<div id="hotelbooking" class="registration container">
  <form #hotelbookingForm="ngForm" [ngClass]="{'form-complete': hotelbookingForm.valid}" name="hotelbookingForm" (ngSubmit)="forward(hotelbookingForm)" novalidate autocomplete="off" appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.HOTEL.heading' | copy | async }}</h1>
    <!-- <mtc-alert></mtc-alert> -->
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.label' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group [(ngModel)]="enrollment.HotelNeeded" name="hotelNeeded" #hotelNeeded="ngModel" required (ngModelChange)="clearRequests()">
          <mat-radio-button [value]="true" [checked]="enrollment.HotelNeeded === true">Yes</mat-radio-button>
          <mat-radio-button [value]="false" [checked]="enrollment.HotelNeeded === false">No</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="hotelNeeded" isRequired=true ></app-validation></mat-error>
          </div>
        </mat-radio-group>
      </article>
    </section>
    <section class="card" *ngIf="enrollment.HotelNeeded === true">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.hotels' | copy | async }}
        </div>
      </div>
      <article class="w-100" [innerHTML]='"copy.html.hotelinfo"|copy|async'>
      </article>
    </section>
    <section class="card" *ngIf="enrollment.HotelNeeded === true">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.HOTEL.roominfo' | copy | async }}
        </div>
      </div>
      <article>
        <app-hotel-pref class="w-100"></app-hotel-pref>
        {{ 'copy.label.HOTEL.roompref' |copy |async}}
        <div class="radio-group">
          <mat-radio-group [(ngModel)]="attendee.HotelSmoking" name="smoking" >
            <mat-radio-button [value]="true" [checked]="attendee.HotelSmoking === true">Smoking</mat-radio-button>
            <mat-radio-button [value]="false" [checked]="attendee.HotelSmoking === false">Non-Smoking</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="d-flex justify-content-center w-100">
          <mat-form-field class="w-100">
            <mat-label class="w-100" for="DietaryNeedOther" aria-labelledby="DietaryNeedOther">{{ 'copy.label.HOTEL.special' |copy |async}}</mat-label>
            <textarea matInput class="w-100" name="hotelSpecialRequests" #special="ngModel" [(ngModel)]="enrollment.HotelSpecialRequests" maxlength="225" pattern="^[\w\s!@\-\_:&',.\/?]*"></textarea>
            <mat-error><app-validation [object]="special" isRequired=true pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>
        </div>
      </article>
  </section>

    <section class="card" *ngIf="enrollment.HotelNeeded === false">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.DEMOGRAPHICS.nohotellabel' | copy | async }}
          <i class="fas fa-asterisk required"></i>
        </div>
      </div>
      <article>
        <mat-radio-group [(ngModel)]="enrollment.HotelSpecialRequests" name="noHotelReason" #noHotelReason="ngModel" required>
          <mat-radio-button *ngFor="let op of noHotelRadios" [value]="op.value">{{op.display}}</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="noHotelReason" isRequired=true ></app-validation></mat-error>
          </div>
        </mat-radio-group>

      </article>
    </section>
    <div class="button_group d-flex">
      <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.goback' | copy | async }}
      </button>
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
        {{ 'copy.label.GLOBAL.continue' | copy | async }}
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</div>
