import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationService } from '../services/configuration.service';
import { EmailService } from '../services/email.service';

@Directive({
  selector: '[appUniqueemail]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueemailDirective, multi: true}]
})
export class UniqueemailDirective implements AsyncValidator{

  constructor(private emailService:EmailService, private configService: ConfigurationService) { }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.emailValidator()(control);
  }

  emailValidator(): AsyncValidatorFn{
    return (control: AbstractControl): any => {

      const promise = new Promise((resolve, reject) => {
        this.configService.fetchConfig().then( conf =>{
          console.log(conf.uniqueEmail);
          if(conf.uniqueEmail){

            this.emailService.checkUniqueEmail(control.value).subscribe(obs=>{
              console.log("new email");
              resolve(null);
            },obs=>{
              console.log("error");
              resolve({uniqueemail:true});
            });
          }else{
            resolve(null);
          }
        });
      });
      return promise;
    }
  }

}
