import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { RegistrationService } from '../../services/registration.service';
import { ListManagerService } from '../../services/list-manager.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { ModalService } from 'src/app/services/modal.service';
import { HotelService } from '../../services/hotel.service';
import { GuestService } from 'src/app/services/guest.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { DateUtilityServiceService } from '../../services/date-utility-service.service';
import { VariablesService } from '../../services/variables.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactusComponent } from 'src/app/reusable-components/contactus/contactus.component';
import { ConstantsService } from 'src/app/services/constants.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WSASERVICE_NOT_FOUND } from 'constants';
import { CreditcardService } from 'src/app/services/creditcard.service';
import { LineitemService } from 'src/app/services/lineitem.service';
import { RememberService } from 'src/app/services/remember.service';
import { MatIconModule } from '@angular/material/icon';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  templateUrl: './summary.component.html'
})
export class SummaryComponent implements OnInit {

  isSubmitting = false;
  isError = false;
  hotelsLoaded = false;
  attendee;
  guests;
  enrollment;
  emailAddress;
  hotelList;
  dateRange;
  groupDays;
  preextend;
  postextend;
  hotelStay;
  workAddress: any;
  homeAddress: any;
  workPhone: any;
  homePhone: any;
  mobilePhone: any;
  emerContactMainPhone: any;
  emerContactAltPhone: any;
  adminContact: any;
  hotelSpecialRequests: any;
  hotelSmoking: any;
  personalDisplay: any;
  isRegComplete: any;

  errorMsg;
  dietaryNeedDisplay;
  personalConsiderationsDisplay = "None";
  mealList;
  personalConsiderationsList;
  pagesInSiteFlow: any;
  page: any;
  firstTime;
  firstTimeValue;
  isFormCompleted;
  whyAttend;
  showpayment = false;
  bestFeature;
  bestFeatureValue;
  bestFeatureFreeForm;
  bestFeatureFreeFormValue;
  bestFeatures;
  bestFeaturesSelections;
  programInfo;
  successMessage = false;
  ready = false;
  output:any = {
    showGlobals : false,
    config: "",
    enrollment:"",
    programKey:"",
    serviceURL:"",
    hideLimits:true,
    hideHeader:true
  };

  charges = {
    Conference: null,
    Hotel: null,
    Activity: null,
    Guest: null
  };
  payment;
  conferencePayment;
  guaranteecard;
  guaranteePayment;
  cards;
  hotelPayment;
  hotelcard;
  activityPayment;
  activitycard;
  guestPayment;
  guestcard;
  conferencecard;
  rememberme;
  isGDPR;

  varTrack;
  varBenefitRoomNights;
  varBenefitLevel;
  varCancelPolicy;
  varTravelPolicy;
  varCodeOfConduct;
  varFirstTime;
  varFirstTimeAmbassador;
  varPreConference;
  varWelcomeReception;
  varFinalNightConcert;
  varMorningWorkout;
  varDonation;
  donationvalues;
  varDonationAmount;
  varDonationOther;
  varDesignatedSpeaker;
  varSponsorPreConference;
  varSponsorDateAttend;
  varSupplierRole;
  sponsordayvalues;
  varBreakfast;
  varPastEventsAttend;
  varYesBusRelated;
  varYesInterest;
  varYesRegional;
  varNoCommunities;
  varScooterComments;
  varScooter;
  varDepartment;
  varCECreditAdvisor;
  varProfDesignations;
  varContribution;
  varVeteran;
  varVeteranOther;
  varFirstresponder;
  varFirstresponderOther;
  SMStext;
  SMSprovider;
  SMSproviderother;
  varLPLAStockholder;
  varContribute;
  varInitials;
  ContactConsent;
  varCompStaffBenefits;
  varCompStaffFirst;
  varCompStaffLast;  
  varCompStaffRepID;   
  varAffiliation;
  varAffiliationOther;        
  varHotelRewards;
  varPACAck;
  // varAssumRisk;
  soldout;
  varDPDateAttend;

  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private listService: ListManagerService,
    private enrollmentService: EnrollmentService,
    public modalService: ModalService,
    private hotelService: HotelService,
    private dateService: DateUtilityServiceService,
    private variablesService: VariablesService,
    private guestService: GuestService,
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    public Constants: ConstantsService,
    private configurationService: ConfigurationService,
    private creditCardService: CreditcardService,
    private lineItemService: LineitemService,
    private rememberService: RememberService,
    private environmentService: EnvironmentService
  ) {
    this.attendee = this.registrationService.getAttendee({});
    this.guests = this.registrationService.getGuests({});
    this.enrollment = this.registrationService.getEnrollment({});
    this.workAddress = this.registrationService.getAddress(this.attendee, "WORK", {});
    this.homeAddress = this.registrationService.getAddress(this.attendee, "HOME", {});
    this.workPhone = this.registrationService.getPhone(this.attendee, "WORK", {});
    this.homePhone = this.registrationService.getPhone(this.attendee, "HOME", {});
    this.mobilePhone = this.registrationService.getPhone(this.attendee, "MOBILE", {});
    this.emailAddress = this.registrationService.getEmail(this.attendee, "BUSINESS", {});
    this.emerContactMainPhone = this.registrationService.getEmergencyContactPhone(this.attendee, "DTPHONE", {});
    this.emerContactAltPhone = this.registrationService.getEmergencyContactPhone(this.attendee, "ALTPHONE", {});
    this.adminContact = this.registrationService.getAdminContact({});
    this.hotelSpecialRequests = this.enrollment.HotelSpecialRequests;
    this.hotelSmoking = this.attendee.HotelSmoking;
    this.output.enrollment = this.enrollment;
    this.configurationService.fetchConfig().then( conf =>{
      this.output.config = conf;
      this.output.programKey = this.environmentService.getProgramKey();
      this.output.baseURL = this.environmentService.getEnvironmentUrl("");
      this.output.actEndpoint = "service/webactivity";
      this.ready=true;
      console.log("activity output object ready");
    });

    this.cards = this.creditCardService.cards;
    this.lineItemService.getAllLineItems().subscribe(data=>{
      if(data.length>0){
        if(this.attendee.PAXTypeNo === 20 || this.attendee.PAXTypeNo === 21 || this.attendee.PAXTypeNo === 22 || this.attendee.PAXTypeNo === 23 
          || this.attendee.PAXTypeNo === 24 || this.attendee.PAXTypeNo === 25 || this.attendee.PAXTypeNo === 26  
          || this.attendee.PAXTypeNo === 27 || this.attendee.PAXTypeNo === 28 || this.attendee.PAXTypeNo === 29 || this.attendee.PAXTypeNo === 40)
        {
          if(this.conferencePayment || this.hotelPayment || this.guestPayment)
          {
            this.showpayment = true;

          }
          else
          {
            this.showpayment = false;

          }
        }
        else
        {
          this.showpayment = true;
        }  
      }else{
        console.log("hello" + this.conferencePayment);

        this.configurationService.fetchConfig().then((config: any) => {
          if (config.decideCollect && this.conferencePayment) {
            this.showpayment = true;
          }
          else if(config.hotelGuarantee && this.registrationService.getEnrollment({}).HotelNeeded == true){
            this.showpayment = true;
          }else if(config.chargeExtensions && this.registrationService.getEnrollment({}).HotelNeeded == true && this.registrationService.getEnrollment({}).$mtcExtension){
            this.showpayment = true;
          }
          let hotelsoldoutdate = moment.utc(this.programInfo.hotelSoldOut, "YYYY-MM-DD HH:mm:ss+Z").toDate();
          let now = moment().tz("America/Chicago").toDate();
          if(hotelsoldoutdate < now) {
            this.soldout = true;
          }

        });
      }
    });

    this.registrationService.getParticipant([]).forEach(pax=>{
      this.getDisplayName(pax);
    });
    this.payment = this.registrationService.getPayment([]);
    this.guaranteePayment = this.registrationService.getCreditCard(this.payment, "Guarantee", undefined);
    this.conferencePayment = this.registrationService.getCreditCard(this.payment, "Conference", undefined);
    this.conferencecard = this.conferencePayment && this.conferencePayment.MediaType === "CREDITCARD" ? _.filter(this.cards, {"ReferenceKey": this.conferencePayment.ReferenceKey})[0] : undefined;
    this.guaranteecard = this.guaranteePayment && this.guaranteePayment.MediaType === "CREDITCARD" ? _.filter(this.cards, { "ReferenceKey": this.guaranteePayment.ReferenceKey })[0] : undefined;
    this.hotelPayment = this.registrationService.getCreditCard(this.payment, "Hotel", undefined);
    this.hotelcard = this.hotelPayment && this.hotelPayment.MediaType === "CREDITCARD" ? _.filter(this.cards, { "ReferenceKey": this.hotelPayment.ReferenceKey })[0] : undefined;
    this.activityPayment = this.registrationService.getCreditCard(this.payment, "Activity", undefined);
    this.activitycard = this.activityPayment && this.activityPayment.MediaType === "CREDITCARD" ? _.filter(this.cards, { "ReferenceKey": this.activityPayment.ReferenceKey })[0] : undefined;
    this.guestPayment = this.registrationService.getCreditCard(this.payment, "Guest", undefined);
    this.guestcard = this.guestPayment && this.guestPayment.MediaType === "CREDITCARD" ? _.filter(this.cards, { "ReferenceKey": this.guestPayment.ReferenceKey })[0] : undefined;


    this.varTrack = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varTrack.ID,
      {}
    );

    this.varBenefitRoomNights = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varBenefitRoomNights.ID,
      {}
    );

    this.varBenefitLevel = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varBenefitLevel.ID,
      {}
    );

    this.varCancelPolicy = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varCancelPolicy.ID,
      {}
    );

    this.varTravelPolicy = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varTravelPolicy.ID,
      {}
    );

    this.varCodeOfConduct = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varCodeOfConduct.ID,
      {}
    );
    // this.varAssumRisk = this.registrationService.getVariable(
    //   this.attendee,
    //   this.variablesService.VARIABLE.varAssumRisk.ID,
    //   {}
    // );
    this.varPACAck = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varPACAck.ID,
      {}
    );
    
    this.varFirstTime = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varFirstTime.ID,
      {}
    );

    this.varFirstTimeAmbassador = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varFirstTimeAmbassador.ID,
      {}
    );

    this.varPreConference = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varPreConference.ID,
      {}
    );

    this.varSupplierRole = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varSupplierRole.ID,
      {}
    ); 

    this.varWelcomeReception = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varWelcomeReception.ID,
      {}
    );

    this.varFinalNightConcert = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varFinalNightConcert.ID,
      {}
    );

    this.varMorningWorkout = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varMorningWorkout.ID,
      {}
    );

    this.varDonation = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varDonation.ID,
      {}
    );

    this.donationvalues = this.variablesService.donationvalues;


    this.varDonationAmount = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varDonationAmount.ID,
      {}
    );

    this.varDonationOther = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varDonationOther.ID,
      {}
    );

    this.varDesignatedSpeaker = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varDesignatedSpeaker.ID,
      {}
    );

    this.varSponsorPreConference = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varSponsorPreConference.ID,
      {}
    );

    this.varSponsorDateAttend = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varSponsorDateAttend.ID,
      {}
    );

    this.varBreakfast = this.registrationService.getVariable(
      this.attendee,
      this.variablesService.VARIABLE.varBreakfast.ID,
      {}
    );
    this.varPastEventsAttend = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varPastEventsAttend.ID,
      {}
    );
    this.varScooter = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varScooter.ID,
      {}
    );

    this.varScooterComments = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varScooterComments.ID,
      {}
    );
    this.varYesBusRelated = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varYesBusRelated.ID,
      {}
    );
    this.varYesInterest = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varYesInterest.ID,
      {}
    );
    this.varYesRegional = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varYesRegional.ID,
      {}
    );
    this.varNoCommunities = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varNoCommunities.ID,
      {}
    );
    this.varDepartment = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varDepartment.ID,
      {}
    );
    this.varCECreditAdvisor = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCECreditAdvisor.ID,
      {}
    );
    this.varProfDesignations = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varProfDesignations.ID,
      {}
    );
    this.varVeteran = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varVeteran.ID,
      {}
    );
    this.varVeteranOther = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varVeteranOther.ID,
      {}
    );
    this.varFirstresponder = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varFirstresponder.ID,
      {}
    );    
    this.varFirstresponderOther = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varFirstresponderOther.ID,
      {}
    );
    this.SMStext = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varSMSConsent.ID,
      {}
    );
    this.SMSprovider = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCellProvider.ID,
      {}
    );
    this.SMSproviderother = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.SMSproviderother.ID,
      {}
    );
    this.varLPLAStockholder = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varLPLAStockholder.ID,
      {}
    );

    this.varContribute = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varContribute.ID,
      {}
    );

    this.varContribution = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varContribution.ID,
      {}
    );

    this.varInitials = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varInitials.ID,
      {}
    );
    this.ContactConsent = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varContactSheet.ID,
      {}
    );
    this.varCompStaffBenefits = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffBenefits.ID,
      {}
    );
    this.varCompStaffFirst = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffFirst.ID,
      {}
    );    
    this.varCompStaffLast = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffLast.ID,
      {}
    );  
    this.varCompStaffRepID = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffRepID.ID,
      {}
    );   
    this.varAffiliation = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varAffiliation.ID,
      {}
    );
    this.varAffiliationOther = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varAffiliationOther.ID,
      {}
    );        
    this.varHotelRewards = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varHotelRewards.ID,
      {}
    );

    this.varDPDateAttend = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varDPDateAttend.ID,
      {}
    );

    if (this.varCodeOfConduct.Value === "Agreed" ) {
      this.enrollment.$mtccodeofconduct = true;
    }

    if ( this.varPACAck.Value === "Agreed") {
      this.enrollment.$mtcpacack = true;
    }



    this.isRegComplete = this.registrationService.isRegistrationComplete();

    this.mealList = [
      {name: 'None', code: ''},
      {name: 'Dairy-Free', code: 'DAIRYFREE'},
      {name: 'Food Allergy', code: 'FOODALLE'},
      {name: 'Gluten-Free', code: 'GLUTFREE'},
      {name: 'Halal', code: 'HALAL'},
      {name: 'Kosher', code: 'KOSHER'},
      {name: 'No Pork', code: 'NOPORK'},
      {name: 'Pescatarian', code: 'PESCATARIAN'},
      {name: 'Vegan', code: 'VEGAN'},
      {name: 'Vegetarian', code: 'VEGTRIAN'},
      {name: 'Other', code: 'OTHER'}
    ];

    //this.listService.getMealPreferenceList().then(list => {
    //  this.mealList = list;

      if (this.attendee.DietaryNeed) {
        this.dietaryNeedDisplay = _.filter(this.mealList, { "code": this.attendee.DietaryNeed })[0].name;
      }
    //});

    this.listService.getDisabilityList().then(list => {
      this.personalConsiderationsList = list;

      if (this.attendee.DisabilityType) {
        var temp;
        var final = "";
        for (var x = 0; x < this.attendee.DisabilityType.length; x++) {
          temp = _.filter(this.personalConsiderationsList, { "code": this.attendee.DisabilityType[x] })[0].name;
          if (temp === "Other")
            final = final + temp + " - " + this.attendee.PersonalConsiderations;
          else
            final = final + temp;
          if (this.attendee.DisabilityType.length > 0 && x !== this.attendee.DisabilityType.length - 1) {
            final = final + ", "
          }
        }
        this.personalConsiderationsDisplay = final;
      }
    });

    this.listService.getPrivacyPolicyCountryList().then(list => {
      if(!this.enrollment.PrivacyPolicy) {
        this.enrollment.PrivacyPolicy = false;
      }
      this.isGDPR = false;
      for(let country of list) {
        if(country.code === this.attendee.ResidenceCountryISOCode) {
          this.isGDPR = true;
        }
      }
    });

    this.hotelStay = this.registrationService.getHotel([]);
    //check if hotel booking
    this.hotelList = { locked: false };
    if (this.enrollment.HotelNeeded) {
      this.hotelService.getHotelList().then(list => {
        this.hotelList = list;
        if (!this.hotelStay.length) {
          //look for booked stays.
          this.hotelList.roomTypeList.forEach((roomtype) => {
            var groupid = roomtype.webHotelGroupID;
            var pending = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 2 });
            var reserved = _.filter(roomtype.roomNightsListWithRate, { availabilityCode: 3 });
            reserved = _.union(pending, reserved);
            reserved.forEach((res) => {
              this.hotelStay.push({ Date: res.blockDate, Hotel: groupid });
            });
          });
        }
        this.hotelsLoaded = true;
      });
    }
  }

  getDisplayName(participant) {
    var returnValue = "";
    this.listService.getPrefixValue(participant.Prefix).then(data=>{
      var prefix = data.name;
      console.log(data);
      console.log(prefix);
      if(prefix){
        returnValue = prefix + " ";
      }
      if (participant.FirstName) {
        returnValue += participant.FirstName + " ";
      }
      if (participant.MiddleName && !participant.NoMiddleNameFlag) {
        returnValue += participant.MiddleName + " ";
      }
      if (participant.LastName) {
        returnValue += participant.LastName + " ";
      }
      this.listService.getSuffixValue(participant.Suffix).then(data=>{
        var suffix = data.name;
        if(suffix && suffix !== "(None)"){
          returnValue += suffix;
        }
        if (returnValue.length <= 1) {
          returnValue = "Personal Information";
        } else {
          returnValue = returnValue.trim();
        }
        participant.$mtcDisplayName = returnValue;
      });
    });

  }


  siteFlowContains(page) {
    let index = 0; let found;
    if (this.pagesInSiteFlow) {
      for (index; index < this.pagesInSiteFlow.length; index++) {
        found = false;

        this.pagesInSiteFlow[index].forEach((entry) => {
          if (entry == '/' + page) {
            found = true;
          }
        });
        if (found) {
          break;
        }
      }
      return found;
    }
  };

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      this.navigationService.getNavigationList().then(pagelist => {
        this.pagesInSiteFlow = pagelist;
      });
    });
    if (this.enrollment.$mtcUpdateSuccess) {
      this.successMessage = true;
      this.enrollment.$mtcUpdateSuccess = false;
    }

    for (const key of _.keys(this.charges)) {
      this.lineItemService.getLineItems(key).subscribe({
        next: data => {
          this.charges[key] = _.reduce(data, (sum, n) => {
            return parseFloat(sum) + parseFloat(n);
          });
          },
            error: error => {
              this.Constants.ALERT_POLICY.CONTACT_SUPPORT
            }
      });
    }

  }

  getVariable(guest, seqno){
    return this.registrationService.getVariable(guest, seqno, {});
  }


  getGuestDiet(guest) {
    if (guest && this.mealList) {
      if (guest.DietaryNeed) {
        return _.filter(this.mealList, { "code": guest.DietaryNeed })[0].name;
      }
    }
  }

  getGuestTypeDisplay(guest) {
    if (guest) {
      return this.guestService.getTypeDisplay(guest);
    }
  }

  getDietaryDisplay(considerations){
    let display = considerations.replace('!', ',');
    display = display.replaceAll(',', ', ');
    return display;
  }


  getGuestADAPersonal(guest) {
    if (guest && this.personalConsiderationsList) {
      if (guest.DisabilityType) {
        var temp;
        var final = "";
        for (var x = 0; x < guest.DisabilityType.length; x++) {
          if (guest.DisabilityType[x] !== 'NONE') {
            temp = _.filter(this.personalConsiderationsList, { "code": guest.DisabilityType[x] })[0].name;
            if (temp === "Other")
              final = final + temp + " - " + guest.PersonalConsiderations;
            else
              final = final + temp;
            if (guest.DisabilityType.length > 0 && x !== guest.DisabilityType.length - 1) {
              final = final + ", "
            }
          } else {
            if (guest.DisabilityType.length > 0 && x !== guest.DisabilityType.length - 1) {
              final = "None" + ", "
            } else {
              final = final + "None";
            }
          }
        }
        return final;
      }
    }
  }

  printPage() {
    window.print();
  }

  openModal(copy, title, actionButtonText) {
    this.modalService.openModal(copy, title, actionButtonText);
  }

  openModalExternal(url, title, actionButtonText) {
    this.modalService.openModalExternal(url, title, actionButtonText);
  }

  getHotelsWithStays() {
    var hotelids = _.uniq(_.map(this.hotelStay, "Hotel"));
    var hotels = [];
    if (this.hotelList) {
      var self = this;
      hotelids.forEach((id)=> {
        var hotel = _.filter(self.hotelList.roomTypeList, { webHotelGroupID: id })[0];
        var hotelNights = self.getNightsAtHotel(hotel.webHotelGroupID);
        hotel.firstNight = hotelNights[0];
        hotels.push(hotel);
      });
    }
    return hotels;
  };

  getNightsAtHotel(hotelid) {
    this.hotelStay.forEach( (hotelnight) =>{
      if (moment.isMoment(hotelnight.Date)) {
        hotelnight.Date = hotelnight.Date.toDate();
      }
    });
    return _.orderBy((_.map(_.filter(this.hotelStay, { Hotel: hotelid }), "Date")));
  };

  getStatus(hotelobj, date) {
    var hotelnight;
    var testDate;

    if (moment.isMoment(date)) {
      testDate = date.toDate();
    } else {
      testDate = this.dateService.createMTCZoneDateTime(date).toDate();
    }
    var self = this;
    _.forEach(hotelobj.roomNightsListWithRate, (night) => {
      var nightDate = self.dateService.createMTCZoneDateTime(night.blockDate).toDate();
      var inputDate = testDate;
      if (nightDate.getTime() === inputDate.getTime()) {
        hotelnight = night;
      }
    });

    if (hotelnight.availabilityCode === 1) {
      //if night is not already booked.
      if (hotelnight.groupDateFlag === "GRP") {
        return "Confirmed";
      } else {
        return "Pending";
      }
    } else if (hotelnight.availabilityCode === 2) {
      return "Pending";
    } else if (hotelnight.availabilityCode === 3) {
      return "Confirmed";
    }
  };

  editAttendee() {
    if (this.isRegComplete) {
      this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
    } else {
      this.router.navigate(['attendee']);
    }
  }
  editGuest(guest) {
    if (guest) {
      this.guestService.selectGuest(guest);
    }
    this.router.navigate(['guest']);
  }
  editDemographics() {
    if (this.isRegComplete) {
      this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
    } else {
      this.router.navigate(['demographics']);
    }
  }
  editAgreements() {
    if (this.isRegComplete) {
      this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
    } else {
      this.router.navigate(['agreements']);
    }
  }
  editHotel() {
    if (this.isRegComplete) {
      this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
    } else {
      this.router.navigate(['hotelbooking']);
    }
  }
  editTravel() {
    if (this.isRegComplete) {
      this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
    }
    else {
      this.router.navigate(['travel']);
    }
  }
  editGuestTravel(guest) {
    if (this.isRegComplete) {
      this.dialog.open(ContactusComponent, { panelClass: 'forgot-dialog-container' });
    }
    else {
      this.guestService.selectGuest(guest);
      this.router.navigate(['guest-travel']);
    }
  }

  /**
  * @function editPayment
  * @description Sets activity path to payment page.
  */
  editPayment() {
    this.router.navigate(['payment']);
  }



  forward(form) {
    if (!this.isSubmitting) {
    this.isFormCompleted = true;
    this.isSubmitting = true;
    this.errorMsg = undefined;
    /* if () { */
    if (form.valid ) {

      if (this.enrollment.$mtccodeofconduct === true) {
        this.varCodeOfConduct.Value = "Agreed";
      }
      if (this.enrollment.$mtcpacack === true) {
        this.varPACAck.Value = "Agreed";
      }

      if (this.enrollment.AgreedToTermsAndConditions) {
        this.enrollment.ResponsibilityStatement = true;
      }
      if (this.attendee.TravelType === undefined) {
        this.attendee.TravelType = 'UNK';
      }

      if (this.attendee.DietaryNeed === undefined || this.attendee.DietaryNeed === "") {
        this.attendee.DietaryNeed = "Other";
      }

      this.enrollmentService.createEnrollment(this.registrationService.getEnrollment({})).then(() => {
        if(this.rememberme){
          this.rememberService.setCookie(this.registrationService.getEnrollment({}).$mtcConfirmationCode, this.emailAddress.EmailAddress);
        }
        this.router.navigate(['acknowledgement']);
      }, (err) => {
        this.isSubmitting = false;

        this.errorMsg = "Error while submitting your registration.";
      });
      //this.navigationService.forward();
    } else {
      this.isError = true;
      this.isSubmitting = false;
    }
    //this.navigationService.forward();
    /* } else {
      this.isError = true;
      this.isSubmitting = false;
    } */
    }
  }


}
