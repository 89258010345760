<div id="payment" class="registration container" *ngIf="programInfo">
    <form #paymentForm="ngForm" name="paymentForm" (ngSubmit)="forward(paymentForm)"
        novalidate autocomplete="off" appScrollToInvalid
        [ngClass]="{'form-complete': isFormCompleted}">
        <h1>{{"copy.label.PAYMENT.heading"|copy|async}}</h1>
        <div *ngFor="let ccerror of CCerrors">{{ccerror}}</div>
        <!-- <section class="card" name="conferenceFee" *ngIf="programInfo.decideCollect && charges.Total === 0"> -->
        <section class="card" name="conferenceFee" *ngIf="Total === 0">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.PAYMENT.FEESRT10.guarantee' | copy | async }}
                </div>
            </div>
            <article>
                <div class="card-body w-100">
                    <app-collect-payment cardType="Conference"></app-collect-payment>
                </div>
            </article>
        </section>

        <section class="card" name="conferenceFee" *ngIf="charges.Conference > 0">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.PAYMENT.FEESRT10.conference' | copy |async }} {{charges.Conference | currency}}
                </div>
            </div>
            <article>
                <div [innerHTML]='"copy.html.creditcarddaypass"|copy|async'></div>
                <div class="card-body w-100">
                    <app-collect-payment cardType="Conference"></app-collect-payment>
                </div>
            </article>
        </section>


        <section class="card" name="hotelFee" *ngIf="charges.Hotel > 0">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.PAYMENT.FEESRT10.hotel' | copy | async }} {{charges.Hotel | currency}}
                </div>
            </div>
            <article>
                <div class="card-body w-100">
                    <div [innerHTML]='"copy.html.OtherPayment" | copy | async'></div>
                    <app-collect-payment cardType="Hotel"></app-collect-payment>
                </div>
            </article>
        </section>

        <section class="card" name="hotelFee"
            *ngIf="programInfo.hotelGuarantee && enrollment.HotelNeeded && (!charges.Hotel || charges.Hotel === 0)">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.PAYMENT.FEESRT10.hotelguarantee' | copy | async }}
                </div>
            </div>
            <article>
                <div class="card-body w-100">
                    <div [innerHTML]='"copy.html.HotelGuaranteeText"|copy|async'></div>
                    <app-collect-payment cardType="Hotel" [ccOnly]="true"></app-collect-payment>
                </div>
            </article>
        </section>

        <section class="card" name="activityFee" *ngIf="charges.Activity > 0">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.PAYMENT.FEESRT10.activity' | copy | async }} {{charges.Activity | currency}}
                </div>
            </div>
            <article>
                <div class="card-body w-100">
                    <app-collect-payment cardType="Activity"></app-collect-payment>
                </div>
            </article>
        </section>

        <section class="card" name="guestFee" *ngIf="charges.Guest > 0">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.PAYMENT.FEESRT10.guest' | copy | async }} {{charges.Guest | currency}}
                </div>
            </div>
            <article>
                <div class="card-body w-100">
                    <app-collect-payment cardType="Guest"></app-collect-payment>
                </div>
            </article>
        </section>


        <section class="card" name="guarantee" *ngIf="needGuarantee()">
          <div class="card-header">
              <div class="card-title">
                  {{ 'copy.label.PAYMENT.FEESRT10.guarantee' | copy | async }}
              </div>
          </div>
          <article>
              <div class="card-body w-100">
                  <div [innerHTML]='"copy.html.OtherPayment" | copy | async'></div>
                  <app-collect-payment cardType="Guarantee" [ccOnly]="true"></app-collect-payment>
              </div>
          </article>
      </section>
        <div class="button_group d-flex">
          <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
            {{ 'copy.label.GLOBAL.goback' | copy | async }}
          </button>
          <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
            {{ 'copy.label.GLOBAL.continue' | copy | async }}
          </button>
          <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
            <i class="fas fa-spinner fa-spin"></i>
          </button>
        </div>
    </form>
</div>
