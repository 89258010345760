<div id="summary" class="registration container">
  <form #summaryForm="ngForm" name="summaryForm" (ngSubmit)="forward(summaryForm)" novalidate appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted }" *ngIf="!errorMsg">
    <div class="card emptybody" *ngIf="!errorMsg && !successMessage && isRegComplete">
      <div class="card-title">
        <div class="summaryHeaderAndPrint">
          <h1>{{ 'copy.label.SUMMARY.heading' | copy | async }}</h1>
          <mat-icon class="printButton" (click)="printPage();" aria-hidden="false" aria-label="Example home icon">print</mat-icon>
        </div>
      </div>
    </div>
    <div class="w-100" *ngIf="isRegComplete && (varTrack.Value === 'Benefit Advisor' || varTrack.Value==='Non-Benefit Advisor')">
      <div class="w-100" [innerHTML]='"copy.html.buildersummarynote"|copy|async'></div>
    </div>
    <div class="card" *ngIf="errorMsg || successMessage || !isRegComplete">
      <div class="card-title">
        <div class="summaryHeaderAndPrint">
          <h1>{{ 'copy.label.SUMMARY.heading' | copy | async }}</h1>
          <mat-icon class="printButton" (click)="printPage();" aria-hidden="false" aria-label="Example home icon">print</mat-icon>
        </div>
      </div>
      <div class="card-body" >
        <!-- Top Section -->
        <div class="w-100" *ngIf="errorMsg" type="danger">
          <strong>
            <span class="fas fa-stack">
              <i class="fas fa-exclamation fa-stack-1x"></i>
              <i class="far fa-circle fa-stack-2x"></i></span> {{errorMsg}}</strong>
        </div>
        <div class="w-100" *ngIf="successMessage">
          <div class="w-100" [innerHTML]='"copy.html.rvsSuccessMessage"|copy|async'></div>
        </div>
        <section *ngIf="!isRegComplete">
          <h2>{{ 'copy.label.SUMMARY.review' | copy | async }}</h2>
          <article name="legal" class="summaryLinks">
            <div class="agreeCheckbox">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="termsAndConditions" [(ngModel)]="enrollment.AgreedToTermsAndConditions" #termsAndConditions="ngModel" required></mat-checkbox>
                <!-- Placing the text inside the mat-checkbox will make it so the checkbox will be checked when links are clicked --><label class="required">
                  I agree to the Maritz <a tabindex="0" (click)="openModal('termsOfUse', 'About This Site', 'Close');" (keydown.enter)="openModal('termsOfUse', 'About This Site', 'Close');">Terms of Use</a> and
                  the <a tabindex="0" (click)="openModal('ResponsibilityStatement', 'Responsibility Statement', 'Close');" (keydown.enter)="openModal('ResponsibilityStatement', 'Responsibility Statement', 'Close');">{{ 'copy.label.SUMMARY.responsibilitylink' | copy | async }}</a>.</label>
              </div>
              <div class="w-100">
                <small class="text-danger"><app-validation [object]="termsAndConditions" isRequired=true></app-validation></small>
              </div>
            </div>

            <div class="agreeCheckbox mb-2">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="privacy" [(ngModel)]="enrollment.PrivacyPolicy" #privacy="ngModel"></mat-checkbox>
                <label >
                  I agree to the LPL Financial <b><a style="color:#007bff" href="https://www.lpl.com/disclosures/privacy-security.html" target="_blank">Privacy Policy</a></b> I understand that Maritz may collect and process my personal information on behalf of LPL Financial.</label>
              </div>
              <!-- <div class="w-100">
                <small class="text-danger"><app-validation [object]="privacy" isRequired=true></app-validation></small>
              </div> -->
            </div>

            <div class="agreeCheckbox mb-2">
              <div class="checkboxFlexContainer">
                <mat-checkbox name="conduct" [(ngModel)]="enrollment.$mtccodeofconduct" #conduct="ngModel" required></mat-checkbox>
                <label class="required">
                  By clicking here, I acknowledge that I have read the <b><a style="color:#007bff" href="https://content-dev.travelhq.com/focus2024content/assets/pdf/Conference%20Code%20of%20Conduct%20-%2008.18.23.pdf" target="_blank">Conference Code of Conduct</a></b> and agree to the terms and conditions outlined in the policy.</label>
              </div>
              <div class="w-100">
                <small class="text-danger"><app-validation [object]="conduct" isRequired=true></app-validation></small>
              </div>
            </div>

            <strong class="mb-2">Personal Accountability Commitment</strong>
              <div class="agreeCheckboxPac agreeCheckbox">
                <div class="checkboxFlexContainer">
                  <mat-checkbox name="pac" [(ngModel)]="enrollment.$mtcpacack" #pac="ngModel" required></mat-checkbox>
                  <label class="required">
                    LPL Financial believes that its upcoming conference will provide valuable information. By registering, you agree not to travel to or attend the conference if you are feeling unwell or sick.</label>
                </div>
                <div class="w-100">
                  <small class="text-danger"><app-validation [object]="pac" isRequired=true></app-validation></small>
                </div>
              </div>


          </article>
        </section>

      </div>
    </div>
    <!-- Attendee Page -->
    <mat-expansion-panel *ngIf="siteFlowContains('attendee')" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{attendee.$mtcDisplayName}}
          <a class="edit-link" *ngIf="!isRegComplete" (click)="editAttendee()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          <a class="edit-link" *ngIf="isRegComplete" (click)="editAttendee()">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>

        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-badge></app-badge>

      <app-summary-display [ngmodel]="varDepartment.Value" *ngIf="varDepartment.Value">
        {{ 'copy.label.ATTENDEE.department' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varCECreditAdvisor.Value" *ngIf="varCECreditAdvisor.Value">
        {{ 'copy.label.ATTENDEE.creditadvisor' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varProfDesignations.Value" *ngIf="varProfDesignations.Value">
          {{ 'copy.label.ATTENDEE.professionaldesignationssummary' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varDPDateAttend.Value" *ngIf="varDPDateAttend.Value">
        {{ 'copy.label.ATTENDEE.dayattend' | copy | async }}</app-summary-display>
      <!-- <app-summary-display [ngmodel]="varVeteran.Value" *ngIf="varVeteran.Value">
            {{ 'copy.label.ATTENDEE.veteran' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varVeteranOther.Value" *ngIf="varVeteranOther.Value && varVeteran.Value === 'Yes - Other'">
            {{ 'copy.label.ATTENDEE.veteranother' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varFirstresponder.Value" *ngIf="varFirstresponder.Value">
            {{ 'copy.label.ATTENDEE.firstresponder' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varFirstresponderOther.Value" *ngIf="varFirstresponderOther.Value && varFirstresponder.Value === 'Yes - Other'">
            {{ 'copy.label.ATTENDEE.veteranother' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="SMStext.Value" *ngIf="SMStext.Value">
        {{ 'copy.label.ATTENDEE.smstext' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="SMSprovider.Value" *ngIf="SMSprovider.Value">
          {{ 'copy.label.ATTENDEE.smsprovider' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="SMSproviderother.Value" *ngIf="SMSproviderother.Value && SMSprovider.Value === 'Other'">
            {{ 'copy.label.ATTENDEE.smsproviderother' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varLPLAStockholder.Value" *ngIf="varLPLAStockholder.Value">
              {{ 'copy.label.ATTENDEE.lplastock' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varContribute.Value" *ngIf="varContribute.Value">
                {{ 'copy.label.ATTENDEE.contributelplpac' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varContribution.Value" *ngIf="varContribution.Value">
        {{ 'copy.label.ATTENDEE.contribution' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varInitials.Value" *ngIf="varInitials.Value">
        {{ 'copy.label.ATTENDEE.initials' | copy | async }}</app-summary-display> -->
      <app-summary-display [ngmodel]="ContactConsent.Value" *ngIf="ContactConsent.Value && (varTrack.Value === 'Benefit Advisor' || varTrack.Value==='Non-Benefit Advisor')">
        {{ 'copy.label.ATTENDEE.contactsheet' | copy | async }}</app-summary-display>
      <!-- <app-summary-display [ngmodel]="varCompStaffBenefits.Value" *ngIf="varCompStaffBenefits.Value">
          {{ 'copy.label.ATTENDEE.compstaff' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varCompStaffFirst.Value" *ngIf="varCompStaffFirst.Value">
            {{ 'copy.label.ATTENDEE.compstafffirst' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varCompStaffLast.Value" *ngIf="varCompStaffLast.Value">
              {{ 'copy.label.ATTENDEE.compstafflast' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varCompStaffRepID.Value" *ngIf="varCompStaffRepID.Value">
                {{ 'copy.label.ATTENDEE.compstaffrepid' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="this.attendee.BirthDate| date:'longDate'" *ngIf="this.attendee.BirthDate">
        {{'copy.label.TRAVEL.birthdate' | copy | async }}</app-summary-display> -->
      <app-addresssummary *ngIf="workAddress.AddressLine1 !== undefined" [address]="workAddress">
        {{ 'copy.label.FEATURES.ADDRESS.business' | copy | async }}</app-addresssummary>
      <app-addresssummary *ngIf="homeAddress.AddressLine1 !== undefined" [address]="homeAddress">
        {{ 'copy.label.FEATURES.ADDRESS.home' | copy | async }}</app-addresssummary>
      <app-summary-display [ngmodel]="emailAddress.EmailAddress">{{ 'copy.label.FEATURES.EMAIL.label' | copy | async }}
      </app-summary-display>
      <app-phonesummary *ngIf="workPhone.PhoneNo !== '' && workPhone.PhoneNo !== undefined" [phone]="workPhone">
        {{ 'copy.label.ATTENDEE.CONTACT.bizphonelabel' | copy | async }}</app-phonesummary>
      <app-phonesummary *ngIf="mobilePhone.PhoneNo !== '' && mobilePhone.PhoneNo !== undefined" [phone]="mobilePhone">
        {{ 'copy.label.ATTENDEE.CONTACT.cellphonelabel' | copy | async }}</app-phonesummary>
      <app-phonesummary *ngIf="homePhone.PhoneNo !== '' && homePhone.PhoneNo !== undefined" [phone]="homePhone">
        {{ 'copy.label.ATTENDEE.CONTACT.homephonelabel' | copy | async }}</app-phonesummary>
      <app-summary-display [ngmodel]="attendee.EmergencyContact.EmergencyContactName">
        {{ 'copy.label.FEATURES.EMERGENCY.namelabel' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="attendee.EmergencyContact.EmergencyContactRelation">
        {{ 'copy.label.FEATURES.EMERGENCY.relationshiplabel' | copy | async }}</app-summary-display>
      <app-phonesummary *ngIf="emerContactMainPhone.PhoneNo !== '' && emerContactMainPhone.PhoneNo !== undefined" [phone]="emerContactMainPhone">{{ 'copy.label.FEATURES.EMERGENCY.phonelabel' | copy | async }}
      </app-phonesummary>
      <app-phonesummary *ngIf="emerContactAltPhone.PhoneNo !== '' && emerContactAltPhone.PhoneNo !== undefined" [phone]="emerContactAltPhone">{{ 'copy.label.FEATURES.EMERGENCY.altphonelabel' | copy | async }}
      </app-phonesummary>
      <!-- Dietary Considerations -->
      <!-- <div class="mainAttendeeConsiderations" *ngIf="!attendee.$mtcHasConsiderations">
        <div *ngIf="!attendee.$mtcHasConsiderations" class="form-summary">
          <label class="col-md-6 col-xs-12">{{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}</label>
          <span class="form-summary-item col-md-6 col-xs-12">{{ 'copy.label.FEATURES.PERSONAL.nopersonal' | copy | async }}</span>
        </div>
      </div> -->
      <div class="mainAttendeeConsiderations" >
        <div *ngIf="attendee.DietaryConsiderations" class="form-summary">
          <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</label>
          <span class="form-summary-item col-md-6 col-xs-12" *ngIf="!attendee.DietaryConsiderations">{{dietaryNeedDisplay}}</span>
          <span class="form-summary-item col-md-6 col-xs-12" *ngIf="attendee.DietaryConsiderations">{{getDietaryDisplay(attendee.DietaryConsiderations)}} </span>
        </div>
        <app-summary-display [ngmodel]="personalConsiderationsDisplay" *ngIf="attendee.DisabilityType !== undefined">
          {{ 'copy.label.FEATURES.PERSONAL.ADAsummary' | copy | async }}</app-summary-display>
      </div>
      <div *ngIf="enrollment.$mtcHasAdminContact">
        <app-summary-display [ngmodel]="adminContact.AdminContactName">
          {{ 'copy.label.FEATURES.ADMIN.namelabel' | copy | async }}</app-summary-display>
        <app-phonesummary *ngIf="enrollment.$mtcHasAdminContact" [phone]="adminContact.AdminContactPhone[0]">{{ 'copy.label.FEATURES.ADMIN.phonelabel' | copy | async }}
        </app-phonesummary>
        <app-summary-display [ngmodel]="adminContact.AdminContactEmailAddress">
          {{ 'copy.label.FEATURES.ADMIN.email' | copy | async }}</app-summary-display>
      </div>

      <app-summary-display [ngmodel]="varScooter.Value" *ngIf="varScooter.Value">
        {{ 'copy.label.FEATURES.DIETARY.scooter2' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varScooterComments.Value" *ngIf="varScooterComments.Value">
        {{ 'copy.label.FEATURES.DIETARY.scooter3' | copy | async }}</app-summary-display>

    </mat-expansion-panel>
    <!-- Guest Page -->
    <div *ngIf="siteFlowContains('guest')">
      <mat-expansion-panel *ngIf="!guests.length" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Guest
            <a class="edit-link" *ngIf="!isRegComplete" (click)="editGuest(undefined)">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
            <a class="edit-link" *ngIf="isRegComplete" (click)="editGuest(undefined)">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>

          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-summary">
          <label class="col-md-6 col-xs-12">{{"copy.label.SUMMARY.noguest"|copy|async}}</label>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngFor="let guest of guests" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{guest.$mtcDisplayName}}
            <a class="edit-link" *ngIf="!isRegComplete" (click)="editGuest(guest)">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
            <a class="edit-link" *ngIf="isRegComplete" (click)="editGuest(guest)">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>

          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-summary-display [ngmodel]="guest.PreferredFirstName" *ngIf="guest.PreferredFirstName">
          {{ 'copy.label.FEATURES.NAME.preferredfirst' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="guest.PreferredLastName" *ngIf="guest.PreferredLastName">
          {{ 'copy.label.FEATURES.NAME.preferredlast' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="getGuestTypeDisplay(guest)">
          {{ 'copy.label.FEATURES.GUESTTYPE.heading' | copy | async }}</app-summary-display>
        <!-- <div class="guestConsiderations">
          <div *ngIf="!guest.$mtcHasConsiderations" class="form-summary">
            <label class="col-md-6 col-xs-12">{{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}</label>
            <span class="form-summary-item col-md-6 col-xs-12">{{ 'copy.label.FEATURES.PERSONAL.nopersonal' | copy | async }}</span>
          </div>
        </div> -->
        <div class="guestConsiderations">
          <div *ngIf="guest.DietaryConsiderations" class="form-summary">
            <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</label>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="!guest.DietaryConsiderations">{{getGuestDiet(guest)}}</span>
            <span class="form-summary-item col-md-6 col-xs-12" *ngIf="guest.DietaryConsiderations">{{getDietaryDisplay(guest.DietaryConsiderations)}} </span>
          </div>
          <app-summary-display [ngmodel]="getGuestADAPersonal(guest)" *ngIf="guest.DisabilityType !== undefined">
            {{ 'copy.label.FEATURES.PERSONAL.ADAsummary' | copy | async }}</app-summary-display>
        </div>

        <app-summary-display [ngmodel]="getVariable(guest, 47).Value" *ngIf="getVariable(guest, 47).Value">
          {{ 'copy.label.FEATURES.DIETARY.scooter2' | copy | async }}</app-summary-display>
        <app-summary-display [ngmodel]="getVariable(guest, 48).Value" *ngIf="getVariable(guest, 48).Value">
          {{ 'copy.label.FEATURES.DIETARY.scooter3' | copy | async }}</app-summary-display>

      </mat-expansion-panel>
    </div>

    <mat-expansion-panel *ngIf="siteFlowContains('demographics')" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Demographics
          <a class="edit-link" *ngIf="!isRegComplete" (click)="editDemographics()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          <a class="edit-link" *ngIf="isRegComplete" (click)="editDemographics()">{{ 'copy.label.SUMMARY.editlocked'| copy | async }}</a>

        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-summary-display [ngmodel]="varFirstTime.Value" *ngIf="varFirstTime.Value">
        {{'copy.label.DEMOGRAPHICS.firsttime' | copy | async}}</app-summary-display>
        <app-summary-display [ngmodel]="varPastEventsAttend.Value" *ngIf="varPastEventsAttend.Value">
          {{'copy.label.DEMOGRAPHICS.pastattendee' | copy | async}}</app-summary-display>


      <app-summary-display [ngmodel]="varPreConference.Value" *ngIf="varPreConference.Value">
        <label [innerHTML]='"copy.html.demopreconf" | copy | async'></label></app-summary-display>

      <app-summary-display [ngmodel]="varWelcomeReception.Value" *ngIf="varWelcomeReception.Value">
        <label [innerHTML]='"copy.html.demowelcomereception" | copy | async'></label></app-summary-display>
      <app-summary-display [ngmodel]="varFinalNightConcert.Value" *ngIf="varFinalNightConcert.Value">
        <label [innerHTML]='"copy.html.democoncert" | copy | async'></label></app-summary-display>
        
        
      <app-summary-display [ngmodel]="varDesignatedSpeaker.Value" *ngIf="varDesignatedSpeaker.Value">
        {{'copy.label.DEMOGRAPHICS.designatedspeaker' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varSponsorPreConference.Value" *ngIf="varSponsorPreConference.Value">
        <label class="w-100" [innerHTML]='"copy.html.demosponsorpreconf" | copy | async'></label></app-summary-display>
    <app-summary-display [ngmodel]="varSupplierRole.Value" *ngIf="varSupplierRole.Value">
          {{'copy.label.DEMOGRAPHICS.supplierrole' | copy | async }}</app-summary-display> 
      <app-summary-display [ngmodel]="varBreakfast.Value" *ngIf="varBreakfast.Value">
        <label class="label-display" [innerHTML]='"copy.html.demobreakfast" | copy | async'></label></app-summary-display>
      <!-- <app-summary-display [ngmodel]="varAffiliation.Value" *ngIf="varAffiliation.Value">
        {{'copy.label.DEMOGRAPHICS.affiliation' | copy | async }}</app-summary-display>
      <app-summary-display [ngmodel]="varAffiliationOther.Value" *ngIf="varAffiliationOther.Value && varAffiliation.Value === 'Other'">
        {{'copy.label.ATTENDEE.affilotherlabel' | copy | async }}</app-summary-display> -->
  
    </mat-expansion-panel>

    <div *ngIf="siteFlowContains('payment') && !isRegComplete && showpayment">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"copy.label.PAYMENT.heading"|copy|async}} 
            <a class="edit-link" *ngIf="!isRegComplete" (click)="editPayment()">{{ 'copy.label.SUMMARY.edit'| copy | async }}</a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="(!charges.Conference || charges.Conference === 0 || charges.Conference === '0.0' || charges.Conference === undefined || charges.Conference < 0 ) && conferencePayment">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{ 'copy.label.PAYMENT.FEESRT10.guarantee' | copy |async }}</h3>
              </span></span>
            <div *ngIf="conferencePayment.MediaType === 'CHECK'">
              <div [innerHTML]='"copy.html.Check"|copy|async'></div>
            </div>
            <div *ngIf="conferencePayment.MediaType === 'WIRETRANSFER'">
              <div [innerHTML]='"copy.html.WireTransfer"|copy|async'></div>
            </div>
            <div *ngIf="conferencePayment.MediaType === 'CHECK' || conferencePayment.MediaType === 'WIRETRANSFER'">
              <div *ngIf="guaranteecard.ReferenceKey">
                <h3>{{"copy.label.PAYMENT.FEESRT10.guarantee"|copy|async}}</h3>
                <app-credit-card-display cardType="Guarantee"></app-credit-card-display>
              </div>
            </div>
            <div *ngIf="conferencePayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="conferencePayment && conferencecard" cardType="Conference"></app-credit-card-display>
            </div>
          </div>
        </div>
        <div *ngIf="charges.Conference > 0">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{ 'copy.label.PAYMENT.FEESRT10.conference' | copy |async }} {{charges.Conference | currency}}</h3>
              </span></span>
            <div *ngIf="conferencePayment.MediaType === 'CHECK'">
              <div [innerHTML]='"copy.html.Check"|copy|async'></div>
            </div>
            <div *ngIf="conferencePayment.MediaType === 'WIRETRANSFER'">
              <div [innerHTML]='"copy.html.WireTransfer"|copy|async'></div>
            </div>
            <div *ngIf="conferencePayment.MediaType === 'CHECK' || conferencePayment.MediaType === 'WIRETRANSFER'">
              <div *ngIf="guaranteecard.ReferenceKey">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.guarantee"|copy|async}}</h3>
                <app-credit-card-display cardType="Guarantee"></app-credit-card-display>
              </div>
            </div>
            <div *ngIf="conferencePayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="conferencePayment && conferencecard" cardType="Conference"></app-credit-card-display>
            </div>
          </div>
        </div>
        <div *ngIf="charges.Hotel > 0">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.hotel"|copy|async}} {{charges.Hotel | currency}}</h3>
              </span></span>
            <div *ngIf="hotelPayment.MediaType === 'CHECK'">
              <div [innerHTML]='"copy.html.Check"|copy|async'></div>
            </div>
            <div *ngIf="hotelPayment.MediaType === 'WIRETRANSFER'">
              <div [innerHTML]='"copy.html.WireTransfer"|copy|async'></div>
            </div>
            <div *ngIf="hotelPayment.MediaType === 'CHECK' || hotelPayment.MediaType === 'WIRETRANSFER'">
              <div *ngIf="guaranteecard.ReferenceKey">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.hotel"|copy|async}}</h3>
                <app-credit-card-display cardType="Guarantee"></app-credit-card-display>
              </div>
            </div>
            <div *ngIf="hotelPayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="hotelPayment && hotelcard" cardType="Hotel"></app-credit-card-display>
            </div>
          </div>
        </div>
        <div *ngIf="programInfo.hotelGuarantee && (charges.Hotel === 0 || !charges.Hotel ) && enrollment.HotelNeeded">
          <div class="form-summary">
          <h3 class="w-100">{{"copy.label.HOTEL.guarantee"|copy|async}}</h3>
          <app-credit-card-display *ngIf="hotelPayment && hotelcard" cardType="Hotel"></app-credit-card-display>
        </div>
      </div>
        <div *ngIf="charges.Guest > 0">
          <div class="form-summary">
            <span class="form-summary-item w-100"><span class="form-summary-line">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.guest"|copy|async}} {{charges.Guest | currency}}</h3>
              </span></span>
            <div *ngIf="guestPayment.MediaType === 'CHECK'">
              <div [innerHTML]='"copy.html.Check"|copy|async'></div>
            </div>
            <div *ngIf="guestPayment.MediaType === 'WIRETRANSFER'">
              <div [innerHTML]='"copy.html.WireTransfer"|copy|async'></div>
            </div>
            <div *ngIf="guestPayment.MediaType === 'CHECK' || guestPayment.MediaType === 'WIRETRANSFER'">
              <div *ngIf="guaranteecard.ReferenceKey">
                <h3 class="w-100">{{"copy.label.PAYMENT.FEESRT10.guarantee"|copy|async}}</h3>
                <app-credit-card-display cardType="Guarantee"></app-credit-card-display>
              </div>
            </div>
            <div *ngIf="guestPayment.MediaType === 'CREDITCARD'">
              <app-credit-card-display *ngIf="guestPayment && guestcard" cardType="Guest"></app-credit-card-display>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="button_group d-flex">
      <button id="forward-button" type="submit" class="inputSubmit btn btn-primary ml-auto" *ngIf="!isSubmitting && !isRegComplete">
        Submit
      </button>
      <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
  <div *ngIf="errorMsg">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"copy.label.SUMMARY.heading"|copy|async}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <h2>{{"copy.label.SUMMARY.error"|copy|async}}</h2>
      <p>{{"copy.label.SUMMARY.errordetails"|copy|async}}</p>
      <p *ngIf="'travelHQ800Num'|copy|async">
        {{"copy.label.CONTACTUS.phone"|copy |async}} {{'travelHQ800Num' |copy |async}}
      </p>
      <p *ngIf="'travelHQIntlNum'|copy|async">
        {{"copy.label.CONTACTUS.phone"|copy|async}} {{'travelHQIntlNum'|copy|async}}
      </p>
      <p>{{"copy.label.CONTACTUS.email2"|copy|async}} <a href="mailto:{{'travelHQEmail'|copy|async}}">{{'travelHQEmail'|copy|async}}</a>
      </p>
      <p>{{"copy.label.CONTACTUS.hours"|copy|async}} {{'programSupportHours'|copy|async}}</p>
      <button (click)="errorMsg=undefined">Dismiss</button>
      <!-- <pre>REMOVE THIS BEFORE PROD: <br/>{{enrollment | json}}</pre> -->
    </mat-expansion-panel>
  </div>
</div>
