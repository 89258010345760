<div id="attendee" class="registration container">
  <form #attendeeForm="ngForm" name="attendeeForm" (ngSubmit)="forward(attendeeForm)" novalidate appScrollToInvalid [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{ 'copy.label.ATTENDEE.header' | copy | async }}</h1>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ATTENDEE.PERSONAL.header' | copy | async }}
        </div>
      </div>
      <article>
        <div class="card-body w-100">

          <mat-form-field class="firstNameInput">
            <mat-label for="FirstName" [ngClass]="{'active' : attendee.FirstName}">{{ 'copy.label.FEATURES.NAME.first' | copy | async }}</mat-label>
            <input  matInput name="FirstName" [disabled]="isRegComplete" id="FirstName" [attr.aria-label]="FirstName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.FirstName" #FirstName="ngModel" required />
            <mat-error><app-validation  [object]="FirstName" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="lastNameInput">
            <mat-label for="LastName" [ngClass]="{'active' : attendee.LastName}">{{ 'copy.label.FEATURES.NAME.last' | copy | async }}</mat-label>
            <input matInput name="LastName" [disabled]="isRegComplete" id="LastName" [attr.aria-label]="LastName" type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.LastName" #LastName="ngModel" required />
            <mat-error><app-validation  [object]="LastName" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="suffixInput">
            <mat-label for="Suffix">{{ 'copy.label.FEATURES.NAME.suffix' | copy | async }}</mat-label>
            <mat-select name="Suffix" [disabled]="isRegComplete" id="Suffix" aria-label="Suffix" [(ngModel)]="attendee.Suffix" matNativeControl>
              <mat-option *ngFor="let obj of suffixList" [value]="obj.code">{{ obj.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-label class="w-100 font-weight-bold">{{ 'copy.label.FEATURES.NAME.preferredheading' | copy | async }}</mat-label>

          <mat-form-field class="w-100">
            <mat-label for="PreferredFirst" [ngClass]="{'active' : attendee.PreferredFirstName}">{{ 'copy.label.FEATURES.NAME.preferredfirst' | copy | async }}</mat-label>
            <input matInput name="PreferredFirst" id="PreferredFirst" aria-label="PreferredFirst" type="text" maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.PreferredFirstName" #PreferredFirst="ngModel" />
            <mat-error><app-validation  [object]="PreferredFirst" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="PreferredLast" [ngClass]="{'active' : attendee.PreferredLastName}">{{ 'copy.label.FEATURES.NAME.preferredlast' | copy | async }}</mat-label>
            <input matInput name="PreferredLast" id="PreferredLast" [attr.aria-label]="PreferredLast" type="text" maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.PreferredLastName" #PreferredLast="ngModel" />
            <mat-error><app-validation  [object]="PreferredLast" isRequired=true pattern="alpha"></app-validation></mat-error>
          </mat-form-field>



          <!--Professional Designations-->
          <div class="w-100" *ngIf="attendee.PAXTypeNo !== 25">
            <br/>
            <label class="w-100" for="firstTime">{{'copy.label.ATTENDEE.professionaldesignations' | copy | async }}</label>
            <div class="w-100">
              <div class="d-flex justify-content-between w-100 multiSelect">
                <mat-form-field class="w-33">
                  <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async }}</mat-label>
                  <mat-select disableRipple name="profdesig1" id="profdesig1" #profdesig1="ngModel" [(ngModel)]="profDesig1" (ngModelChange)="changeprofdesig1()">
                    <mat-option *ngFor="let ss of profdesiglist1" value="{{ss}}">{{ss}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-33">
                  <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
                  <mat-select disableRipple name="profdesig2" id="profdesig2" #profdesig2="ngModel" [(ngModel)]="profDesig2" (ngModelChange)="changeprofdesig2()">
                    <mat-option *ngFor="let ss of profdesiglist2" value="{{ss}}">{{ss}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-33">
                  <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
                  <mat-select disableRipple name="profdesig3" id="profdesig3" #profdesig3="ngModel" [(ngModel)]="profDesig3" (ngModelChange)="changeprofdesig3()">
                    <mat-option *ngFor="let ss of profdesiglist3" value="{{ss}}">{{ss}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-form-field class="w-100" *ngIf="profDesig1 === 'Other'">
                <mat-label for="prodesigother1" aria-labelledby="prodesigother1" [ngClass]="{'active' : prodesigother1}">{{ 'copy.label.ATTENDEE.otherdesig' | copy | async }}</mat-label>
                <input matInput name="prodesigother1" id="prodesigother1" [attr.aria-label]="prodesigother1" type="text" maxlength="100" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="proDesigother1" #prodesigother1="ngModel" required/>
                <app-validation  [object]="prodesigother1" isRequired=true pattern="alphanum"></app-validation>
              </mat-form-field>
              <mat-form-field class="w-100" *ngIf="profDesig2 === 'Other'">
                <mat-label for="prodesigother2" aria-labelledby="prodesigother2" [ngClass]="{'active' : prodesigother2}">{{ 'copy.label.ATTENDEE.otherdesig' | copy | async }}</mat-label>
                <input matInput name="prodesigother2" id="prodesigother2" [attr.aria-label]="prodesigother2" type="text" maxlength="100" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="proDesigother2" #prodesigother2="ngModel" required/>
                <app-validation  [object]="prodesigother2" isRequired=true pattern="alphanum"></app-validation>
              </mat-form-field>
              <mat-form-field class="w-100" *ngIf="profDesig3 === 'Other'">
                <mat-label for="prodesigother3" aria-labelledby="prodesigother3" [ngClass]="{'active' : prodesigother3}">{{ 'copy.label.ATTENDEE.otherdesig' | copy | async }}</mat-label>
                <input matInput name="prodesigother3" id="prodesigother3" [attr.aria-label]="prodesigother3" type="text" maxlength="100" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="proDesigother3" #prodesigother3="ngModel" required/>
                <app-validation  [object]="prodesigother3" isRequired=true pattern="alphanum"></app-validation>
              </mat-form-field>
            </div>
          </div>

          <div>
            <mat-label class="w-100 font-weight-bold">{{ 'copy.label.ATTENDEE.EMPLOYMENT.header' | copy | async }}</mat-label>
            <mat-form-field class="w-100">
              <mat-label for="JobTitle" aria-labelledby="JobTitle" [ngClass]="{'active' : attendee.JobTitle}">{{ 'copy.label.FEATURES.JOBTITLE.label2' | copy | async }}</mat-label>
              <input matInput name="JobTitle" id="JobTitle" [attr.aria-label]="JobTitle" type="text" maxlength="35" pattern="^[\w\s!@\-\&_\\:',.\/?]*" [(ngModel)]="attendee.JobTitle" #JobTitle="ngModel" required />
              <mat-error><app-validation  [object]="JobTitle" isRequired=true pattern="alphanum"></app-validation></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label for="Department" aria-labelledby="Department" [ngClass]="{'active' : varDepartment.Value}">{{ 'copy.label.ATTENDEE.department' | copy | async }}</mat-label>
              <input matInput name="Department" id="Department" [attr.aria-label]="Department" type="text" maxlength="35" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="varDepartment.Value" #Department="ngModel" required />
              <mat-error><app-validation  [object]="Department" isRequired=true pattern="alphanum"></app-validation></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" >
              <mat-label for="committeeMemberValue">{{'copy.label.ATTENDEE.daypasscommitteemember' | copy | async}}</mat-label>
              <mat-select name="committeeMemberValue" id="committeeMemberValue" #committeeMemberValue="ngModel" [(ngModel)]="committeeMember.Value" required>
                <mat-option *ngFor="let ss of committeeMemberList" value="{{ss}}">{{ss}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div>
            <label class="w-100 font-weight-bold" for="firstTime">{{'copy.label.ATTENDEE.attendeequestion' | copy | async }}</label>
            <label class="w-100" for="firstTime">{{'copy.label.ATTENDEE.veteran' | copy | async }}</label>
            <mat-form-field class="w-40">
              <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
              <mat-select disableRipple name="varveteran" id="varveteran" #varveteran="ngModel" [(ngModel)]="varVeteran.Value" required>
                <mat-option *ngFor="let ss of veteranlist" value="{{ss}}">{{ss}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="varVeteran.Value === 'Yes - Other'">
              <mat-label for="veteranother" aria-labelledby="veteranother" [ngClass]="{'active' : varVeteranOther.Value}">{{ 'copy.label.ATTENDEE.veteranother' | copy | async }}</mat-label>
              <input matInput name="veteranother" id="veteranother" [attr.aria-label]="veteranother" type="text" maxlength="100" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="varVeteranOther.Value" #veteranother="ngModel" required/>
              <app-validation  [object]="veteranother" isRequired=true pattern="alphanum"></app-validation>
            </mat-form-field>

            <label class="w-100" for="firstTime">{{'copy.label.ATTENDEE.firstresponder' | copy | async }}</label>
            <mat-form-field class="w-40">
              <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
              <mat-select disableRipple name="varfirstresponder" id="varfirstresponder" #varfirstresponder="ngModel" [(ngModel)]="varFirstresponder.Value" required>
                <mat-option *ngFor="let ss of firstresponderlist" value="{{ss.value}}">{{ss.display}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="varFirstresponder.Value === 'Other'">
              <mat-label for="firstresponder" aria-labelledby="firstresponder" [ngClass]="{'active' : varFirstresponderOther.Value}">{{ 'copy.label.ATTENDEE.firstresponderother' | copy | async }}</mat-label>
              <input matInput name="firstresponder" id="firstresponder" [attr.aria-label]="firstresponder" type="text" maxlength="100" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="varFirstresponderOther.Value" #firstresponder="ngModel" required/>
              <app-validation  [object]="firstresponder" isRequired=true pattern="alphanum"></app-validation>
            </mat-form-field>


            <div class="w-100 required">
              <label class="w-100" for="stock" aria-labelledby="stock">{{'copy.label.ATTENDEE.lplastock'|copy|async}}
                <!-- <i class="fas fa-asterisk required"></i> --></label>
              <!-- <app-radio-group></app-radio-group> -->
              <mat-radio-group class="w-100" [(ngModel)]="varLPLAStockholder.Value" name="stock" required>
                <mat-radio-button value="Yes">Yes</mat-radio-button>
                <mat-radio-button value="No">No</mat-radio-button>
              </mat-radio-group>
            </div>


          </div>

          <div class="w-100 required">
            <mat-form-field class="w-100">
              <label>{{'copy.label.ATTENDEE.dayattend' | copy | async}}</label>
              <mat-select disableRipple name="sponsordays" id="sponsordays" #sponsordays="ngModel" [(ngModel)]="varDPDateAttend.Value" required>
                <mat-option *ngFor="let ss of dayvalues" value="{{ss}}">{{ss}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

                    <div class="w-100" [innerHTML]='"copy.html.daypassagenda" | copy | async'></div>


          <div class="required">
            <label class="w-100" for="smstext">{{'copy.label.ATTENDEE.smstext' | copy | async }} <a matTooltip="{{'copy.label.ATTENDEE.smstexttooltip'|copy|async}}">
              <i tabindex="0" class="fa fa-info-circle" aria-hidden="true"></i>
            </a></label>

            <mat-radio-group class="w-100" [(ngModel)]="SMStext.Value" required #smstext="ngModel" name="smstext">
              <mat-radio-button [value]="'Yes'">Yes</mat-radio-button>
              <mat-radio-button [value]="'No'">No</mat-radio-button>
              <div class="w-100">
                <app-validation  [object]="smstext" isRequired=true></app-validation>
              </div>
            </mat-radio-group>
          </div>
          <div *ngIf="SMStext.Value === 'Yes'">
            <label class="w-100" for="smsprovider">{{'copy.label.ATTENDEE.smsprovider' | copy | async }}</label>
            <mat-form-field class="w-40">
              <mat-label>{{'copy.label.DEMOGRAPHICS.shirtsizeplaceholder' | copy | async}}</mat-label>
              <mat-select disableRipple name="smsprovider" id="smsprovider" #stock="ngModel" [(ngModel)]="SMSprovider.Value" required>
                <mat-option *ngFor="let ss of providervalues" value="{{ss}}">{{ss}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="SMStext.Value === 'Yes' && SMSprovider.Value === 'Other'">
            <mat-form-field class="w-100">
              <mat-label for="smsproviderother" aria-labelledby="smsproviderother" [ngClass]="{'active' : smsproviderother}">{{ 'copy.label.ATTENDEE.smsproviderother' | copy | async }}</mat-label>
              <input matInput name="smsproviderother" id="initials" [attr.aria-label]="smsproviderother" type="text" maxlength="50" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="SMSproviderother.Value" #smsproviderother="ngModel" required/>
              <app-validation  [object]="smsproviderother" isRequired=true></app-validation>
            </mat-form-field>
          </div>

          <div class="w-100 required" *ngIf="varTrack.Value === 'Benefit Advisor' || varTrack.Value==='Non-Benefit Advisor'">
            <label class="w-100" for="promotionalemail">{{'copy.label.ATTENDEE.promotionalemail' | copy | async }} <a matTooltip="{{'copy.label.ATTENDEE.promotionalemailtooltip'|copy|async}}">
              <i tabindex="0" class="fa fa-info-circle" aria-hidden="true"></i>
            </a></label>
            <mat-radio-group class="w-100" [(ngModel)]="varpromotionalemail.Value" required #promotionalemail="ngModel" name="promotionalemail">
              <mat-radio-button [value]="'Yes'">Yes</mat-radio-button>
              <mat-radio-button [value]="'No'">No</mat-radio-button>
              <div class="w-100">
                <app-validation  [object]="promotionalemail" isRequired=true></app-validation>
              </div>
            </mat-radio-group>
          </div>
          <div>
            <label *ngIf="varTrack.Value === 'Benefit Advisor' || varTrack.Value==='Non-Benefit Advisor'" class="caption w-100 font-weight-bold0"><b>{{'copy.label.ATTENDEE.contactsheetheader' | copy | async }}</b></label>
            <div *ngIf="varTrack.Value === 'Benefit Advisor' || varTrack.Value==='Non-Benefit Advisor'" class="w-100 required">
              <label class="w-100" for="contact_consent">{{'copy.label.ATTENDEE.contactsheet' | copy | async }}</label>
              <mat-radio-group class="w-100" [(ngModel)]="ContactConsent.Value" required #contact_consent="ngModel" required name="contact_consent">
                <mat-radio-button [value]="'Yes'">Yes</mat-radio-button>
                <mat-radio-button [value]="'No'">No</mat-radio-button>
                <div class="w-100">
                  <app-validation  [object]="contact_consent" isRequired=true></app-validation>
                </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </article>
    </section>
    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ATTENDEE.CONTACT.header' | copy | async }}
        </div>
      </div>
      <article>
        <div class="card-body w-100">
          <!-- <div class="w-100 required">
            <label>{{ 'copy.label.FEATURES.ADDRESS.mailtolabel' | copy | async }}</label>
             <div>
              <mat-radio-group [(ngModel)]="mtcMailToAddress" name="mailTo" #mailTo="ngModel" required (ngModelChange)="setMailTo()">
                <mat-radio-button value="WORK">Business</mat-radio-button>
                <mat-radio-button value="HOME">Home</mat-radio-button>
                <div class="w-100">
                  <small class="text-danger"><app-validation  [object]="mailTo" isRequired=true></app-validation></small>
                </div>
              </mat-radio-group>
            </div>
          </div> -->
        <div [innerHTML]="'copy.label.ATTENDEE.CONTACT.warning' |copy |async"></div>
        <div class="md-form">
          <label>{{ 'copy.label.FEATURES.ADDRESS.business' | copy | async }}</label>
        </div>
        <!-- Business Address -->
        <app-address class="w-100"  [isRequired]=true [resident]=true [attendee]="attendee" [address]="workAddress" groupname="workAddress" name="workAddress"></app-address>

        <app-phone [phone]="workPhone"  isRequired=true groupname="workPhone" label="copy.label.ATTENDEE.CONTACT.bizphonelabel"></app-phone>
        <app-phone [phone]="mobilePhone"  isRequired=true groupname="mobilePhone" label="copy.label.ATTENDEE.CONTACT.cellphonelabel"></app-phone>
        <!-- Email -->
        <app-email class="w-100" [model]="emailAddress"></app-email>
        <!--Alternate Email -->
        <mat-form-field class="w-100">
          <mat-label for="altattendeeemail" aria-labelledby="altattendeeemail" [ngClass]="{'active' : altemailAddress.EmailAddress}">{{ 'copy.label.FEATURES.EMAIL.altlabel' | copy | async }}</mat-label>
          <input matInput type="text" name="altattendeeemail" id="altattendeeemail" aria-label="altattendeeemail" maxlength="100" [(ngModel)]="altemailAddress.EmailAddress" pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}" #altattendeeemail="ngModel" />
          <mat-error><app-validation  [object]="altattendeeemail" pattern="email"></app-validation></mat-error>
        </mat-form-field>
      </div>
  </article>
</section>
<!-- Emergency Contact Section -->
<section class="card">
  <div class="card-header">
    <div class="card-title">
      {{ 'copy.label.ATTENDEE.EMERGENCY.header' | copy | async }}
    </div>
  </div>
  <article>
    <div class="card-body w-100">
      <p>Please enter contact information for the person we should contact in case of an emergency while you are traveling. Please do not enter the name of a person who is traveling with you.</p>
      <mat-form-field class="w-100">
        <mat-label for="attendeeemailverify" [ngClass]="{'active' : attendee.EmergencyContact.EmergencyContactName}">
          {{ 'copy.label.FEATURES.EMERGENCY.namelabel' | copy | async }}
        </mat-label>
        <input matInput name="EmergencyContactName" id="EmergencyContactName" aria-label="EmergencyContactName"  type="text" maxlength="30" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="attendee.EmergencyContact.EmergencyContactName" #EmergencyContactName="ngModel" required />
        <mat-error><app-validation  [object]="EmergencyContactName" isRequired=true pattern="alpha"></app-validation></mat-error>
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label for="EmergencyContactRelation" aria-labelledby="EmergencyContactRelation" [ngClass]="{'active' : attendee.EmergencyContact.EmergencyContactRelation}">{{ 'copy.label.FEATURES.EMERGENCY.relationshiplabel' | copy | async }}</mat-label>
        <mat-select name="EmergencyContactRelation" id="EmergencyContactRelation"  [(ngModel)]="attendee.EmergencyContact.EmergencyContactRelation" #EmergencyContactRelation="ngModel" required>
          <mat-option *ngFor="let obj of relationlist" [value]="obj">{{ obj }}</mat-option>
        </mat-select>
        <mat-error>
        <app-validation  [object]="EmergencyContactRelation" isRequired=true pattern="alpha"></app-validation></mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" *ngIf="attendee.EmergencyContact.EmergencyContactRelation === 'Other'">
        <mat-label >{{ 'copy.label.ATTENDEE.emerrelrother' | copy | async }}</mat-label>
        <input matInput name="EmergencyContactRelationOther" id="EmergencyContactRelationOther" aria-label="EmergencyContactRelationOther" type="text" maxlength="30" pattern="[a-zA-Z ]+" [(ngModel)]="relationother" #EmergencyContactRelationOther="ngModel" required />
      </mat-form-field>

      <app-phone [phone]="emerContactMainPhone"  isRequired=true groupname="emerContactMainPhone" label="copy.label.FEATURES.EMERGENCY.phonelabel"></app-phone>
      <app-phone [phone]="emerContactAltPhone"  isRequired=false groupname="emerContactAltPhone" label="copy.label.FEATURES.EMERGENCY.altphonelabel"></app-phone>

    </div>
  </article>
</section>
<!-- Admin Section -->
<section class="card">
  <div class="card-header">
    <div class="card-title">
      {{ 'copy.label.ATTENDEE.ADMIN.header' | copy | async }}
    </div>
  </div>
  <article>
    <div class="card-body w-100">
      <div class="w-100 required">
        <label class="w-100 required">{{'copy.label.ATTENDEE.ADMIN.label' |copy | async}}</label>
        <mat-radio-group class="w-100" [(ngModel)]="enrollment.$mtcHasAdminContact" required #admin="ngModel" name="hasAdmin">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
          <div class="w-100">
            <small class="text-danger"><app-validation  [object]="admin" isRequired=true></app-validation></small>
          </div>
        </mat-radio-group>
      </div>
      <mat-form-field class="w-100" *ngIf="enrollment.$mtcHasAdminContact === true">
        <mat-label for="AdminContactName" [ngClass]="{'active' : adminContact.AdminContactName}">{{
          'copy.label.FEATURES.ADMIN.namelabel' | copy | async
          }}</mat-label>
        <input matInput name="AdminContactName" id="AdminContactName" aria-label="AdminContactName"  type="text" maxlength="60" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="adminContact.AdminContactName" #AdminContactName="ngModel" required />
        <mat-error><app-validation  [object]="AdminContactName" isRequired=true pattern="alphanum"></app-validation></mat-error>
      </mat-form-field>
      <app-phone [phone]="adminPhone"  isRequired=true groupname="adminContactPhone" label="copy.label.FEATURES.ADMIN.phonelabel" *ngIf="enrollment.$mtcHasAdminContact === true"></app-phone>
      <mat-form-field class="w-100" *ngIf="enrollment.$mtcHasAdminContact === true">
        <mat-label for="adminemail" [ngClass]="{'active' : adminContact.AdminContactEmailAddress}">{{ 'copy.label.FEATURES.ADMIN.email' | copy | async }}</mat-label>
        <input matInput type="text" name="adminemail" id="adminemail" aria-label="adminemail"  maxlength="100" [(ngModel)]="adminContact.AdminContactEmailAddress" pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}" #adminemail="ngModel" required />

        <mat-error><app-validation  [object]="adminemail" isRequired=true pattern="email"></app-validation></mat-error>
      </mat-form-field>
    </div>
  </article>
</section>
<!-- Dietary Section -->
<section class="card">
  <div class="card-header">
    <div class="card-title">
      {{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}
    </div>
  </div>
  <article>
    <div class="card-body w-100">
      <!-- <div class="w-100 required">
        <label class="w-100 floatLeft">{{ 'copy.label.ATTENDEE.DIETARY.label' | copy | async }}</label>
        <mat-radio-group class="w-100" [(ngModel)]="attendee.$mtcHasConsiderations" required #dietary="ngModel" name="hasConsiderations">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
          <div class="w-100">
            <mat-error><app-validation  [object]="dietary" isRequired=true></app-validation></mat-error>
          </div>
        </mat-radio-group>
      </div> -->
      <!-- Dietary -->
      <!-- <div class="w-100" >
        <mat-form-field class="w-100">
          <mat-label for="DietaryNeed">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</mat-label>
          <mat-select name="DietaryNeed" [(ngModel)]="attendee.DietaryNeed">
            <mat-option *ngFor="let obj of mealList" [value]="obj.code">{{ obj.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-100" *ngIf="attendee.DietaryNeed === 'OTHER' || attendee.DietaryNeed === 'FOODALLE'">
        <div class="d-flex justify-content-start">
          <mat-form-field class="w-100">
            <mat-label for="DietaryConsiderations">{{ 'copy.label.FEATURES.DIETARY.otherlabel' | copy | async }}</mat-label>
            <textarea required matInput class="w-100" name="DietaryConsiderations" id="DietaryConsiderations" aria-label="DietaryConsiderations" [(ngModel)]="attendee.DietaryConsiderations" #DietaryConsiderations="ngModel" maxlength="225"  [required]="attendee.DietaryNeed === 'OTHER' || attendee.DietaryNeed === 'FOODALLE'" pattern="^[\w\s!@\-\_:',.\/?]*"></textarea>
            <mat-error><app-validation  [object]="DietaryConsiderations" isRequired=true pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <div class="w-100 required">
        <label for="DietaryNeed" aria-labelledby="DietaryNeed">{{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</label>
      </div>
      <div class="w-100">
        <div *ngFor="let meal of mealList">
          <div class="agreeCheckbox">
            <div class="checkboxFlexContainer">
              <mat-checkbox *ngIf="meal.name !== 'Other' && meal.name !== 'Food Allergy'" name="meal_{{ meal.name }}" [ngModel]="attendee.DietaryConsiderations.includes(meal.name)" (change)="mealUpdate(meal)" aria-labelledby="{{ meal.code }}_label" [required]="!hasdietary()"></mat-checkbox>
              <mat-checkbox *ngIf="meal.name === 'Other'" name="meal_{{ meal.name }}" [(ngModel)]="dietaryOtherCheckbox" (change)="mealUpdate(meal)" aria-labelledby="{{ meal.code }}_label" [required]="!hasdietary()"></mat-checkbox>
              <mat-checkbox *ngIf="meal.name === 'Food Allergy'" name="meal_{{ meal.name }}" [(ngModel)]="allergyCheckbox" (change)="mealUpdate(meal)" aria-labelledby="{{ meal.code }}_label" [required]="!hasdietary()"></mat-checkbox>
              <label id="{{ meal.code }}_label">{{ meal.name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100" *ngIf="dietaryOtherCheckbox === true || allergyCheckbox === true">
        <div class="d-flex justify-content-start">
          <mat-form-field class="w-100">
            <mat-label for="DietaryConsiderations">{{ 'copy.label.FEATURES.DIETARY.otherlabel' | copy | async }}</mat-label>
            <textarea matInput class="w-100" name="DietaryConsiderations" id="DietaryConsiderations" aria-label="DietaryConsiderations" [(ngModel)]="mealOther" #DietaryConsiderations="ngModel" maxlength="125" pattern="^[\w\s!@\-\_:',.\/?]*" required></textarea>
            <mat-error><app-validation  [object]="DietaryConsiderations" isRequired=true pattern="alphanum"></app-validation></mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-error *ngIf="hasdietary() === false && enrollment.$mtcshowselecterror">{{ 'copy.label.ATTENDEE.adaError' | copy | async }}</mat-error>

      <!-- Personal -->
      <div class="w-100 required">
        <label for="DietaryNeed" aria-labelledby="DietaryNeed">{{ 'copy.label.FEATURES.PERSONAL.heading' | copy | async }}</label>
      </div>


      <div class="w-100" >
        <div *ngFor="let considerations of personalConsiderationsList">
          <div class="agreeCheckbox">
            <div class="checkboxFlexContainer">
              <mat-checkbox name="considerations_{{ considerations.name }}" [ngModel]="includes(attendee.DisabilityType,considerations.code)" (change)="considerationUpdate(considerations)" aria-labelledby="{{ considerations.code }}_label" [required]="!haspersonalconsiderations()"></mat-checkbox>
              <label id="{{ considerations.code }}_label">{{ considerations.name }}</label>
          <!-- {{personalConsiderations.invalid}} -->
            </div>
          </div>
        </div>
      </div>

      <mat-error *ngIf="haspersonalconsiderations() === false && enrollment.$mtcshowselecterror">{{ 'copy.label.ATTENDEE.adaError' | copy | async }}</mat-error>

        <div class="w-100" *ngIf="adaOtherCheckbox === true">
          <div class="d-flex justify-content-start">
            <mat-form-field class="w-100 longQuestionText">
              <mat-label for="PersonalConsiderations" aria-labelledby="PersonalConsiderations">{{ 'copy.label.FEATURES.PERSONAL.otherlabel' | copy | async }}</mat-label>
              <textarea matInput class="w-100" name="PersonalConsiderations" id="PersonalConsiderations" aria-label="PersonalConsiderations" [(ngModel)]="attendee.PersonalConsiderations" #PersonalConsiderations="ngModel" maxlength="225" pattern="^[\w\s!@\-\_:',.\/?]*" required></textarea>
              <mat-error><app-validation  [object]="PersonalConsiderations" isRequired=true pattern="alphanum"></app-validation></mat-error>
            </mat-form-field>
          </div>
        </div>

      <div>
        <br/>
        <label class="w-100" for="firstTime">{{'copy.label.FEATURES.DIETARY.scooter1' | copy | async }}</label>
        <mat-form-field class="w-40">
          <mat-label>{{'copy.label.FEATURES.DIETARY.scooter2' | copy | async}}</mat-label>
          <mat-select disableRipple name="scooter" id="scooter" #scooter="ngModel" [(ngModel)]="varScooter.Value">
            <mat-option *ngFor="let ss of yesno" value="{{ss}}">{{ss}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="varScooter.Value === 'Yes'">
          <mat-label for="scootercomment" aria-labelledby="scootercomment" [ngClass]="{'active' : scootercomment}">{{'copy.label.FEATURES.DIETARY.scooter3' | copy | async }}</mat-label>
          <input matInput name="scootercomment" id="scootercomment" [attr.aria-label]="scootercomment" type="text" maxlength="100" pattern="^[\w\s!@\-\_\\:',.\/?]*" [(ngModel)]="varScooterComments.Value" #scootercomment="ngModel"/>
          <app-validation  [object]="scootercomment" isRequired=true pattern="alphanum"></app-validation>
        </mat-form-field>
     </div>

    </div>
  </article>
</section>
<div class="button_group d-flex">
  <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()" *ngIf="!isSubmitting">
    {{ 'copy.label.GLOBAL.goback' | copy | async }}
  </button>
  <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
    {{ 'copy.label.GLOBAL.continue' | copy | async }}
  </button>
  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
    <i class="fas fa-spinner fa-spin"></i>
  </button>
</div>
</form>
</div>
