import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../../services/registration.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html'
})
export class BadgeComponent implements OnInit {
  attendee: any;
  config: any;
  showbadgeInfo: any;
  showbadge: any;

  constructor(private regService: RegistrationService, private configService: ConfigurationService) { }

  ngOnInit(): void {
    this.attendee = this.regService.getAttendee({});
    this.configService.fetchConfig().then(data => {
      this.config = data;
      this.showbadge = this.config.showbadge;
    });
  }
}
