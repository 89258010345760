<div class="w-100"  provide-parent-form >
  <div ngModelGroup="email" appEmailmatch>

    <!-- <div> -->
      <mat-form-field class="w-100">
        <mat-label for="attendeeemail" [ngClass]="{'active' : model.EmailAddress}">{{ 'copy.label.FEATURES.EMAIL.label' | copy | async }}</mat-label>
        <input matInput type="text" name="attendeeemail" id="attendeeemail" aria-label="attendeeemail"  maxlength="100" [(ngModel)]="model.EmailAddress" pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}" #attendeeemail="ngModel" required appUniqueemail/>
        <mat-error><app-validation  [object]="attendeeemail" isRequired=true pattern="email"></app-validation></mat-error>
      </mat-form-field>

      <!-- <mat-form-field class="w-100">
        <mat-label for="attendeeemailverify" [ngClass]="{'active' : model.$mtcverifyEmailAddress}">{{ 'copy.label.FEATURES.EMAIL.verifylabel' | copy | async }}</mat-label>
        <input matInput type="text" name="attendeeemailverify" id="attendeeemailverify" aria-label="attendeeemailverify"  appPreventPaste maxlength="100" [(ngModel)]="model.$mtcverifyEmailAddress" pattern="[a-zA-Z0-9._'%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}" #attendeeemailverify="ngModel" required />
        <mat-error><app-validation class="w-100"  [object]="attendeeemailverify" isRequired=true pattern="email"></app-validation></mat-error>
        
      </mat-form-field> -->
    </div>

</div>
