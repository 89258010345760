<!-- AGENDA USED IN FIB SITES (SIMPLE AGENDA) -->
<!-- <div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div id="square-container" class="d-flex flex-column flex-md-row justify-content-between" [innerHTML]='"copy.html.FIBAgenda.FIBAgendaCopy"|copy|async'></div>
    </div>
</div> -->

<!-- TABBED AGENDA (USED IN TOYOTA AND OTHER CUSTOM SITES) -->
<div class="contentFullVerticalHeight halfVerticalHeight">
  <div class="container" id="agenda">
    <section class="agendaTabs">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li class="nav-item" [ngbNavItem]="1">
          <a ngbNavLink>
            <span class="day">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day1.AgendaDay' | copy | async"
              ></div>
            </span>
            <span class="date">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day1.AgendaDate' | copy | async"
              ></div>
            </span>
            <span class="curve"></span>
          </a>
          <ng-template ngbNavContent>
            <div
              [innerHTML]="'copy.html.PretripAgenda.Page.Day1.AgendaDay1' | copy | async"
            ></div>
          </ng-template>
        </li>
        <li class="nav-item" [ngbNavItem]="2">
          <a ngbNavLink>
            <span class="day">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day2.AgendaDay' | copy | async"
              ></div>
            </span>
            <span class="date">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day2.AgendaDate' | copy | async"
              ></div>
            </span>
            <span class="curve"></span>
          </a>
          <ng-template ngbNavContent>
            <div
              [innerHTML]="'copy.html.PretripAgenda.Page.Day2.AgendaDay2' | copy | async"
            ></div>
          </ng-template>
        </li>
        <li class="nav-item" [ngbNavItem]="3">
          <a ngbNavLink>
            <span class="day">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day3.AgendaDay' | copy | async"
              ></div>
            </span>
            <span class="date">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day3.AgendaDate' | copy | async"
              ></div>
            </span>
            <span class="curve"></span>
          </a>
          <ng-template ngbNavContent>
            <div
              [innerHTML]="'copy.html.PretripAgenda.Page.Day3.AgendaDay3' | copy | async"
            ></div>
          </ng-template>
        </li>

        <li class="nav-item" [ngbNavItem]="4">
          <a ngbNavLink>
            <span class="day">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day4.AgendaDay' | copy | async"
              ></div>
            </span>
            <span class="date">
              <div
                [innerHTML]="'copy.html.PretripAgenda.Page.Day4.AgendaDate' | copy | async"
              ></div>
            </span>
            <span class="curve"></span>
          </a>
          <ng-template ngbNavContent>
            <div
              [innerHTML]="'copy.html.PretripAgenda.Page.Day4.AgendaDay4' | copy | async"
            ></div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </section>

    <div
      [innerHTML]="'copy.html.PretripAgenda.PretripAgendaCopy' | copy | async"
    ></div>
  </div>
</div>
