import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigationService } from '../../services/navigation.service';
import { EnrollmentService } from '../../services/enrollment.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ForgotConfirmationComponent } from '../../reusable-components/forgot-confirmation/forgot-confirmation.component';
import { ModalService } from 'src/app/services/modal.service';
import { PersistenceService } from '../../services/persistence.service';
import { Router } from '@angular/router';
import { RememberService } from 'src/app/services/remember.service';

@Component({
  templateUrl: './onsite.component.html',
  styleUrls: ['./onsite.component.scss']
})
export class OnsiteComponent implements OnInit {


  loginID;
  regcode;
  confirmationNo;
  emailAddress;
  errorMsg;
  TrackFocusDirective;
  isFormCompleted = false;
  isSubmitting = false;
  rememberme=false;
  foundcookie=false;
  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private enrollmentService: EnrollmentService,
    public dialog: MatDialog,
    public modalService: ModalService,
    private persistenceService: PersistenceService,
    private router: Router,
    private rememberService: RememberService
  ) {
    this.persistenceService.clear();
    let member = this.rememberService.getCookie();
    if(member){
      console.log(member);
      this.rememberme = true;
      this.foundcookie = true;
      this.confirmationNo = member.confcode;
      this.emailAddress = member.email;
    }
  }

  ngOnInit(): void { }

  declineButtonClick() {

    this.persistenceService.testStorage();
    this.persistenceService.clear();

    const creds: any = {};
    console.log(this.loginID);
    creds.username = this.loginID;
    creds.password = 'password';

    if (this.loginID) {
      this.authenticationService.initialLogin(creds, this.regcode).then(() => {
        //  promise resolved
        //this.router.navigate(['/invitation']);
        this.authenticationService.updateProfile('CANCELLED', "Declined from Login Page").subscribe(() => {
          this.router.navigate(['decline']);
      });

      }, (err) => {
        this.errorMsg = err;
      });
    }

  }

  openModal(copy, title, actionButtonText) {
    this.modalService.openModal(copy, title, actionButtonText);
  }

  openForgotConf(): void {
    let dialogRef = this.dialog.open(ForgotConfirmationComponent, { panelClass: 'forgot-dialog-container' });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   this.animal = result;
    // });
  }

  forward() {
    if(!this.isSubmitting){
      this.isSubmitting =true;
      const creds: any = {};
      this.isFormCompleted = true;
      if(this.foundcookie && !this.rememberme){
        this.rememberService.deleteCookie();
      }
      if (this.confirmationNo) {
        if (this.emailAddress) {
          creds.confirmationCode = this.confirmationNo;
          creds.emailAddress = this.emailAddress;
          this.authenticationService.rvsLogin(creds).then(() => {
            //  authService resolved
            this.enrollmentService.rvsGetEnrollment().then(() => {
              if(!this.foundcookie && this.rememberme){
                this.rememberService.setCookie(this.confirmationNo, this.emailAddress);
              }
              // enrollmentService resolved
              this.navigationService.forwardrvs();
            });
          }, (err) => {
            this.isSubmitting =false;
            this.errorMsg = err;
          });
        } else {
          this.isSubmitting =false;
          this.errorMsg =
            'Enter your email address with your confirmation number.';
        }
      } else if (this.loginID) {
        creds.username = this.loginID;
        creds.password = 'password';
        this.authenticationService.initialLogin(creds, this.regcode).then(() => {
          // promise resolved
          this.navigationService.forward();
        }, (err) => {
          this.isSubmitting =false;
          this.errorMsg = err;
        });
      } else {
        this.isSubmitting =false;
        this.errorMsg = 'Please enter a Login ID or Confirmation Code.';
      }
    }
  }

}
