<mat-toolbar class="logoutbar" *ngIf="!notlogin()">
  <span class="example-spacer"></span>
  <a (click)="openCart()" *ngIf="displayCart()"><!-- {{"copy.label.GLOBAL.cart"|copy|async}} --><i class="fas fa-comment-dollar"></i></a>
  <a (click)="openContactUs()"><!-- {{"copy.label.LOGOUT.contact"|copy|async}} --><i class="fas fa-envelope"></i></a>
  <app-logout></app-logout>
</mat-toolbar>
<div role="banner" class="bannerBackground">
  <div class="banner-img">
    <img alt="header" src="assets/images/focus-2024-logo-small.svg" />
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark w-100" *ngIf="showNav()">
  <button class="navbar-toggler" type="button" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <button ngbNavLink *ngIf="!isRegistrationComplete() && isCollapsed" class="btn btn-primary d-block d-lg-none" (click)="forward()"> {{"copy.label.NAVIGATION.register.register"| copy | async}}
        </button>
  <div class="collapse navbar-collapse" id="navbarNav" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <ul ngbNav [(activeId)]="active" class="navbar-nav w-100 d-flex justify-content-end align-items-center">
      <!-- <li [ngbNavItem]="1" class="nav-item">
        <a ngbNavLink routerLink="pretrip" class="nav-link" (click)="isMobile()">{{"copy.label.NAVIGATION.pretrip.home"| copy | async}} <span class="sr-only">(current)</span></a>
      </li>
      <li [ngbNavItem]="2" class="nav-item">
        <a ngbNavLink routerLink="pretrip/agenda" class="nav-link" (click)="isMobile()">{{"copy.label.NAVIGATION.pretrip.agenda"| copy | async}}</a>
      </li>
      <li [ngbNavItem]="3" class="nav-item">
        <a ngbNavLink routerLink="pretrip/destination" class="nav-link" (click)="isMobile()">{{"copy.label.NAVIGATION.pretrip.destination"| copy | async}}</a>
      </li>
      <li [ngbNavItem]="4" class="nav-item">
        <a ngbNavLink routerLink="pretrip/hotels" class="nav-link" (click)="isMobile()">{{"copy.label.NAVIGATION.pretrip.hotels"| copy | async}}</a>
      </li>
      <li [ngbNavItem]="5" class="nav-item">
        <a ngbNavLink routerLink="pretrip/activities" class="nav-link" (click)="isMobile()">{{"copy.label.NAVIGATION.pretrip.activities"| copy | async}}</a>
      </li>
      <li [ngbNavItem]="6" class="nav-item">
        <a ngbNavLink routerLink="pretrip/travel" class="nav-link" (click)="isMobile()">{{"copy.label.NAVIGATION.pretrip.travelinfo"| copy | async}}</a>
      </li> -->
      <!-- reg button -->
      <!-- <li ngbNavItem class="nav-item ml-0 ml-md-auto">
        <button ngbNavLink *ngIf="!isRegistrationComplete()" class="btn btn-light is-register" (click)="forward()"> {{"copy.label.NAVIGATION.register.register"| copy | async}}
        </button>
      </li>
      <li [ngbNavItem]="7" *ngIf="isRegistrationComplete()" class="nav-item dropdownElement ml-auto">
        <div ngbDropdown class="dropdown">
          <a ngbDropdownToggle class="btn btn-light dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{"copy.label.NAVIGATION.register.registerrvs"| copy | async}}
          </a>
          <div ngbDropdownMenu *ngIf="isRegistrationComplete()" aria-labelledby="dropdownMenuLink" class="dropdown-menu">
            <a ngbDropdownItem class="dropdown-item" routerLink="summary" (click)="isCollapsed = !isCollapsed">{{"copy.label.NAVIGATION.register.summary"| copy | async}}</a>
            <a ngbDropdownItem class="dropdown-item" routerLink="cancellation" (click)="isCollapsed = !isCollapsed">{{"copy.label.NAVIGATION.register.cancel"| copy | async}}</a>
            <a ngbDropdownItem class="dropdown-item" routerLink="replacement" (click)="isCollapsed = !isCollapsed">{{"copy.label.NAVIGATION.register.replace"| copy | async}}</a>
            <a ngbDropdownItem *ngIf="showInvoice" class="dropdown-item" href="{{backendurl}}service/invoice/{{programkey}}/{{token}}.pdf" target="_blank" (click)="isCollapsed = !isCollapsed">{{"copy.label.NAVIGATION.register.invoice"| copy | async}}</a>
            <a ngbDropdownItem *ngIf="isRegistrationComplete()" class="dropdown-item" href="{{backendurl}}service/docusign/{{programkey}}/{{token}}" target="_blank" (click)="isCollapsed = !isCollapsed">Docusign</a>
            <a ngbDropdownItem *ngIf="isRegistrationComplete()" class="dropdown-item" href="{{backendurl}}service/adobesign/{{programkey}}/{{token}}" target="_blank" (click)="isCollapsed = !isCollapsed">Adobe Sign</a>
          </div>
        </div>
      </li> -->
    </ul>
  </div>
</nav>
<mat-horizontal-stepper *ngIf="progresslist && !processing && !notlogin() && !isRegistrationComplete()" labelPosition="bottom" linear #stepper [selectedIndex]="selectedIndex">
  <mat-step *ngFor="let navpage of progresslist" [label]="navpage.display" editable="false">
  </mat-step>
</mat-horizontal-stepper>
