import { Component, ViewChild, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactusComponent } from '../contactus/contactus.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import * as _ from 'lodash';
import { MatStepper } from '@angular/material/stepper';
import { CopyService } from 'src/app/services/copy.service';
import { CartComponent } from '../cart/cart.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LineitemService } from 'src/app/services/lineitem.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent implements OnInit {
  isRegComplete: any;
  isCollapsed: any;
  config: any;
  showInvoice = false;
  token: any;
  programkey;
  backendurl;
  nav;
  progresslist;
  @ViewChild('stepper')
  stepper: MatStepper;
  selectedIndex;
  processing;
  showCart;
  active = 1;
  hideProgress;
  isPretrip;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private copyService: CopyService,
    private authService: AuthenticationService,
    private environment: EnvironmentService,
    private lineitemService: LineitemService,
    private renderer: Renderer2
  ) {

    this.isCollapsed = true;
    this.token = authService.authtoken;
    this.programkey = environment.getProgramKey();
    this.backendurl = environment.getEnvironmentUrl(undefined);

    router.events.subscribe((val) => {
      this.token = authService.authtoken;
      if (val instanceof NavigationEnd) {
        this.invoicelookup();

        this.isPretrip = (
            this.router.url.indexOf('/pretrip') !== -1
        );
        //console.log (this.isPretrip == true);
        if(this.isPretrip == true) {
          this.renderer.addClass(document.body, 'hideProgress');
        }
        else {
          this.renderer.removeClass(document.body, 'hideProgress');
        }

        this.getprogress();
      }
    });

    this.copyService.getCopyByKey("showCart").then( (cart)=>{
      this.showCart = cart;
    });
  }

  ngOnInit(): void {
    //this.renderer.addClass(document.body, 'hideProgress');
    this.getprogress()
  }

  invoicelookup(){
    if(this.registrationService.isRegistrationComplete()){
      this.lineitemService.invoiceLookup().subscribe(data=>{
        console.log('invoice success');
          this.showInvoice = true;
      });
    }
  }

  getprogress() {
    //this hides the progress bar in Pretrip.
    if (this.router.url.indexOf('pretrip') >= 0) {
      this.renderer.addClass(document.body, 'hideProgress');
    } else {
      this.renderer.removeClass(document.body, 'hideProgress');
    }

    this.navigationService.getNavigationList().then((nav: Array<any>) => {
      this.nav = nav;
      this.progresslist = nav.map((navitem) => {
        return { "route": navitem, "display": this.format(navitem[0]) };
      });

      _.remove(this.progresslist, (item: any) => {
        return item.display === 'Login' || item.display === 'Pretrip';
      });

      this.setprogress();
      this.showNav();
    });
  }

  setprogress(){
    this.processing = true;
    let routeIndex = 0;
    let currentroute = decodeURI(this.router.url);
    this.progresslist.forEach((navitem,index) => {
      navitem.route.forEach(route => {
        if(route === currentroute){
          routeIndex = index;
        }
      });
    });
    this.selectedIndex = routeIndex;
  //   this.stepper.selectedIndex = routeIndex;
    setTimeout(() => {
      this.processing = false;
      //this.stepper.linear = true;
   });
  }

  format(item:string){
    item = item.slice(1);
    if( item === "guest-travel" ) {
        return "Guest Travel";
    } else if( item === "hotelbooking" ) {
        return "Hotel";
    } else {
        return _.capitalize( item );
    }
  }

   openContactUs() {
    const dialogRef = this.dialog.open(ContactusComponent, {
			disableClose: true,
			data: { row: null }
		});

		dialogRef.afterClosed().subscribe(result => {
		});
  }

  displayCart(){
    if(this.showCart === "true"){
      if(!this.isRegistrationComplete()){
        return true;
      }
    }
    return false;
  }

  openCart(){
    const dialogRef = this.dialog.open(CartComponent, {
			disableClose: true,
			width: '1000px',
			height: 'auto',
			data: { row: null }
		});

		dialogRef.afterClosed().subscribe(result => {
		});
  }

  notlogin() {
    return this.router.isActive('/login', true) || this.router.isActive('/instructions', true) || this.router.isActive('/', true) || this.router.isActive('/timeout', true);
  }

  forward() {
    this.navigationService.forward();
  }

  isRegistrationComplete() {
    return this.registrationService.isRegistrationComplete();
  }

  showNav() {
    let showNav = false;
    if ((this.router.url === '/summary' || this.router.url === '/acknowledgement') &&
    this.registrationService.isRegistrationComplete()) {
      showNav = true;
    }
    if (this.router.url.indexOf('/pretrip') !== -1 ) {
      showNav = true;
    }
    if(!showNav){
      //look for pages outside of normal navigation flow
      let found = false;
      if(this.nav){
        this.nav.forEach(state => {
          if(state.indexOf(this.router.url) != -1){
            found = true;
          }
        });
        if(!found){
          showNav = true;
        }
      }
    }
    if (this.router.url.indexOf('/timeout') !== -1 ) {
      showNav = false;
    }
    return showNav;
  }

  isMobile() {
    let width = screen.width;
    
    if (width <= 640) {
      this.isCollapsed = !this.isCollapsed;
      // console.log("IS MOBILE!")
    }
  }

}
