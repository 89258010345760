<div id="acknowledgment" class="registration container">
  <form novalidate autocomplete="off">
    <h1>{{ 'copy.label.ACKNOWLEDGEMENT.confirmation' | copy | async }}</h1>

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ACKNOWLEDGEMENT.success' | copy | async }}
        </div> 
      </div>
      <article class="p-4">
        <!-- <h3 class="w-100">{{'returnLogin' | copy | async}}: <span class="number" id="confCode">{{enrollment.$mtcConfirmationCode}}</span></h3> -->
        <p class="w-100">
          {{ 'copy.label.ACKNOWLEDGEMENT.daypassmessage' | copy | async }}
        </p>
      </article>
    </section>

    <!-- <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.LOGOUT.contact' | copy | async }}
        </div>
      </div>
      <div class="travelLinksPrimaryAndGuest">
        <article class="p-4">
          <a (click)="openContactModal()">{{'copy.label.ACKNOWLEDGEMENT.question'|copy|async}}</a>
        </article>
      </div>
    </section> -->

    <section class="card">
      <div class="card-header">
        <div class="card-title">
          {{ 'copy.label.ACKNOWLEDGEMENT.traveldesk' | copy | async }}
        </div>
      </div>
      <div class="travelLinksPrimaryAndGuest">
        <article class="p-4">
          <p *ngIf="phone0">
            {{"copy.label.CONTACTUS.phone"|copy |async}} {{'travelHQ800Num' | copy | async}}
          </p>
          <p *ngIf="phone1">
            {{"copy.label.CONTACTUS.phone"|copy|async}} {{'travelHQIntlNum' | copy | async}}
          </p>
          <p>{{"copy.label.CONTACTUS.email2"|copy|async}} <a
              href="mailto:{{'travelHQEmail' | copy | async}}">{{'travelHQEmail' | copy | async}}</a>
          </p>
          <p>{{"copy.label.CONTACTUS.hours"|copy|async}} {{'programSupportHours' | copy | async}}</p>
        </article>
      </div>
    </section>
  </form>
</div>
