import { Component, Input } from '@angular/core';
import { ListManagerService } from '../../services/list-manager.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddressService } from '../../services/address.service';
import { PhoneService } from 'src/app/services/phone.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class AddressComponent {

  @Input() resident: boolean;
  @Input() attendee: any;
  @Input() address: any;
  @Input() isRequired: any;
  @Input() isError: any;
  @Input() groupname: any;

  stateList: any;
  countryList: any;
  provinceList: any;

  constructor(
    private listService: ListManagerService,
    private addressService: AddressService,
    private phoneService: PhoneService
  ) {
    this.listService.getStateList().then(list => {
      this.stateList = list;
    });
    this.listService.getCountryList().then(list => {
      this.countryList = list;
    });
    this.listService.getProvinceList().then(list => {
      this.provinceList = list;
    });
  }

  countryChange(){
    this.setCountryOfResidence();
    if(this.address.MailTo){
      this.phoneService.triggeredChange();
    }
  }

  setCountryOfResidence(){
    if(this.resident){
      if(this.attendee.$mtcvarCountryOfRes){
        this.attendee.ResidenceCountryISOCode = this.address.CountryISOCode;
      }
    }
  }

  zipChange(zip) {
    if (this.isZipValidForUSA(zip.viewModel)) {
      this.addressService.getCityState(zip.viewModel).subscribe(data => {
        this.address.City = data.city;
        this.address.StateProvince = data.state;
      });
    }
  }

  isZipValidForUSA(zipCode) {
    if (zipCode && this.address.CountryISOCode==='US' ) {
      return /^[0-9]{5}$/.test(zipCode);
    }
    return false;
  }
}
