<div id="guest" class="registration container">
    <form #guestForm="ngForm" name="guestForm" (ngSubmit)="forward(guestForm)" novalidate appScrollToInvalid [panels]="panels"
        [ngClass]="{'form-complete': isFormCompleted}">
        <h1>{{ 'copy.label.GUEST.header' | copy | async }}</h1>
        <div id="rvs-text" *ngIf="isRegComplete">
          <a (click)="openContact()">{{"copy.label.GUEST.rvstext"|copy|async}}</a>
        </div>
        <section class="card">
            <div class="card-header">
                <div class="card-title">
                    {{ 'copy.label.GUEST.label' | copy | async }}
                    <i class="fas fa-asterisk required"></i>
                </div>
            </div>
            <article>
                <mat-radio-group class="w-100" [(ngModel)]="attendee.$mtcHasGuestsAttending" [disabled]="isRegComplete" #hasGuest="ngModel" name="hasGuest" required>
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                    <div class="w-100">
                        <small class="text-danger"><app-validation [object]="hasGuest" isRequired=true></app-validation></small>
                    </div>
                </mat-radio-group>

                <!-- INSERTED CODE (FROM BELOW) -->
                <div *ngIf="attendee.$mtcHasGuestsAttending" class="p-2 bg-warning rounded">
                    <div class="agreeCheckbox">
                        <div class="checkboxFlexContainer">
                            <mat-checkbox name="hasGuestAgeAcknowledged" [(ngModel)]="enrollment.$mtcHasGuestAgeAcknowledged"
                                [disabled]="isRegComplete" #hasGuestAgeAcknowledged="ngModel" required>
                            </mat-checkbox>
                            <label class="required p-0 font-weight-bold">I acknowledge that my guest will be 21 years of age or older on the first day of the event.</label>
                        </div>
                        <div class="w-100">
                            <small class="text-danger"><app-validation class="w-100" [object]="hasGuestAgeAcknowledged" isRequired=true></app-validation></small>
                        </div>
                    </div>
                </div>
            </article>
        </section>

        <!-- ORIGINAL CODE -->
        <!-- <div *ngIf="attendee.$mtcHasGuestsAttending">
            <article class="w-100">
                <div class="agreeCheckbox">
                    <div class="checkboxFlexContainer">
                        <mat-checkbox name="hasGuestAgeAcknowledged" [(ngModel)]="enrollment.$mtcHasGuestAgeAcknowledged"
                            [disabled]="isRegComplete" #hasGuestAgeAcknowledged="ngModel" required>
                        </mat-checkbox>
                        <label class="required">I acknowledge that my guest will be 21 years of age or older on the first day of the event.</label>
                    </div>
                    <div class="w-100">
                        <small class="text-danger"><app-validation class="w-100" [object]="hasGuestAgeAcknowledged" isRequired=true></app-validation></small>
                    </div>
                </div>
            </article>
            <h2 class="square">{{"copy.label.GUEST.GUESTFORM.heading"|copy|async}}</h2>
        </div> -->

        <!-- INSERTED CODE (FROM ABOVE) -->
        <div *ngIf="attendee.$mtcHasGuestsAttending" class="mt-2 mt-md-4">
            <h2 class="square">{{"copy.label.GUEST.GUESTFORM.heading"|copy|async}}</h2>
        </div>

        <mat-accordion *ngIf="attendee.$mtcHasGuestsAttending" class="example-headers-align mb-4 w-100" multi>
            <div *ngIf="isAddGuestEnabled() && !isRegComplete" class="mb-2">
                <button type="button" (click)="addGuest({})"
                    class="add-guest btn btn-success">{{'copy.label.GUEST.GUESTFORM.add'|copy|async}}
                </button>
            </div>
            <mat-expansion-panel *ngFor="let guest of guests"
                [ngClass]="{'active': guest.$mtcIsSelected}"
                class="mat-expanded"
                [expanded]="guest.$mtcIsSelected">

                <mat-expansion-panel-header class="w-100">
                    <mat-panel-title>
                        <strong>{{getDisplayName(guest)}}</strong>
                    </mat-panel-title>
                    <mat-panel-description>
                        &nbsp;
                        <mat-icon></mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div *ngIf="isRemoveGuestEnabled() && !isRegComplete" class="remove-guest text-right">
                    <button (click)="removeGuest(guest)" type="button"
                        class="btn btn-link">{{"copy.label.GUEST.GUESTFORM.remove"|copy|async}}</button>
                </div>

                <div class="w-100 required mt-3">
                    <label class="w-100 floatLeft" for="PAXTypeNo"
                        aria-labelledby="PAXTypeNo">{{ 'copy.label.FEATURES.GUESTTYPE.heading' | copy | async }}</label>
                    <mat-radio-group [(ngModel)]="guest.PAXTypeNo" name="PAXTypeNo_{{guest.$mtcUUID}}" aria-label="PAXTypeNo"
                        #PAXTypeNo="ngModel" id="PAXTypeNo" matNativeControl required [disabled]="isRegComplete" class="flex-row">
                        <mat-radio-button class="px-2" *ngFor="let op of guestTypes" [value]="op.value">{{op.display}}
                        </mat-radio-button>
                        <div class="w-100">
                            <small class="text-danger"><app-validation [object]="PAXTypeNo" isRequired=true></app-validation></small>
                        </div>
                    </mat-radio-group>
                </div>
                <div class="nameFields">
                    <mat-form-field>
                        <mat-label for="Prefix" aria-labelledby="Prefix">
                            {{ 'copy.label.FEATURES.NAME.prefixlabel' | copy | async }}</mat-label>
                        <mat-select [disabled]="isRegComplete" name="Prefix_{{guest.$mtcUUID}}" id="Prefix_{{guest.$mtcUUID}}" aria-label="Prefix" [(ngModel)]="guest.Prefix"
                            #Prefix="ngModel" matNativeControl>
                            <mat-option *ngFor="let obj of prefixList" [value]="obj.code">{{ obj.name }}</mat-option>
                        </mat-select>
                        <mat-error><app-validation [object]="Prefix" isRequired=true></app-validation></mat-error>
                    </mat-form-field>

                    <mat-form-field class="firstNameInput">
                        <mat-label for="FirstName" aria-labelledby="FirstName" [ngClass]="{'active' : guest.FirstName}">
                            {{ 'copy.label.FEATURES.NAME.first' | copy | async }}</mat-label>
                        <input [disabled]="isRegComplete" matInput name="FirstName_{{guest.$mtcUUID}}" id="FirstName_{{guest.$mtcUUID}}" [attr.aria-label]="FirstName" type="text"
                             maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="guest.FirstName"
                            #FirstName="ngModel" required />
                        <mat-error><app-validation [object]="FirstName" isRequired=true pattern="alpha"></app-validation></mat-error>
                    </mat-form-field>



                    <mat-form-field class="lastNameInput">
                        <mat-label for="LastName" aria-labelledby="LastName" [ngClass]="{'active' : guest.LastName}">
                            {{ 'copy.label.FEATURES.NAME.last' | copy | async }}</mat-label>
                        <input [disabled]="isRegComplete" matInput name="LastName_{{guest.$mtcUUID}}" id="LastName_{{guest.$mtcUUID}}" [attr.aria-label]="LastName" type="text"
                             maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="guest.LastName"
                            #LastName="ngModel" required />
                        <mat-error><app-validation [object]="LastName" isRequired=true pattern="alpha"></app-validation></mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label for="Suffix" aria-labelledby="Suffix">
                            {{ 'copy.label.FEATURES.NAME.suffix' | copy | async }}</mat-label>
                        <mat-select [disabled]="isRegComplete" name="Suffix_{{guest.$mtcUUID}}" id="Suffix_{{guest.$mtcUUID}}" aria-label="Suffix" [(ngModel)]="guest.Suffix"
                            matNativeControl>
                            <mat-option *ngFor="let obj of suffixList" [value]="obj.code">{{ obj.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field class="w-100">
                    <mat-label for="PreferredFirst" aria-labelledby="PreferredFirst"
                        [ngClass]="{'active' : guest.PreferredFirstName}">
                        {{ 'copy.label.FEATURES.NAME.preferredfirst' | copy | async }}</mat-label>
                    <input matInput name="PreferredFirst_{{guest.$mtcUUID}}" id="PreferredFirst_{{guest.$mtcUUID}}" aria-label="PreferredFirst" type="text"
                         maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$" [(ngModel)]="guest.PreferredFirstName"
                        #PreferredFirst="ngModel" />
                    <mat-error><app-validation [object]="PreferredFirst" isRequired=true pattern="alpha"></app-validation></mat-error>
                </mat-form-field>

                <mat-form-field class="w-100">
                    <mat-label for="PreferredLast" aria-labelledby="PreferredLast"
                        [ngClass]="{'active' : guest.PreferredLastName}">
                        {{ 'copy.label.FEATURES.NAME.preferredlast' | copy | async }}</mat-label>
                    <input matInput name="PreferredLast_{{guest.$mtcUUID}}" id="PreferredLast_{{guest.$mtcUUID}}" [attr.aria-label]="PreferredLast"
                        type="text"  maxlength="25" pattern="^[A-Za-z\s\-\_'.]*$"
                        [(ngModel)]="guest.PreferredLastName" #PreferredLast="ngModel" />
                    <mat-error><app-validation [object]="PreferredLast" isRequired=true pattern="alpha"></app-validation></mat-error>
                </mat-form-field>

              <!-- child birthdate -->
              <app-date *ngIf="isChild(guest) && !isRegComplete" [participant]="guest" fieldName="BirthDate" [submitted]="isError"
              label="copy.label.TRAVEL.birthdate" [isRequired]="true" [minDate]="minChildDate"
              maxDate="today"></app-date>

                <!-- <div class="w-100 required">
                    <label class="w-100 floatLeft">{{ 'copy.label.GUEST.GUESTFORM.adalabel' | copy | async }}</label>
                    <mat-radio-group class="w-100" [(ngModel)]="guest.$mtcHasConsiderations" required #dietary="ngModel"
                    name="hasConsiderations_{{guest.$mtcUUID}}">
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                        <div class="w-100">
                            <mat-error><app-validation [object]="dietary" isRequired=true></app-validation></mat-error>
                        </div>
                    </mat-radio-group>
                </div> -->
                <h4>{{ 'copy.label.ATTENDEE.DIETARY.header' | copy | async }}</h4>
                <!-- Dietary -->
                <div class="w-100" >
                    <mat-form-field class="w-100">
                        <mat-label for="DietaryNeed" aria-labelledby="DietaryNeed">
                            {{ 'copy.label.FEATURES.DIETARY.label' | copy | async }}</mat-label>
                        <mat-select name="DietaryNeed_{{guest.$mtcUUID}}" [(ngModel)]="guest.DietaryNeed">
                            <mat-option *ngFor="let obj of mealList" [value]="obj.code">{{ obj.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <div class="d-flex justify-content-start">
                        <mat-form-field class="w-100">
                            <mat-label for="DietaryConsiderations" aria-labelledby="DietaryConsiderations">
                                {{ 'copy.label.FEATURES.DIETARY.otherlabel' | copy | async }}</mat-label>
                              <textarea matInput class="w-100" name="DietaryConsiderations_{{guest.$mtcUUID}}" id="DietaryConsiderations_{{guest.$mtcUUID}}"
                                aria-label="DietaryConsiderations" [(ngModel)]="guest.DietaryConsiderations"
                                maxlength="225"  [required]="(guest.DietaryNeed === 'OTHER' || guest.DietaryNeed === 'FOODALLE')" pattern="^[\w\s!@\-\_:',.\/?]*" #DietaryCons="ngModel"></textarea>
                              <mat-error><app-validation [object]="DietaryCons" isRequired=true pattern="alphanum"></app-validation></mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Personal -->
                <div class="w-100">
                    {{ 'copy.label.FEATURES.PERSONAL.heading' | copy | async }}
                    <div *ngFor="let considerations of personalConsiderationsList">
                        <div class="agreeCheckbox my-2">
                            <div class="checkboxFlexContainer">
                                <mat-checkbox name="considerations_{{ considerations.name }}_{{guest.$mtcUUID}}"
                                [ngModel]="includes(guest.DisabilityType,considerations.code)"
                                (change)="considerationUpdate(considerations,guest)">
                                </mat-checkbox>
                                <label>{{ considerations.name }}</label>
                            </div>
                        </div>
                        <!-- {{personalConsiderations.invalid}} -->
                    </div>
                </div>
                <div>
                    <div class="d-flex justify-content-start">
                        <mat-form-field class="w-100">
                            <mat-label for="PersonalConsiderations" aria-labelledby="PersonalConsiderations">
                                {{ 'copy.label.FEATURES.PERSONAL.otherlabel' | copy | async }}</mat-label>
                              <textarea matInput class="w-100" name="PersonalConsiderations_{{guest.$mtcUUID}}" id="PersonalConsiderations_{{guest.$mtcUUID}}"
                                aria-label="PersonalConsiderations" [(ngModel)]="guest.PersonalConsiderations"
                                maxlength="225"  [required]="includes(guest.DisabilityType, 'OTHER')" pattern="^[\w\s!@\-\_:',.\/?]*" #PersonalConsiderations="ngModel"></textarea>
                              <mat-error><app-validation [object]="PersonalConsiderations" isRequired=true pattern="alphanum"></app-validation></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>

            <div *ngIf="isAddGuestEnabled() && !isRegComplete" class="mt-2">
                <button type="button" (click)="addGuest({})"
                    class="btn btn-success customSuccess">{{'copy.label.GUEST.GUESTFORM.add'|copy|async}}
                </button>
            </div>

        </mat-accordion>

        <div class="button_group d-flex">
            <button id="back-button" type="button" class="back btn btn-outline-primary" (click)="back()"
                *ngIf="!isSubmitting">
                {{ 'copy.label.GLOBAL.goback' | copy | async }}
            </button>
            <button id="forward-button" type="submit" class="inputSubmit btn btn-primary" *ngIf="!isSubmitting">
                {{ 'copy.label.GLOBAL.continue' | copy | async }}
            </button>
            <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
                <i class="fas fa-spinner fa-spin"></i>
            </button>
        </div>
    </form>
</div>
