import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { promise } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class AirportService {

    constructor(private environmentService: EnvironmentService, private Constants: ConstantsService, private http: HttpClient) { }

    /**
           *@function AirportService.getAirportLocations
           *@description Gets list containing airports based on data entered by the user via the [AirportResource.java](../apidocs/com/mtc/regsite/reference/AirportResource.html)
           *@returns list
           */
    getAirportLocations(input) {
        let url = this.environmentService.getEnvironmentUrl("service/airports/" + input);
        const promise = new Promise((resolve, reject) => {
            this.http.get(url).toPromise().then((response: any) => {

                var list = response.data;
                if (input) {
                    var crscode = input.toUpperCase();
                    var airport = _.find(list, { "crscode": crscode });
                    if (airport) {
                        list = _.filter(list, (airport) => {
                            return airport.crscode !== crscode;
                        });
                        list.unshift(airport);
                    }
                }
                return list;
                resolve(list);
            });
        });
        return promise;
    }
}
