<!-- Yes to Badge -->
<div *ngIf="showbadge">
    <div class="badge">
    <div>
        {{attendee.PreferredFirstName|| attendee.FirstName}} {{attendee.PreferredLastName|| attendee.LastName}}
    </div>
    <div>{{attendee.BusinessName}}</div>
    <div>{{attendee.JobTitle}}</div>
    <div *ngIf="attendee.Pronouns">{{attendee.Pronouns}}</div>
</div>
</div>
<!-- No to Badge -->
<div *ngIf="!showbadge">
    <div class="form-summary" *ngIf="attendee.PreferredFirstName !== undefined || attendee.FirstName !== undefined">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.NAME.preferredfirst' | copy | async }}</label>
        <span class="form-summary-item col-md-6 col-xs-12">{{attendee.PreferredFirstName|| attendee.FirstName}}</span>
    </div>
    <div class="form-summary" *ngIf="attendee.PreferredLastName !== undefined || attendee.LastName !== undefined">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.NAME.preferredlast' | copy | async }}</label>
        <span class="form-summary-item col-md-6 col-xs-12">{{attendee.PreferredLastName|| attendee.LastName}}</span>
    </div>
    <div class="form-summary" *ngIf="attendee.ProfessionalLetters !== undefined">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.NAME.proletterslabel' | copy | async }}</label>
        <span class="form-summary-item col-md-6 col-xs-12">{{attendee.ProfessionalLetters}}</span>
    </div>
    <!-- <div class="form-summary" *ngIf="attendee.BusinessName !== undefined">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.COMPANY.label' | copy | async }}</label>
        <span class="form-summary-item col-md-6 col-xs-12">{{attendee.BusinessName}}</span>
    </div> -->
    <div class="form-summary" *ngIf="attendee.JobTitle !== undefined">
        <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.JOBTITLE.label' | copy | async }}</label>
        <span class="form-summary-item col-md-6 col-xs-12">{{attendee.JobTitle}}</span>
    </div>
    <div class="form-summary" *ngIf="attendee.Pronouns !== undefined">
      <label class="col-md-6 col-xs-12">{{ 'copy.label.FEATURES.NAME.pronouns' | copy | async }}</label>
      <span class="form-summary-item col-md-6 col-xs-12">{{attendee.Pronouns}}</span>
  </div>
</div>
