import { Component, ViewChild } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { RegistrationService } from '../../services/registration.service';
import { VariablesService } from '../../services/variables.service';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  templateUrl: './demographics.component.html'
})
export class DemographicsComponent {
  @ViewChild('demographicsForm') form;

  isSubmitting: boolean;
  attendee: any;
  shirtSize;
  shirtSizeValue;

  firstTime;
  firstTimeValue;

  whyAttend;
  whyAttendValue;
  bestFeatureChecked;

  emailFormArray: Array<any> = [];
  otherChecked: boolean;

  bestFeatureValue;
  bestFeatureFreeForm;
  bestFeatureFreeFormValue;
  bestFeatures;
  bestFeaturesSelections;
  isFormCompleted=false;
  shirtSizeList = [
    { display: "S-Men's", value: 'Men S' },
    { display: "M-Men's", value: 'Men M' },
    { display: "L-Men's", value: 'Men L' },
    { display: "XL-Men's", value: 'Men XL' },
    { display: "S-Women's", value: 'Women S' },
    { display: "M-Women's", value: 'Women M' },
    { display: "L-Women's", value: 'Women L' },
    { display: "XL-Women's", value: 'Women XL' }
  ];

  // bestFeatures = [
  //   { display: 'Reusable directives', value: 'Reusable directives' },
  //   { display: 'Built in responsive design', value: 'Built in responsive design' },
  //   { display: 'Easy theming', value: 'Easy theming' },
  //   { display: 'Other', value: 'Other' }
  // ];

  multiSelectValues = ['val1', 'val2', 'val3'];

  BEST_FEATURES_KEYS = {
    ANGULAR: 'angular',
    BOOTSTRAP: 'bootstrap',
    BOOTSWATCH: 'bootswatch',
    OTHER: 'other',
  };

  BEST_FEATURES_VALUES = [
    { display: 'Reusable directives', value: this.BEST_FEATURES_KEYS.ANGULAR },
    {
      display: 'Built in responsive design',
      value: this.BEST_FEATURES_KEYS.BOOTSTRAP,
    },
    { display: 'Easy theming', value: this.BEST_FEATURES_KEYS.BOOTSWATCH },
    { display: 'Other', value: this.BEST_FEATURES_KEYS.OTHER },
  ];

  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private variablesService: VariablesService
  ) {
    this.attendee = this.registrationService.getAttendee({});

    // this.shirtSize = registrationService.getVariable(
    //   this.attendee,
    //   variablesService.VARIABLE.SHIRT_SIZE.ID,
    //   {}
    // );
    // this.firstTime = registrationService.getVariable(
    //   this.attendee,
    //   variablesService.VARIABLE.FIRST_TIME.ID,
    //   {}
    // );
    // this.whyAttend = registrationService.getVariable(
    //   this.attendee,
    //   variablesService.VARIABLE.WHY_ATTENDING.ID,
    //   {}
    // );


    // this.bestFeatureFreeForm = registrationService.getVariable(this.attendee, variablesService.VARIABLE.BEST_FEATURES_OTHER.ID, {});

    // this.bestFeatures = this.registrationService.getVariable(
    //   this.attendee,
    //   this.variablesService.VARIABLE.BEST_FEATURES.ID,
    //   {}
    // );

    // bestFeatures.Value is a string ...
   //this.bestFeaturesSelections = variablesService.toCheckBoxModel(this.bestFeatures.Value, this.BEST_FEATURES_KEYS);
    //this.onChangeBestFeature();
  }

  /**
   * @function onChangeBestFeature
   * @description On change of best feature selection if it is other shows textarea.
   */
  onChangeBestFeature() {
    this.bestFeatures.Value = this.variablesService.toDelimitedKeysString(this.bestFeaturesSelections);
    if(this.bestFeaturesSelections.other){
      this.otherChecked = true;
    }else{
      this.otherChecked = false;
    }
  }


  /**
   * @function back
   * @description Uses [navigation.service.back()] to go back to previous page.
   */
  back() {
    this.navigationService.back();
  }

  /**
   * @function forward
   * @description Checks if form is valid then uses [navigation.service.forward()] to go forward to next page.
   */
  forward(form) {
    this.isFormCompleted=true;
    if (form.valid) {
    this.isSubmitting = true;
    this.navigationService.forward();
    }
  }
}
