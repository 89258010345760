import { Injectable } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Router } from '@angular/router';
import { RegistrationService } from './registration.service';
import * as _ from 'lodash';
import { PersistenceService } from './persistence.service';
import { EnrollmentService } from './enrollment.service';
import { ConstantsService } from '../services/constants.service';
import { ConfigurationService } from './configuration.service';
import { LineitemService } from './lineitem.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  FORWARD = 1;
  BACKWARD = -1;
  guests: any;
  programInfo;
  attendee: any;
  nextPage: any;
  skipFunction: any;


  submitting = false;
  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private persistenceService: PersistenceService,
    private enrollmentService: EnrollmentService,
    private configurationService: ConfigurationService,
    private constants: ConstantsService,
    private lineItemService: LineitemService,
    private auth: AuthenticationService
  ) {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
    });
  }


  getNavigationList() {

    const pageList = [];
    this.guests = this.registrationService.getGuests({});
    this.attendee = this.registrationService.getAttendee({});

    const promise = new Promise((resolve, reject) => {
      this.configurationService.fetchConfig(true).then((data: any) => {
        this.programInfo = data;

        pageList.push(['/login', '/load', '/oauth', '/onsite']);
        // pageList.push(['/profile']);
        // pageList.push(['/pretrip']);
        // if (this.programInfo.collectInvitation) {
        //   pageList.push(['/invitation']);
        // }
        // if (this.programInfo.collectWave) {
        //   pageList.push(['/selection']);
        // }
        // if (this.programInfo.showTestPage) {
        //   pageList.push(['/testpage']);
        // }
        // if (this.programInfo.showGeneralPage) {
        //   pageList.push(['/general']);
        // }

        if (this.programInfo.collectAttendee) {
          pageList.push(['/attendee']);
        }
        // if (this.programInfo.collectGuest) {
        //   pageList.push(['/guest']);
        // }
        // if (this.programInfo.collectDemographics) {
        //   pageList.push(['/demographics information']);
        // }
        // if (this.programInfo.collectHotel) {
        //   if (this.programInfo.hotelpackage) {
        //     pageList.push(['/hotelpackage']);
        //   } else if (this.programInfo.hotelBooking) {
        //     pageList.push(['/hotelbooking']);
        //   } else {
        //     pageList.push(['/hotel']);
        //   }
        // }
        // if (this.programInfo.collectTravel) {
        //   pageList.push(['/travel']);
        //   if (this.programInfo.collectGuestTravel) {
        //     if (this.guests.length > 0)
        //       pageList.push(['/guest-travel']);
        //   }
        // }
        // if (this.programInfo.collectActivity) {
        // pageList.push(['/activity']);
        // }
        // if (this.programInfo.collectPayment) {
          pageList.push(['/payment']);
        // }
        pageList.push(['/summary']);
        // pageList.push(['/acknowledgement']);
        resolve(pageList);
      });
    });
    return promise;

  }

  getNextPage(direction, pageList, currentpage) {
    let index = 0;

    for (index; index < pageList.length; index++) {
      let found = false;

      let tokens = currentpage.split('/');
      currentpage = "/" + decodeURI(tokens[1]);
      pageList[index].forEach((entry)=>{
        if (currentpage === entry) {
          found = true;
        }
      });
      if (found) {
        break;
      }
    }

    return pageList[index + direction][0];
  }



 skipFunctions(page) {
  const promise = new Promise((resolve, reject) => {
    console.log(page);
    if(page === "/guest-travel") {
        if(this.guests.length <= 0 || this.attendee.TravelType !== this.constants.TRAVEL_TYPES.FLY){
            resolve(true);
        }else{
          resolve(false);
        }
    }else if(page === "/payment"){
      this.lineItemService.getAllLineItems().subscribe(data=>{
        if(data.length===0){
          if(this.programInfo.hotelGuarantee && this.registrationService.getEnrollment({}).HotelNeeded == true){
            resolve(false);
          }else if(this.programInfo.chargeExtensions && this.registrationService.getEnrollment({}).HotelNeeded == true && this.registrationService.getEnrollment({}).$mtcExtension){
            resolve(false);
          }else{
            resolve(true);
          }
        }else{
          resolve(false);
        }
      });
    }else if(page === "/invitation"){
      this.auth.getProfile().subscribe((profile:any)=>{
        resolve(profile.status !== "INVITE");
      });

    }else{
      resolve(false);
    }
  });
  return promise;

  }

  advancePage(direction, nextpage) {
    const pageList = this.getNavigationList().then(pageList=>{
      const currentpage = this.router.url;
      if(!nextpage){
        nextpage = this.getNextPage(direction, pageList, currentpage);
      }
      this.skipFunction = this.skipFunctions(nextpage).then((skip)=>{
        if(skip){
          this.advancePage(direction, this.getNextPage(direction, pageList, nextpage));
        }else{
          this.persistenceService.store();
          this.router.navigate([nextpage]);
          this.submitting = false;
        }
      });
    });
  }



  forward() {
    if (!this.submitting) {
      this.submitting = true;

      if (this.registrationService.getEnrollment({}).$mtcRegistrationComplete) {
        this.forwardrvs()
      } else if (this.registrationService.getEnrollment({}).$mtcHasVisitedSummary) {
        this.advancePage(this.FORWARD, false);
      } else {
        this.advancePage(this.FORWARD, false);
      }
    }
  }

  forwardrvs() {
      this.submitting = true;
      if (this.router.url === '/login' || this.router.url.indexOf('/load')>-1  || this.router.url === '/oauth' || this.router.url === '/onsite') {
        this.submitting = false;
        this.router.navigate(['/summary']);
      } else {
        console.log("calling updateEnrollment")
        this.enrollmentService.updateEnrollment(this.registrationService.getEnrollment({})).then(() => {
          this.registrationService.getEnrollment({}).$mtcUpdateSuccess = true;
          this.submitting = false;
          this.router.navigate(['/summary']);
        });
      }
  }

  back() {
    if (!this.submitting) {
      this.submitting = true;
      if (this.registrationService.getEnrollment({}).$mtcRegistrationComplete || this.registrationService.getEnrollment({}).$mtcHasVisitedSummary ) {
        this.persistenceService.retrieve('/summary');
        this.router.navigate(['/summary']);
        this.submitting = false;
      } else {
        this.advancePage(this.BACKWARD, false);
      }
    }
  }


}
