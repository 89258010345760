<div id="login" class="page container">
  <form name="loginForm" novalidate (ngSubmit)="forward()" autocomplete="off" [ngClass]="{'form-complete': isFormCompleted}">
    <h1>{{"copy.label.LOGIN.daypassheader"|copy|async}}</h1>
    <!-- <mtc-alert></mtc-alert> -->
    <div *ngIf="errorMsg" type="danger">
      <strong>
        <span class="fas fa-stack">
          <i class="fas fa-exclamation fa-stack-1x"></i>
          <i class="far fa-circle fa-stack-2x"></i>
        </span>
        {{errorMsg}}
      </strong>
    </div>
    <div class="content col-md-12">
      <div class="row">
        <div class="d-flex loginFlexContainer justify-content-between">
          <!-- <div [innerHTML]='"copy.html.logintext"|copy|async' class="login-info"></div> -->
          <div [innerHTML]='"copy.html.daypasslogintext"|copy|async' class="login-info"></div>

          <div class="login-forms">

            <div class="card" *ngIf="!onsite">
              <div class="card-header">
                <div class="card-title">{{"copy.label.LOGIN.newlogin.header"|copy|async}}</div>
              </div>
              <div class="card-body">
                <div class="card-title">{{"copy.label.LOGIN.newlogin.continuenote"|copy|async}}</div>

                <!-- <mat-form-field class="w-100">
                  <mat-label for="login-new-input" [ngClass]="{'active' : loginID}">{{"copy.label.LOGIN.newlogin.label"|copy|async}}</mat-label>
                  <input matInput [required]="!confirmationNo"  type="text" name="login-new-input" id="login-new-input" aria-label="login-new-input" [(ngModel)]="loginID" alphanum />
                </mat-form-field>
                <span class="help-block"><a tabindex="0" (click)="openModal('LoginRegHelp', 'Registration Help', 'Close')" (keydown.enter)="openModal('LoginRegHelp', 'Registration Help', 'Close')">{{"copy.label.LOGIN.newlogin.help"|copy|async}}</a></span>
                <mat-form-field class="w-100" ng-if="loginCtrl.programInfo.regCode">
                  <mat-label [ngClass]="{'active' : regcode}" for="regcode">{{"copy.label.LOGIN.regcode.label"|copy|async}}</mat-label>
                  <input matInput  type="text" name="regcode" id="regcode" aria-label="Registration/Promo code" [(ngModel)]="regcode" alphanum />
                </mat-form-field> -->
                <div class="button_group d-flex">
                  <!-- <button aria-label="Decline" class="back btn btn-outline-primary" type="button" (click)="declineButtonClick( )" *ngIf="!isSubmitting">Decline</button> -->
                  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </button>
                  <button *ngIf="!isSubmitting" name="new-login-button" type="submit" aria-label="Submit" class="forward btn btn-primary">{{'copy.label.LOGIN.newlogin.button'|copy|async}}</button>

                </div>
              </div>
            </div>            
            <!-- <app-otp *ngIf="('OTP'|copy|async) ==='true'"></app-otp>
            <div class="card" *ngIf="('OTP'|copy|async) ==='false'">
              <div class="card-header">
                <div class="card-title">{{"copy.label.LOGIN.oldlogin.daypassheader"|copy|async}}</div>
              </div>
              <div class="card-body">
                <div class="card-title">{{"copy.label.LOGIN.oldlogin.focusnote"|copy|async}}</div>

                <mat-form-field class="w-100">
                  <mat-label [ngClass]="{'active' : confirmationNo}" class="control-label">
                    {{"copy.label.LOGIN.oldlogin.label"|copy|async}}
                  </mat-label>
                  <a class="helpiconInTextbox" tabindex="0" matTooltip="{{'copy.label.LOGIN.oldlogin.returnLoginHelp'|copy|async}}">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </a>
                  <input matInput [required]="!loginID"  type="text" name="login-old-conf" [(ngModel)]="confirmationNo" alphanum />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label [ngClass]="{'active' : emailAddress}" class="control-label">

                    {{"copy.label.LOGIN.oldlogin.email"|copy|async}}
                  </mat-label>
                  <a class="helpiconInTextbox" tabindex="0" matTooltip="{{'copy.label.LOGIN.oldlogin.emailHelp'|copy|async}}">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </a>
                  <input matInput [required]="!loginID"  type="text" name="login-old-email" [(ngModel)]="emailAddress" alphanum />
                </mat-form-field>
                <span class="help-block">
                  <a tabindex="0" (click)="openForgotConf()" (keydown.enter)="openForgotConf()">{{"copy.label.LOGIN.oldlogin.forgot"|copy|async}}</a>
                </span>
                <div class="agreeCheckbox">
                  <div class="checkboxFlexContainer">
                    <mat-checkbox aria-labelledby="rememberme-label" name="rememberme" [(ngModel)]="rememberme" #RememberMe="ngModel"></mat-checkbox>
                    <label id="rememberme-label">{{"copy.label.SUMMARY.rememberme"|copy|async}}
                    <a tabindex="0" matTooltip="{{'copy.label.SUMMARY.remembermequestion'|copy|async}}">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </a></label>
                  </div>
                </div>
                <div class="button_group d-flex">
                  <button type="submit" name="old-login-button" class="forward btn btn-primary" *ngIf="!isSubmitting"> {{'copy.label.LOGIN.oldlogin.button'|copy|async}} </button>
                  <button class="inputSubmit btn btn-primary ml-auto" *ngIf="isSubmitting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
