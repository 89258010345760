import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { CopyService } from '../../services/copy.service';
import { RegistrationService } from '../../services/registration.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import * as _moment from 'moment';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConstantsService } from '../../services/constants.service';
import { ListManagerService } from 'src/app/services/list-manager.service';
import { GuestService } from 'src/app/services/guest.service';
import { ModalService } from '../../services/modal.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { VariablesService } from 'src/app/services/variables.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-guest-travel',
  templateUrl: './guest-travel.component.html',
  styleUrls: ['./guest-travel.component.scss'],
})
export class GuestTravelComponent implements OnInit {
  isSubmitting;
  isFormCompleted = false;
  travelTypes = [];
  programInfo: any;
  attendee: any;
  enrollment: any;
  guests: any;
  passport: any;
  ownAir: any;
  isRegComplete: any;
  countryList: any;
  isError: any;
  prefixList: any;
  suffixList: any;
  genderRadios: any;
  eventDates: any;

  //For flight preference
  travelTimes: any;
  timesRadios: any;
  groupArrivalDate: any;
  groupDepartureDate: any;
  calendarready: any;
  options: any;
  airlineList: any;
  flightPrefRadios;
  participant;
  locations;
  startDateOutbound;
  endDateOutbound;
  startDateReturn;
  endDateReturn;
  search;
  startDateOutboundYear: number;
  startDateOutboundMonth: number;
  startDateOutboundDay: number;
  startDate;
  startDateRet;
  endDate;
  endDateRet;
  airportLocations2;
  minChildDate;
  @ViewChildren(MatExpansionPanel) panels: QueryList<ElementRef>;

  constructor(
    private navigationService: NavigationService,
    private copyService: CopyService,
    private registrationService: RegistrationService,
    public Constants: ConstantsService,
    private configurationService: ConfigurationService,
    private listService: ListManagerService,
    private guestService: GuestService,
    public modalService: ModalService,
    private matDialog: MatDialog,
    private dateAdapter: NgbDateAdapter<string>,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private variables: VariablesService
  ) {
    this.attendee = this.registrationService.getAttendee({});
    this.enrollment = this.registrationService.getEnrollment({});
    this.guests = this.registrationService.getGuests({});
    this.guestService.getGuestTypesList().then((gTypes: any) => {
      console.log(gTypes);

    });

    this.guestService.getChildMinDate().then(date=>{
      this.minChildDate = date;
    });
    this.guests.forEach( (guest) =>{
      guest.$mtcOutbound = registrationService.getPAXAirPreference(
        guest,
        Constants.AIRPREF_DIRECTIONS.OUTBOUND,
        {}
      );
        if(guest.$mtcOutbound.DepartureDateTime){
          guest.$mtcOutboundDateTime = this.convertToDatePicker(
            guest.$mtcOutbound.DepartureDateTime
          );
        }else{
          guest.$mtcOutboundDateTime = this.convertToDatePicker(
            this.attendee.$mtcOutbound.DepartureDateTime
          );
        }

      guest.$mtcReturn = registrationService.getPAXAirPreference(
        guest,
        Constants.AIRPREF_DIRECTIONS.RETURN,
        {}
      );

      if(guest.$mtcReturn.DepartureDateTime){
        guest.$mtcReturnDateTime = this.convertToDatePicker(
          guest.$mtcReturn.DepartureDateTime
        );
      }else{
        guest.$mtcReturnDateTime = this.convertToDatePicker(
          this.attendee.$mtcReturn.DepartureDateTime
        );
      }

      guest.$mtcReturnDateTime = this.convertToDatePicker(
        this.attendee.$mtcReturn.DepartureDateTime
      );
        if(!guest.FrequentFlyer){
          guest.FrequentFlyer = [];
        }

      let otherNumber = this.registrationService.getVariable(guest,variables.VARIABLE.OTHER_NUMBER.ID,{});
      if(otherNumber.Value==="Yes"){
        guest.$mtcOtherNumberBox = true;
      }
    });

    this.listService.getCountryList().then((data) => {
      this.countryList = data;
    });

    this.listService.getCommonPrefixList().then((list) => {
      this.prefixList = list;
    });

    this.listService.getSuffixList().then((list) => {
      this.suffixList = list;
    });

    this.listService.getFrequentFlyerList().then((data) => {
      this.airlineList = data;
    });

    this.listService.getTravelTimeList().then((data) => {
      this.travelTimes = data;
    });


  }

  otherNumChange(guest){

    if(guest.$mtcOtherNumberBox){
      this.registrationService.getVariable(guest,this.variables.VARIABLE.OTHER_NUMBER.ID,{}).Value = "Yes";
    }else{
      this.registrationService.getVariable(guest,this.variables.VARIABLE.OTHER_NUMBER.ID,{}).Value = "";
    }
  }


  isChild(pax){
    return this.guestService.isChild(pax);
  }
  convertToDatePicker(datestring) {
    let dateobj = moment.utc(datestring, 'YYYY-MM-DD HH:mm:ss+Z').toDate();
    return {
      year: dateobj.getFullYear(),
      month: dateobj.getMonth() + 1,
      day: dateobj.getDate(),
    };

    // return this.ngbDateParserFormatter.parse(datestring);
  }

  ngOnInit(): void {
    this.calendarready = false;
    this.options = {
      showWeeks: false,
      startingDay: '0',
    };
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;

      this.startDateOutbound = moment
        .utc(this.programInfo.airOutboundDateBegin, 'YYYY-MM-DD HH:mm:ss+Z')
        .toDate();
      this.startDate = {
        year: this.startDateOutbound.getFullYear(),
        month: this.startDateOutbound.getMonth() + 1,
        day: this.startDateOutbound.getDate(),
      };
      this.endDateOutbound = moment
        .utc(this.programInfo.airOutboundDateEnd, 'YYYY-MM-DD HH:mm:ss+Z')
        .toDate();
      this.endDate = {
        year: this.endDateOutbound.getFullYear(),
        month: this.endDateOutbound.getMonth() + 1,
        day: this.endDateOutbound.getDate(),
      };

      this.startDateReturn = moment
        .utc(this.getMinimumDepartureDate(), 'YYYY-MM-DD HH:mm:ss+Z')
        .toDate();
      this.startDateRet = {
        year: this.startDateReturn.getFullYear(),
        month: this.startDateReturn.getMonth() + 1,
        day: this.startDateReturn.getDate(),
      };
      this.endDateReturn = moment
        .utc(this.programInfo.airReturnDateEnd, 'YYYY-MM-DD HH:mm:ss+Z')
        .toDate();

      this.endDateRet = {
        year: this.endDateReturn.getFullYear(),
        month: this.endDateReturn.getMonth() + 1,
        day: this.endDateReturn.getDate(),
      };

      this.flightPrefRadios = [
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.SEATPREF.window'
          ),
          value: this.Constants.REQUESTED_SEAT_TYPES.WINDOW,
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.SEATPREF.aisle'
          ),
          value: this.Constants.REQUESTED_SEAT_TYPES.AISLE,
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.SEATPREF.nopref'
          ),
          value: this.Constants.REQUESTED_SEAT_TYPES.NOPREF,
        },
      ];

      this.genderRadios = [
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.GENDER.male'
          ),
          value: 'MALE',
        },
        {
          display: this.copyService.getCopyByKey(
            'copy.label.FEATURES.GENDER.female'
          ),
          value: 'FEMALE',
        },
      ];

      // For flight preference
      this.groupArrivalDate = moment
        .utc(this.programInfo.arrivalDate, 'YYYY-MM-DD HH:mm:ss+Z')
        .toDate();
      this.groupDepartureDate = moment
        .utc(this.programInfo.departureDate, 'YYYY-MM-DD HH:mm:ss+Z')
        .toDate();
      this.calendarready = true;

      this.guests.forEach(guest => {
        if(!guest.$mtcReturn.DepartureCityLocation){
          if(this.programInfo.programDepartureCityAirport){
            guest.$mtcReturn.DepartureCityLocation = this.programInfo.programDepartureCityAirport;
          }
          if(this.programInfo.programDepartureCityAirportCode){
            guest.$mtcReturn.DepartureAirportCode = this.programInfo.programDepartureCityAirportCode;
          }
        }
      });
    });

    this.populateGender();
    this.setTravelTypes();
  }

  setTravelTypes() {
    this.travelTypes = [];
    this.guests.forEach(guest => {
      let options = [
        {
          display:
            'Yes, I\'d like TravelHQ Conference Travel Desk to make ' +
            this.getDisplayName(guest) +
            '\'s air arrangements',
          value: this.Constants.TRAVEL_TYPES.FLY,
        },
        {
          display:  'No, I will make ' + this.getDisplayName(guest) + '\'s air arrangements',
          value: this.Constants.TRAVEL_TYPES.OWN_AIR,
        },
      ];
      this.travelTypes.push(options);
    });
  }

  /**
   * @function back
   * @description Uses [navigation.service.back()] to go back to previous page.
   */
  back() {
    this.cleanAirTravelInfo();
    this.navigationService.back();
  }

  /**
   * @function forward
   * @description Checks if form is valid then uses [NavigationService.forward()](./app.NavigationService.html) to go forward to next page.
   */
  forward(form) {
    this.isFormCompleted = true;
    if (form.valid) {
      this.isSubmitting = true;
      this.updateModel();
      this.sameItinCleanup();
      this.cleanAirTravelInfo();

      //console.log(this.guests)
      this.navigationService.forward();
    }
  }

  sameItinCleanup(){
    this.guests.forEach(guest => {
      if(guest.$mtcSameItinerary){
        guest.PAXAirPreference = _.cloneDeep(this.attendee.PAXAirPreference);
        guest.TravelPlan = this.attendee.TravelPlan;
      }
    });
  }

  convertToRealDate(d) {
    return this.ngbDateParserFormatter.format(d) + 'T12:00:00-0500';;
  }

  updateModel() {
    this.guests.forEach((guest) => {
      if(guest.$mtcOutboundDateTime){
        guest.$mtcOutbound.DepartureDateTime = this.convertToRealDate(
          guest.$mtcOutboundDateTime
        );
      }
      if(guest.$mtcReturnDateTime){
        guest.$mtcReturn.DepartureDateTime = this.convertToRealDate(
          guest.$mtcReturnDateTime
        );
      }

      let departdate = moment.utc(guest.$mtcOutbound.DepartureDateTime, "YYYY-MM-DD HH:mm:ss+Z").toDate();
      let retdate = moment.utc(guest.$mtcReturn.DepartureDateTime, "YYYY-MM-DD HH:mm:ss+Z").toDate();

      if(guest.$mtcOutboundDateTime && departdate < this.groupArrivalDate){
        guest.TravelPlan = 'EXT';
      }else if(guest.$mtcReturnDateTime && this.groupDepartureDate < retdate){
        guest.TravelPlan = 'EXT';
      }else{
        guest.TravelPlan = 'PGMDATE';
      }
      console.log(guest);
    });
  }

  format(date) {
    return this.convertToRealDate(date);
  }

  /**
   * @function TravelController.getMinimumDepartureDate
   * @returns {Window.programInfo.airReturnDateBegin|this.programInfo.airReturnDateBegin|Window.attendee.$mtcOutbound.DepartureDateTime|this.attendee.$mtcOutbound.DepartureDateTime}
   */
  getMinimumDepartureDate() {
    if (this.programInfo && this.programInfo.airReturnDateBegin) {
      // if (
      //   this.attendee.$mtcOutbound.DepartureDateTime >
      //   this.programInfo.airReturnDateBegin
      // ) {
      //   return this.attendee.$mtcOutbound.DepartureDateTime;
      // } else {
        return this.programInfo.airReturnDateBegin;
      // }
    } else {
      return undefined;
    }
  }

  /**
   * @function TravelController.populateGender
   */
  populateGender() {
    if (this.attendee.Prefix && !this.attendee.Gender) {
      if (this.attendee.Prefix === 'MR' || this.attendee.Prefix === 'MSTR') {
        this.attendee.Gender = 'MALE';
      } else if (
        this.attendee.Prefix === 'MRS' ||
        this.attendee.Prefix === 'MS' ||
        this.attendee.Prefix === 'MISS'
      ) {
        this.attendee.Gender = 'FEMALE';
      } else {
        this.attendee.Gender = undefined;
      }
    }
  }

  /**
   * @function FrequentFlyerDirective.addFrequentFlyer
   * @description Adds frequent flyer information.
   */
  addFrequentFlyer(guest) {
    guest.FrequentFlyer.push({});
  }

  /**
   * @function FrequentFlyerDirective.removeFrequentFlyer
   * @description Removes frequent flyer information.
   */
  removeFrequentFlyer(guest,flyer) {
    _.remove(guest.FrequentFlyer, flyer);
  }

  /**
   * @function cleanAirTravelInfo
   */
  cleanAirTravelInfo() {
    this.guests.forEach(guest => {
      if(guest.TravelType &&
        guest.TravelType !== this.Constants.TRAVEL_TYPES.FLY){
          guest.MiddleName = undefined;
          guest.Gender = undefined;
          if (!this.guestService.isChild(guest)) {
            guest.BirthDate = undefined;
          }
          guest.RedressNumber = undefined;
          guest.KnownTravelerNumber = undefined;
          guest.InterRowPosition = undefined;
          guest.CitizenshipCountryISOCode = undefined;
          guest.FrequentFlyer = [];
          guest.PAXAirPreference = [];
          guest.$mtcSameItinerary = undefined;
          guest.$mtcOutbound = {};
          guest.$mtcReturn = {};
        }
    });
  }

  getDisplayName(guest) {
    if (guest) {
      if (guest.$mtcDeleting) {
        return 'Undo ';
      }
      return guest.PreferredFirstName || guest.FirstName || 'Guest';
    } else {
      return false;
    }
  }

  openModal(copy, title, actionButtonText) {
    this.modalService.openModal(copy, title, actionButtonText);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component-terms';
  }

  filterAirlines(airlinelist, code, guest) {
    let selected = [];
    if(guest.FrequentFlyer) {
      guest.FrequentFlyer.forEach(flier => {
        if(flier.AirlineCode && flier.AirlineCode !== code) {
          selected.push(flier.AirlineCode);
        }
      });
    }
    const filteredlist = _.filter(airlinelist, o => {
      if(selected.indexOf(o.code) >= 0) {
        return false;
      } else {
        return true;
      }
    });
    return filteredlist;
  }
}
