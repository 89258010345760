import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  constructor() {}

  test = 'test';

  VARIABLE = {
    varMasterRepID: {ID: 1}, //used to be varAdvisorID
    varBenefitLevel: {ID: 9},
    varLoginID: {ID: 10},
    varLOB: {ID: 11},
    varRegFeesType: {ID: 12},
    varBenefitRoomNights: {ID: 13},
    varPrimaryCompAir: {ID: 14},
    varAdvisorAffiliatio: {ID: 15}, //used to be varStaffCompRegFee
    varEnterpriseAllianc: {ID: 16}, //used to be varStaffCompAir
    varTrack: {ID: 17},
    SMSproviderother: {ID: 18},
    //varDoNotContact: {ID: 18},
    varpromotionalemail: {ID: 19},
    varDepartment: {ID: 21, displayOnSummary: true, summaryDisplayLabel: "varDepartment", summarySection: "attendee", convert: 'text'},
    varCECreditAdvisor: {ID: 22},
    varProfDesignations: {ID: 23},
    varVeteran: {ID: 24},
    varLPLAStockholder: {ID: 25},
    varContribute: {ID: 26},
    varContribution: {ID: 27, displayOnSummary: true, summaryDisplayLabel: "varContribution", summarySection: "attendee", convert: 'text'},
    varInitials: {ID: 28},
    varCompStaffBenefits: {ID: 29},
    varSMSConsent: {ID: 30, displayOnSummary: true, summaryDisplayLabel: "varSMSConsent", summarySection: "attendee", convert: 'text'},
    varCellProvider: {ID: 31, displayOnSummary: true, summaryDisplayLabel: "varCellProvider", summarySection: "attendee", convert: 'text'},
    varContactSheet: {ID: 32, displayOnSummary: true, summaryDisplayLabel: "varContactSheet", summarySection: "attendee", convert: 'text'},
    varRole: {ID: 63, displayOnSummary: true, summaryDisplayLabel: "varRole", summarySection: "demographics", convert: 'text'},
    varFirstTime: {ID: 33, displayOnSummary: true, summaryDisplayLabel: "varFirstTime", summarySection: "demographics", convert: 'text'},
    varCommunityMeetup: {ID: 64, displayOnSummary: true, summaryDisplayLabel: "varCommunityMeetup", summarySection: "demographics", convert: 'text'},
    varNetworking: {ID: 65, displayOnSummary: true, summaryDisplayLabel: "varNetworking", summarySection: "demographics", convert: 'text'},
    varFirstTimeAmbassador: {ID: 34, displayOnSummary: true, summaryDisplayLabel: "varFirstTimeAmbassador", summarySection: "demographics", convert: 'text'},
    varSponsorPreConference: {ID: 35, displayOnSummary: true, summaryDisplayLabel: "varSponsorPreConference", summarySection: "demographics", convert: 'text'},
    varSponsorDateAttend: {ID: 36, displayOnSummary: true, summaryDisplayLabel: "varSponsorDateAttend", summarySection: "demographics", convert: 'text'},
    varDesignatedSpeaker: {ID: 37, displayOnSummary: true, summaryDisplayLabel: "varDesignatedSpeaker", summarySection: "demographics", convert: 'text'},
    varPreConference: {ID: 38, displayOnSummary: true, summaryDisplayLabel: "varPreConference", summarySection: "demographics", convert: 'text'},
    varWelcomeReception: {ID: 39, displayOnSummary: true, summaryDisplayLabel: "varWelcomeReception", summarySection: "demographics", convert: 'text'},
    varFinalNightConcert: {ID: 40, displayOnSummary: true, summaryDisplayLabel: "varFinalNightConcert", summarySection: "demographics", convert: 'text'},
    varMorningWorkout: {ID: 41, displayOnSummary: true, summaryDisplayLabel: "varEngage", summarySection: "demographics", convert: 'text'},
    varEngage: {ID: 68, displayOnSummary: true, summaryDisplayLabel: "varEngage", summarySection: "demographics", convert: 'text'},
    varBreakfast: {ID: 42, displayOnSummary: true, summaryDisplayLabel: "varBreakfast", summarySection: "demographics", convert: 'text'},
    varGroupName: {ID: 43, displayOnSummary: true, summaryDisplayLabel: "varGroupName", summarySection: "demographics", convert: 'text'},
    varRoommateFN: {ID: 44, displayOnSummary: true, summaryDisplayLabel: "varRoommateFN", summarySection: "hotel", convert: 'text'},
    varRoommateLN: {ID: 45, displayOnSummary: true, summaryDisplayLabel: "varRoommateLN", summarySection: "hotel", convert: 'text'},
    varShareRoom: {ID: 46, displayOnSummary: true, summaryDisplayLabel: "varShareRoom", summarySection: "guest", convert: 'text'},
    varScooter: {ID: 47, displayOnSummary: true, summaryDisplayLabel: "varScooter", summarySection: "attendee", convert: 'text'},
    varScooterComments: {ID: 48, displayOnSummary: true, summaryDisplayLabel: "varScooterComments", summarySection: "attendee", convert: 'text'},
    varDatesAcknowledgement: {ID: 49},
    varCancelPolicy: {ID: 50},
    varTravelPolicy: {ID: 51},
    varCodeOfConduct: {ID: 52},
    varGuest: {ID: 53},
    varGuestCityState: {ID: 54},
    varIndvGDC: {ID: 55},
    varGrpGDC: {ID: 56},
    varIndvAUM: {ID: 57},
    varGrpAUM: {ID: 58},
    varBusConsult: {ID: 59},
    varOsjID: {ID: 60},
    varOsjMgrID: {ID: 61},
    varOsjMgrName: {ID: 62},
    varSponsorConsent: {ID: 66},
    varSponsorBoothName: {ID: 74},
    varCompStaffFirst: {ID: 75},
    varCompStaffLast: {ID: 76},
    varCompStaffRepID: {ID: 77},
    varPastEventsAttend: {ID: 78},
    varDonation: {ID: 250},
    varDonationAmount: {ID: 251},
    varDonationOther: {ID: 252},
    varSponsor: {ID: 253},
    varSponsorUse: {ID: 254},
    OTHER_NUMBER: { ID: 72 },
    varRelationManager: { ID: 73 },
    varHotelRewards: {ID: 79},
    varVeteranOther: {ID: 80},
    varSupplierRole: {ID: 81},
    varGroupBusMgmt: {ID: 82},
    varGroupBoard: {ID: 83},
    varGroupDiversity: {ID: 84},
    varGroupFaith: {ID: 85},
    varGroupLeadership: {ID: 86},
    varGroupPhil: {ID: 87},
    varGroupPolitical: {ID: 88},
    varGroupStudy: {ID: 89},
    varGroupNone: {ID: 90},
    varGroupOther: {ID: 91},
    varGroupOtherEntry: {ID: 92},
    varYesBusRelated: {ID: 93},
    varYesInterest: {ID: 94},
    varYesRegional: {ID: 95},
    varNoCommunities: {ID: 96},
    varStaffEmailFound: {ID: 97},
    varAffiliation: {ID: 98},
    varAffiliationOther: {ID: 99},
    varAssumRisk: {ID: 100},
    varPACAck: {ID: 101},
    varDPDateAttend: {ID: 103},

    varGuest1: {ID: 104},
    varGuest2: {ID: 105},
    varGuest3: {ID: 106},
    varGuest4: {ID: 107},
    varGuest5: {ID: 108},
    varGuest6: {ID: 109},
    varGuest7: {ID: 110},
    varGuest8: {ID: 111},
    varGuest9: {ID: 112},

    managementCommitteeHomeOffice: {ID: 113},
    varFirstresponder: {ID: 114},
    varInstitutionName: {ID: 115},
    varInstitutionID: {ID: 116},
    varFirstresponderOther: {ID: 117},


  };

  profdesigvalues = [
    "AAMS",
    "AIF",
    "AIFA",
    "CFA",
    "CFP",
    "CPA",
    "ChFC",
    "CKA",
    "CLU",
    "CIMA",
    "CRPC",
    "CFS",
    "JD",
    "MBA",
    "PPC",
    "Other",
    "None"
  ];

  committeeMemberList = [
    'Dan Arnold',
    'Matt Audette',
    'Althea Brown',
    'Sara Dadyar',
    'Matt Enyedi',
    'Greg Gates',
    'Aneri Jambusaria',
    'Rich Steinmeier'
  ];

  relationlist = [
    "Colleague",
    "Family Member",
    "Friend",
    "Partner",
    "Spouse",
    "Other"
  ];


  veteranlist = [
    "No",
    "Yes - Air Force",
    "Yes - Army",
    "Yes - Coast Guard",
    "Yes - Marine Corps",
    "Yes - Navy",
    "Yes - Other"
  ];

  firstresponderlist = [
    {display: 'No', value: 'No'},
    {display: 'Law Enforcement Officer', value: 'Law Enforcement Officer'},
    {display: 'Firefighter', value: 'Firefighter'},
    {display: 'Emergency Medical Technician (EMT)', value: 'Emergency Medical Technician EMT'},
    {display: 'Paramedic', value: 'Paramedic'},
    {display: 'Other', value: 'Other'}
  ];

  yesno = [
    "Yes",
    "No"
  ];

  providervalues = [
    "AT&T",
    "T-Mobile",
    "Verizon",
    "Other"
  ]

  donationvalues = [
    "$20",
    "$50",
    "$100",
    "Other"
  ];

  dayvalues = [
    "Sunday, August 11",
    "Monday, August 12",
    "Tuesday, August 13"
  ];
  timesattending = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10+"
  ];

  contributiondropdownlist = [
    "$250",
    "$500",
    "$1000",
    "$2500",
    "$5000",
    "Other"
  ];



  toCheckBoxModel(actual, keys, delimiters?) {
    if (!keys) {
      return undefined;
    }
    const model = {};
    if (!actual) {
      return model;
    }
    const split = actual.split(delimiters ? delimiters : /,+ */);
    return _.values(keys).reduce((a, c) => {
      a[c] = split.indexOf(c) >= 0;
      return a;
    }, model);
  }

  toDelimitedKeysString(c, delimiters?) {
    if (!c) {
      return '';
    }
    return _.keys(c)
      .filter(k => {
        return c[k];
      })
      .join(delimiters ? delimiters : ', ');
  }
}
