<!-- NO IMAGE / REGULAR TEXT CONTENT -->
<!-- <div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.FIBHotels.HotelsCopy"|copy|async'></div>
    </div>
</div> -->

<!-- CONTENT WITH 1 SIDE IMAGE: CHANGE ORDER VALUES IN GENIE TO SHOW IMAGE OR TEXT FIRST  -->
<div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.FIBHotels.HotelsSideImage"|copy|async'></div>
    </div>
</div>

<!-- CONTENT WITH 3 IMAGES IN A ROW: CHANGE ORDER VALUES IN GENIE TO SHOW IMAGE OR TEXT FIRST  -->
<!-- <div class="contentFullVerticalHeight halfVerticalHeight">
    <div class="container">
        <div [innerHTML]='"copy.html.FIBHotels.HotelsThreeImage"|copy|async'></div>
    </div>
</div> -->