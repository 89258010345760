import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { RegistrationService } from './registration.service';
import { ConstantsService } from './constants.service';
import { VariablesService } from './variables.service';
import * as _ from 'lodash';
import { ConfigurationService } from './configuration.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authtoken;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private registrationService: RegistrationService,
    private Constants: ConstantsService,
    private varService: VariablesService,
    private configService: ConfigurationService,

  ) {}

  serviceurlProfile= this.environmentService.getEnvironmentUrl('service/profile');
  serviceUrlToken= this.environmentService.getEnvironmentUrl('service/mtc-session-token');
  serviceUrlRegcode= this.environmentService.getEnvironmentUrl('service/regcode');
  serviceUrls = this.environmentService.getEnvironmentUrl('service/enrollment');
  serviceUrlIsRVS= this.environmentService.getEnvironmentUrl('service/profile/rvs');

  errorHandler(error){
    //TODO analyze error and return to login screen
    //return throwError('server error');
    let errorMsg = 'Login cannot be found.';
    if (error.status === 409) {
        errorMsg = 'Reg Code is not valid';
    } else if (error.status === 403) {
        errorMsg = 'You cannot register with this Login. Please contact TravelHQ for assistance.';
    } else if (error.status === 412) {
        errorMsg = 'Our records indicate that your enrollment has been cancelled. Please contact TravelHQ for further information.';
    } else if (error.status === 417) {
        errorMsg = 'Your number of allowable registrations has been exceeded. Please contact TravelHQ for assistance.';
    } else if (error.status === 406) {
        errorMsg = 'Already in use. Please use your Confirmation Code or contact TravelHQ for assistance.';
    }
    return of({ error: errorMsg});
  }

  authenticateServiceCall(creds) {
    const serviceurl = this.serviceUrlToken;
    return this.http.post(serviceurl, creds).pipe(
      catchError(err => this.errorHandler(err))
   );
  }

  authenticate(creds) {
    Object.keys(creds).forEach(k => creds[k] = creds[k].trim());
    const promise = new Promise((resolve, reject) => {
      this.authenticateServiceCall(creds).subscribe((data: any) => {
        if(data.error){
          reject(data.error);
        }else{
        this.authtoken = data.token;
        resolve(null);
      }
        });
    });
    return promise;
  }

  regcode(regcode) {
    const promise = new Promise((resolve, reject) => {
      if (!regcode) {
        resolve(null);
      } else {
        const serviceurl = this.serviceUrlRegcode;
        this.http
          .post(serviceurl, { regcode })
          .subscribe((data) => {
            this.authtoken = data;
            resolve(null);
          },()=>{reject()});
      }
    });
    return promise;
  }

  mapRegCode(regcode){
    //this.registrationService.getVariable(this.registrationService.getAttendee({}),this.varService.VARIABLE.REGCODE.ID,{}).Value = regcode;
  }
  isRVS() {
    //const promise = new Promise((resolve, reject) => {
        const serviceurl = this.serviceUrlIsRVS;
        //return this.http.get(serviceurl);
    return this.http.get(serviceurl).pipe(
      catchError(err => this.errorHandler(err))
    );
  }
  getProfile() {
    const serviceurl = this.environmentService.getEnvironmentUrl(
      'service/profile'
    );
    return this.http.get(serviceurl);
  }

  getVariableBySeqNo(profile, sq) {
    return (
      _.map(_.filter(profile.variables, { sequence: sq }), 'value')[0] || ''
    );
  }

  getVariableByName(profile, varname) {
    return (
      _.map(_.filter(profile.variables, { name: varname }), 'value')[0] || ''
    );
  }

  handleProfileUpdate(profile) {
    // tslint:disable-next-line: radix
    const paxtype = parseInt(profile.paxtypeNo);
    const wslvariables = [];
    const VARIABLE = this.varService.VARIABLE;
    profile.variables.forEach((wslvar) => {
      // if an enrollment variable is created with the same name as the wsl variable, map it

      if (VARIABLE[wslvar.name]) {
        wslvariables.push({
          WebSequenceNo: VARIABLE[wslvar.name].ID,
          Value: this.getVariableBySeqNo(profile, wslvar.sequence),
        });
      }
    });

    const attendee = this.registrationService.update(
      this.registrationService.getAttendee({}),
      {
        PAXTypeNo: paxtype,
        FirstName: profile.firstName,
        MiddleName: profile.middleName,
        LastName: profile.lastName,
        BusinessName: profile.businessName,
        JobTitle: profile.jobTitle,
        PAXVariableData: wslvariables,
      }
    );
    if (profile.emailAddress) {
      this.registrationService.update(
        this.registrationService.getEmail(
          attendee,
          this.Constants.EMAILS.BUSINESS,
          {}
        ),
        {
          EmailAddress: profile.emailAddress,
        }
      );
    }

    if (profile.phoneNo) {
      this.registrationService.update(
        this.registrationService.getPhone(
          attendee,
          this.Constants.PHONES.WORK,
          {}
        ),
        {
          PhoneType: this.Constants.PHONES.WORK,
          CountryCode: profile.phoneCountryCode,
          AreaCityCode: profile.phoneAreaCode,
          PhoneNo: profile.phoneNo,
          PhoneNoExtension: undefined,
          MobileMessagePhone: false,
        }
      );
    }
    if (profile.addressLine1) {
      this.registrationService.update(
        this.registrationService.getAddress(
          attendee,
          this.Constants.ADDRESSES.BUSINESS,
          {}
        ),
        {
          AddressType: this.Constants.ADDRESSES.BUSINESS,
          AddressLine1: profile.addressLine1,
          AddressLine2: profile.addressLine2,
          Zip: profile.zip,
          City: profile.city,
          StateProvince: profile.stateProvince,
        }
      );
    }

    // RegistrationService.getVariable(attendee, 1, {}).Value =  this.getVariableBySeqNo(profile, 1);
    // this.getVariableByName(profile, "var1");
  }

  initialLogin(creds, regcode) {
    const promise = new Promise((resolve, reject) => {
      this.authenticate(creds).then(() => {
        this.regcode(regcode).then(() => {
          this.getProfile().subscribe((data) => {
            this.handleProfileUpdate(data);
            resolve(null);
          });
        }, (err)=>{
          reject(err);
        });
      }, (err)=>{
        reject(err);
      });
    });
    return promise;
  }
  oauthlogin(creds, regcode) {
    const promise = new Promise((resolve, reject) => {
      this.authenticate(creds).then(() => {
        this.isRVS().subscribe((data1:any) => {
          if(data1){

          this.getProfile().subscribe((data) => {
            this.handleProfileUpdate(data);
            resolve(null);
          });}
          else
          {
            this.configService.fetchConfig().then((programInfo) => {
              const url = this.serviceUrls;
              //ONLY UNCOMMENT THIS IF YOU NEED TO BE ABLE TO BRING BACK RVS, NOT IF GROUP ID
              //this.http.get(url, this.getHeaders()).subscribe((data) => {
              //        this.registrationService.update(
              //  this.registrationService.getEnrollment({}),
              //  data
              //);
              let enroll = this.registrationService.getEnrollment({});
              let temp = null;
              if (enroll.$mtcConfirmationCode) {
                temp = enroll.$mtcConfirmationCode;
             }
              enroll = this.registrationService.cleanse(enroll);
              if (temp) {
               enroll.$mtcConfirmationCode = temp;
              }
              this.registrationService.Enrollment = enroll;
              const enrollment1 = this.registrationService.getEnrollment({});
              this.registrationService.update(enrollment1, { $mtcRegistrationComplete: true, $mtcNotFromReg: true });
              //this.registrationService.update(enrollment1, {
              //  $mtcFromReg: false
             // });
              //ONLY UNCOMMENT THIS IF YOU NEED TO BE ABLE TO BRING BACK RVS, NOT IF GROUP ID
              /* rvs prefill service */
              //this.rvsPrefill();
              /* config service */
              //this.persistenceService.store();
              resolve(null);
            });
            //});
          }
        });
      }, (err)=>{
        reject(err);
      });
    });
    return promise;
  }
  rvsLogin(creds) {
    const promise = new Promise((resolve, reject) => {
      this.authenticate(creds).then(() => {
        this.configService.fetchConfig().then((programInfo) => {
          resolve(null);
        });
      }, (err)=>{
        reject(err);
      });
    });
    return promise;
  }

  updateProfile(status, comment) {
    const data = { status, comment };
    const serviceurl = this.serviceurlProfile;
    return this.http.put(serviceurl, data);
  }

  setAuthToken(authtoken) {
    this.authtoken = authtoken;
  }

  validateSessionToken() {
    const url = this.serviceUrlToken;
    return this.http.get(url);
  }

  invalidateSessionToken() {
    var url = this.serviceUrlToken;
    const promise = new Promise((resolve, reject) => {
      this.http.delete(url).subscribe((data: any) => {
          this.authtoken = undefined;
          resolve(null);
      });
    });
    return promise;
  }
}
