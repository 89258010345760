import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { NavigationService } from 'src/app/services/navigation.service';
import * as _ from 'lodash';
import { EnvironmentService } from 'src/app/services/environment.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../services/constants.service';
import { LineitemService } from 'src/app/services/lineitem.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class PaymentComponent implements OnInit {

  programInfo: any;
  Total: 0;
  charges = {
    Conference: null,
    Hotel: null,
    Activity: null,
    Guest: null
  };
  attendee;
  isFormCompleted;
  isSubmitting;
  CCerrors;
  cardError;
  enrollment;
  mediatypes;
  payment;
  cards:any;
  fee;
  ready = false;
  constructor(private configurationService: ConfigurationService,
    private registrationService: RegistrationService,
    private navigationService: NavigationService,
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private constantsService: ConstantsService,
    private lineItemService: LineitemService) {
    this.payment = this.registrationService.getPayment([]);
    this.attendee = this.registrationService.getAttendee({});
    this.enrollment = this.registrationService.getEnrollment({});
  }

  ngOnInit(): void {
    this.configurationService.fetchConfig().then((data: any) => {
      this.programInfo = data;
      this.mediaTranslations(this.programInfo);
    });

    this.lineItemService.getAllLineItems().subscribe({
      next: data => {
        this.Total = 0;
        data.forEach((f)=>{
          this.Total += f.price * f.quantity;
        });
        this.ready = true;
        },
          error: error => {
            this.constantsService.ALERT_POLICY.CONTACT_SUPPORT
          }
    });

    for (const key of _.keys(this.charges)) {
      this.lineItemService.getLineItems(key).subscribe({
        next: data => {
          this.charges[key] = _.reduce(data, (sum, n) => {
            return parseFloat(sum) + parseFloat(n);
          });
          },
            error: error => {
              this.constantsService.ALERT_POLICY.CONTACT_SUPPORT
            }
      });
    }
  }

  /**
   * @function PaymentController.mediaTranslations
   * @describe Gets correct translations for displaying on the payment page.
   * @param otherPayment
   */
  mediaTranslations(programInfo) {
    if (programInfo.collectOtherPayment) {
      this.mediatypes = programInfo.mediatypes;
    } else {
      this.payment.forEach(element => {
        element.MediaType = "CREDITCARD";
      });
    }
  }

  /**
   * @function back
   * @description Uses [NavigationService.back()](./app.NavigationService.html) to go back to previous page.
   */

  back() {
    this.navigationService.back();
  }

  forward(form) {
    this.isFormCompleted = true;
    console.log(form);
    if (form.valid && !this.validateCards() && this.ready) {
      this.isSubmitting = true;
      this.navigationService.forward();
    }
  }

  validateCards() {
    this.CCerrors = [];
    this.cardError = false;
    console.log(this.payment);
    if (this.programInfo.decideCollect || this.Total > 0) {
      _.each(this.charges, (value, key) => {
        if (value > 0.00) {
          let card = this.registrationService.getCreditCard(this.payment, key, undefined);
          if (!card || !card.ReferenceKey && card.MediaType === "CREDITCARD") {
            console.log(key,card);
            this.cardError = true;
            this.CCerrors.push(key + " Card is required");
          } else if (!card.MediaType) {
            this.cardError = true;
            this.CCerrors.push(key + " Payment Type is required");
          }
        }
      });
      if (this.needGuarantee()) {
        let card = this.registrationService.getCreditCard(this.registrationService.getPayment([]), "Guarantee", undefined);
        if(!card || !card.ReferenceKey){
          this.cardError = true;
          this.CCerrors.push("Credit card for registration Guarantee is required");
        }
      }
    }

    if (this.programInfo.hotelGuarantee && this.enrollment.HotelNeeded && (this.charges.Hotel === 0 || !this.charges.Hotel)) {
      let card = this.registrationService.getCreditCard(this.registrationService.getPayment([]), "Hotel", undefined);
      if(!card || !card.ReferenceKey){
        this.cardError = true;
        this.CCerrors.push("Hotel Guarantee Card is required");
      }
    }
    return this.cardError;
  }

  needGuarantee(){
    if(!this.programInfo.otherPaymentRequireCC){
      return false;
    }
    let notCC = _.filter(this.payment, pay => {
      return pay.MediaType && pay.MediaType !== "CREDITCARD";
    });
    if (notCC.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
