import { Component, OnInit, Input } from '@angular/core';
import { ListManagerService } from 'src/app/services/list-manager.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, Subscriber } from 'rxjs';


@Component({
  selector: 'app-flight-pref',
  templateUrl: './flight-pref.component.html',
  styleUrls: ['./flight-pref.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class FlightPrefComponent implements OnInit {

  @Input() location: any;
  @Input() direction: any;
  @Input() attendee: any;
  @Input() name: any;


  airportLocations;
  currentLocation;

  constructor(private listService: ListManagerService) { }

  ngOnInit(): void {
  }

  subscription;
  doFilter(location) {
    if (location != '') {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
      this.subscription = this.listService.getAirportLocations(location).subscribe(airportLocations => {
        this.airportLocations = airportLocations;
      });

      var selected = _.filter(this.airportLocations, { airport: location });

      if (this.direction === 'Outbound' && selected.length === 1) {
        this.attendee.$mtcOutbound.DepartureAirportCode = selected[0].crscode;
        this.attendee.$mtcOutbound.DepartureCityLocation = selected[0].formattedAirport;
      } else if (this.direction === 'Return' && selected.length === 1) {
        this.attendee.$mtcReturn.DepartureAirportCode = selected[0].crscode;
        this.attendee.$mtcReturn.DepartureCityLocation = selected[0].formattedAirport;
      } else if (this.direction === 'Outbound') {
        this.attendee.$mtcOutbound.DepartureAirportCode = "";
        this.attendee.$mtcOutbound.DepartureCityLocation = location;
      } else if (this.direction === 'Return') {
        this.attendee.$mtcReturn.DepartureAirportCode = "";
        this.attendee.$mtcReturn.DepartureCityLocation = location;
      }

    } else {
      this.airportLocations = [];
    }
  }

  filter(values) {

    return values.filter(location => _.includes(location.airport.toLowerCase(), location));
  }

}
