import { Component, OnInit} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { RegistrationService } from '../../services/registration.service';
import { ListManagerService } from '../../services/list-manager.service';
import { EnrollmentService } from '../../services/enrollment.service';
import * as _ from 'lodash';
import { PhoneService } from 'src/app/services/phone.service';
import { VariablesService } from 'src/app/services/variables.service';


@Component({
  templateUrl: './attendee.component.html'
})
export class AttendeeComponent implements OnInit {
  isSubmitting = false;
  isError = false;
  attendee;
  prefixList;
  suffixList;
  workPhone;
  homePhone;
  mobilePhone;
  workAddress;
  homeAddress;
  emailAddress;
  altemailAddress;
  adminContact;
  adminPhone;
  emerContact;
  emerContactMainPhone;
  emerContactAltPhone;
  DisabilityType;
  mealList;
  mealarray;
  personalConsiderationsList;
  $mtcverifyEmailAddress;
  $mtcMailToAddress;
  isRegComplete;
  enrollment;
  answer;
  isFormCompleted = false;
  varTrack;
  varDepartment;
  varCECreditAdvisor;
  varProfDesignations;
  profdesigValues;
  profdesiglist1;
  profdesiglist2;
  profdesiglist3;
  profDesig1;
  profDesig2;
  profDesig3;
  proDesigother1;
  proDesigother2;
  proDesigother3;
  varVeteran;
  veteranOther;
  veteranlist;
  varFirstresponder;
  firstresponderlist;
  varFirstresponderOther;
  varVeteranOther;
  yesno;
  varLPLAStockholder;
  varContribute;
  varContribution;
  varInitials;
  varCompStaffBenefits;
  varCompStaffFirst;
  varCompStaffLast;
  varCompStaffRepID;
  varStaffEmailFound;
  varScooter;
  varScooterComments;
  varDoNotContact;
  emailwarn;
  SMStext;
  SMSprovider;
  providervalues;
  SMSproviderother;
  ContactConsent;
  relationlist;
  relationother; 
  varpromotionalemail;
  committeeMemberList;
  committeeMember;
  contributiondropdownlist;
  dietaryOtherCheckbox;
  adaOtherCheckbox;
  mealOther;
  allergyCheckbox;
  varDPDateAttend;
  dayvalues;

  clickedCountry;
  childEventClicked(data) {
    this.clickedCountry = data;
  }


  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private listService: ListManagerService,
    private enrollmentService: EnrollmentService,
    private phoneService: PhoneService,
    private variablesService: VariablesService
  ) {

    this.enrollment = this.registrationService.getEnrollment({});
    this.isRegComplete = this.registrationService.isRegistrationComplete();
    /* this.enrollment.HotelNeeded = "false"; */

    this.attendee = this.registrationService.getAttendee({});
    this.workPhone = this.registrationService.getPhone(this.attendee, "WORK", {});
    this.homePhone = this.registrationService.getPhone(this.attendee, "HOME", {});
    this.mobilePhone = this.registrationService.getPhone(this.attendee, "MOBILE", {});
    this.workAddress = this.registrationService.getAddress(this.attendee, "WORK", {});
    this.homeAddress = this.registrationService.getAddress(this.attendee, "HOME", {});
    this.emailAddress = this.registrationService.getEmail(this.attendee, "BUSINESS", {});
    this.altemailAddress = this.registrationService.getEmail(this.attendee, "PERSONAL", {});

    this.adminContact = this.registrationService.getAdminContact({});
    this.adminPhone = this.adminContact.AdminContactPhone[0];
    this.emerContactMainPhone = this.registrationService.getEmergencyContactPhone(this.attendee, "DTPHONE", {});
    this.emerContactAltPhone = this.registrationService.getEmergencyContactPhone(this.attendee, "ALTPHONE", {});
    this.DisabilityType = this.registrationService.getDisabilities(this.attendee, "");
    if(this.workAddress.CountryISOCode === "" || this.workAddress.CountryISOCode === " " || this.workAddress.CountryISOCode === undefined){
      this.workAddress.CountryISOCode = "US";
    }

    if(this.homeAddress.CountryISOCode === "" || this.homeAddress.CountryISOCode === " " || this.homeAddress.CountryISOCode === undefined){
      this.homeAddress.CountryISOCode = "US";
    }

    if(this.attendee.ResidenceCountryISOCode && this.workAddress.CountryISOCode){
      if(this.attendee.ResidenceCountryISOCode == this.workAddress.CountryISOCode){
        this.attendee.$mtcvarCountryOfRes = 'Yes';
      }else{
        this.attendee.$mtcvarCountryOfRes = 'No';
      }
    }

    this.varTrack = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varTrack.ID,
      {}
    );
    this.varDepartment = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varDepartment.ID,
      {}
    );
    this.varCECreditAdvisor = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCECreditAdvisor.ID,
      {}
    );
    this.varProfDesignations = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varProfDesignations.ID,
      {}
    );
    this.SMStext = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varSMSConsent.ID,
      {}
    );
    this.SMSprovider = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCellProvider.ID,
      {}
    );
    this.SMSproviderother = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.SMSproviderother.ID,
      {}
    );
    this.ContactConsent = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varContactSheet.ID,
      {}
    );
    this.varpromotionalemail = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varpromotionalemail.ID,
      {}
    );
    this.committeeMember = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.managementCommitteeHomeOffice.ID,
      {}
    );

    this.varDPDateAttend = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varDPDateAttend.ID,
      {}
    );
    this.dayvalues = variablesService.dayvalues;

    this.committeeMemberList = _.cloneDeep(variablesService.committeeMemberList);
    this.relationlist = _.cloneDeep(variablesService.relationlist);

    if (this.SMSprovider.Value === 'ATT') {
      this.SMSprovider.Value = 'AT&T';
    }

    this.profdesigValues = _.cloneDeep(variablesService.profdesigvalues);
    this.profdesiglist1 = _.cloneDeep(this.profdesigValues);
    this.profdesiglist2 = _.cloneDeep(this.profdesigValues);
    this.profdesiglist3 = _.cloneDeep(this.profdesigValues);

    //strip apart professional designation if value exists
    if(this.varProfDesignations.Value) {
      if(this.profdesiglist1.indexOf(this.varProfDesignations.Value.split(", ")[0]) > -1) {
        this.profDesig1 = this.varProfDesignations.Value.split(", ")[0];
      } else if (this.varProfDesignations.Value.split(", ")[0]) {
        this.profDesig1 = "Other";
        this.proDesigother1 = this.varProfDesignations.Value.split(", ")[0];
      }
      if(this.profdesiglist2.indexOf(this.varProfDesignations.Value.split(", ")[1]) > -1) {
        this.profDesig2 = this.varProfDesignations.Value.split(", ")[1];
      } else if (this.varProfDesignations.Value.split(", ")[1]) {
        this.profDesig2 = "Other";
        this.proDesigother2 = this.varProfDesignations.Value.split(", ")[1];
      }
      if(this.profdesiglist3.indexOf(this.varProfDesignations.Value.split(", ")[2]) > -1) {
        this.profDesig3 = this.varProfDesignations.Value.split(", ")[2];
      } else if (this.varProfDesignations.Value.split(", ")[2]) {
        this.profDesig3 = "Other";
        this.proDesigother3 = this.varProfDesignations.Value.split(", ")[2];
      }
    }
    
    this.changeprofdesig1();
    this.changeprofdesig2();
    this.changeprofdesig3();

    this.varVeteran = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varVeteran.ID,
      {}
    );
    this.veteranlist = _.cloneDeep(variablesService.veteranlist);
    
    this.varVeteranOther = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varVeteranOther.ID,
      {}
    );

    this.varFirstresponder = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varFirstresponder.ID,
      {}
    );
    this.firstresponderlist = _.cloneDeep(variablesService.firstresponderlist);
    
    this.varFirstresponderOther = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varFirstresponderOther.ID,
      {}
    );



    this.varLPLAStockholder = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varLPLAStockholder.ID,
      {}
    );

    this.varContribute = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varContribute.ID,
      {}
    );

    this.varContribution = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varContribution.ID,
      {}
    );

    this.varInitials = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varInitials.ID,
      {}
    );

    this.varCompStaffBenefits = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffBenefits.ID,
      {}
    );
    this.varCompStaffFirst = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffFirst.ID,
      {}
    );    
    this.varCompStaffLast = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffLast.ID,
      {}
    );  
    this.varCompStaffRepID = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varCompStaffRepID.ID,
      {}
    );   
    this.varStaffEmailFound = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varStaffEmailFound.ID,
      {}
    );   
    this.varScooter = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varScooter.ID,
      {}
    );

    this.varScooterComments = registrationService.getVariable(
      this.attendee,
      variablesService.VARIABLE.varScooterComments.ID,
      {}
    );

    this.mealList = [
      {name: 'None', code: ''},
      {name: 'Dairy-Free', code: 'DAIRYFREE'},
      {name: 'Food Allergy', code: 'FOODALLE'},
      {name: 'Gluten-Free', code: 'GLUTFREE'},
      {name: 'Halal', code: 'HALAL'},
      {name: 'Kosher', code: 'KOSHER'},
      {name: 'No Pork', code: 'NOPORK'},
      {name: 'Pescatarian', code: 'PESCATARIAN'},
      {name: 'Vegan', code: 'VEGAN'},
      {name: 'Vegetarian', code: 'VEGTRIAN'},
      {name: 'Other', code: 'OTHER'}
    ];

    this.mealarray = ["None", "Dairy-Free", "Food Allergy", "Gluten-Free", "Halal", "Kosher", "No Pork", "Pescatarian", "Vegan", "Vegetarian", "Other"];

    this.personalConsiderationsList = [
      {name: 'None', code: 'NONE'},
      {name: 'Diabetic', code: 'DIABETIC'},
      {name: 'Hearing Impaired', code: 'HRNGIMPD'},
      {name: 'Visually Impaired', code: 'VISLIMPD'},
      {name: 'Wheelchair Access', code: 'WHLCHAIR'},
      {name: 'Other', code: 'OTHER'}
    ];

    this.yesno = _.cloneDeep(variablesService.yesno);

    this.providervalues = _.cloneDeep(variablesService.providervalues);
    this.contributiondropdownlist = _.cloneDeep(variablesService.contributiondropdownlist);

    if (this.varTrack.Value === 'Benefit Advisor' || this.varTrack.Value==='Non-Benefit Advisor' || this.varTrack.Value==='Home Office') {
      if (this.varContribution.Value) {
        if (this.varContribution.Value === "$250" || this.varContribution.Value === "$500" || this.varContribution.Value === "$1000" || this.varContribution.Value === "$2500"
        || this.varContribution.Value === "$5000") {
          this.enrollment.$mtccontributetemp = this.varContribution.Value;
        } else {
          this.enrollment.$mtccontributetemp = "Other";
        }
      } else {
        this.varContribution.Value = "250";
      }
    }

    this.attendee.$mtcHasConsiderations = true;

    if ( this.attendee.DietaryConsiderations !== undefined && this.attendee.DietaryConsiderations !== "" ) {
      let dietarray = this.attendee.DietaryConsiderations.split(',');

      for (let x=0; x < dietarray.length; x++) {
        //console.log(dietarray[x]);
        if (this.mealarray.includes(dietarray[x]) === false) {
          this.mealOther = dietarray[x];
          let string = "," + dietarray[x];
          this.attendee.DietaryConsiderations = this.attendee.DietaryConsiderations.replace(string, "")
        }
      }
      
      if ( this.attendee.DietaryConsiderations.includes('Other') ) {
        this.dietaryOtherCheckbox = true;
      }
      if ( this.attendee.DietaryConsiderations.includes('Food Allergy') ) {
        this.allergyCheckbox = true;
      }
    } else {
      this.attendee.DietaryConsiderations = "";
    }

    if( this.attendee.DisabilityType !== undefined && this.attendee.DisabilityType !== "" ){
      if ( this.attendee.DisabilityType.includes('OTHER') ) {
        this.adaOtherCheckbox = true;
      }
    }


    this.listService.getCommonPrefixList().then(list => {
      this.prefixList = list;
    });
    this.listService.getSuffixList().then(list => {
      this.suffixList = list;
    });
    // this.listService.getMealPreferenceList().then(list => {
    //   this.mealList = list;
    // });

    

    // this.listService.getDisabilityList().then(list => {
    //   this.personalConsiderationsList = list;
    // });
    this.detectMailTo();
    this.setMailTo();
    if(this.isRegComplete)
    {}
    else
    {
      this.workAddress.MailTo = true;
    }
  }

  ngOnInit(): void { }

  includes(arr, val){
    return _.includes(arr, val);
  }


  FieldsChange(values:any) {
    if (values.checked) {
      this.homeAddress.AddressLine1 = this.workAddress.AddressLine1;
      this.homeAddress.AddressLine2 = this.workAddress.AddressLine2;
      this.homeAddress.Zip = this.workAddress.Zip;
      this.homeAddress.City = this.workAddress.City;
      this.homeAddress.StateProvince = this.workAddress.StateProvince;
      this.homeAddress.CountryISOCode = this.workAddress.CountryISOCode;
    } else {
      this.homeAddress.AddressLine1 = "";
      this.homeAddress.AddressLine2 = "";
      this.homeAddress.Zip = "";
      this.homeAddress.City = "";
      this.homeAddress.StateProvince = "";
      this.homeAddress.CountryISOCode = "";
    }
  }
  cleanAdminContact() {
    if (!this.enrollment.$mtcHasAdminContact) {
        this.adminContact.AdminContactName = undefined;
        this.adminContact.AdminContactEmailAddress = undefined;
        this.adminContact.AdminContactPhone = undefined;
    }
  }
  cleanConsiderations() {
    if (!(this.attendee.DietaryConsiderations.includes("Food Allergy")) && !(this.attendee.DietaryConsiderations.includes("Other"))) {
       this.mealOther = "";
    }
    if (!this.adaOtherCheckbox){
      this.attendee.PersonalConsiderations = "";
    }
  }

  hasdietary() {
    if (this.attendee.DietaryConsiderations.length > 1) {
      return true;
    } else {
      return false
    }
  }

  haspersonalconsiderations() {
    if (this.attendee.DisabilityType.length > 0) {
      return true;
    } else {
      return false
    }
  }

  considerationUpdate(consideration) {
    let justadded = false;

    if (!_.includes(this.attendee.DisabilityType, consideration.code)) {
      this.attendee.DisabilityType.push(consideration.code);
      justadded = true;
      if(consideration.code === "OTHER"){
        this.adaOtherCheckbox = true;
      }
    }
    if (justadded === false && _.includes(this.attendee.DisabilityType, consideration.code)) {
      var index = this.attendee.DisabilityType.indexOf(consideration.code);
      if (index !== -1) {
        this.attendee.DisabilityType.splice(index, 1);
      }
      if(consideration.code === "OTHER"){
        this.adaOtherCheckbox = false;
      }
    }
  }



  changeprofdesig1() {
    if ((this.profDesig1 !== 'Other' && this.profDesig1 !== 'None') && this.profDesig1 !== ' ') {
      const index = this.profdesiglist2.indexOf(this.profDesig1);
      if (index > -1) {
        this.profdesiglist2.splice(index, 1);
      }
      const index2 = this.profdesiglist3.indexOf(this.profDesig1);
      if (index2 > -1) {
        this.profdesiglist3.splice(index2, 1);
      }
    }
  }

  changeprofdesig2() {
    if ((this.profDesig2 !== 'Other' && this.profDesig2 !== 'None')  && this.profDesig2 !== ' ') {
      const index = this.profdesiglist1.indexOf(this.profDesig2);
      if (index > -1) {
        this.profdesiglist1.splice(index, 1);
      }
      const index2 = this.profdesiglist3.indexOf(this.profDesig2);
      if (index2 > -1) {
        this.profdesiglist3.splice(index2, 1);
      }
    }
  }

  changeprofdesig3() {
    if ((this.profDesig3 !== 'Other' && this.profDesig3 !== 'None')  && this.profDesig3 !== ' ') {
      const index = this.profdesiglist2.indexOf(this.profDesig3);
      if (index > -1) {
        this.profdesiglist2.splice(index, 1);
      }
      const index2 = this.profdesiglist1.indexOf(this.profDesig3);
      if (index2 > -1) {
        this.profdesiglist1.splice(index2, 1);
      }
    }
  }



  mtcMailToAddress;

  detectMailTo(){
    if(this.attendee){
      this.attendee.Address.forEach(add => {
        if(add.MailTo){
          this.mtcMailToAddress = add.AddressType;
        }
      });
    }

  }

  setMailTo() {
    if(this.attendee){
      this.attendee.Address.forEach(add => {
        if(add.AddressType === this.mtcMailToAddress){
          add.MailTo = true;
        }else{
          add.MailTo = false;
        }
      });
    }
    this.phoneService.triggeredChange();
  }

  mealUpdate(meal) {
    console.log(meal);
    if ( meal.name !== undefined ) {
      if ( this.attendee.DietaryConsiderations === undefined ) {
        this.attendee.DietaryConsiderations = "";
      } else {
        if ( !this.attendee.DietaryConsiderations.includes(meal.name) && (this.attendee.DietaryConsiderations === "" || this.attendee.DietaryConsiderations === undefined)) {
          this.attendee.DietaryConsiderations = meal.name;
        }else if( !this.attendee.DietaryConsiderations.includes(meal.name) ) {
          this.attendee.DietaryConsiderations = this.attendee.DietaryConsiderations + "," + meal.name;
        }else {
          this.attendee.DietaryConsiderations = this.attendee.DietaryConsiderations.replace("," + meal.name, "").replace(meal.name, "");
          if(this.attendee.DietaryConsiderations[0] === ","){
            this.attendee.DietaryConsiderations = this.attendee.DietaryConsiderations.slice(1,this.attendee.DietaryConsiderations.length);
          }
        }
      }
    }
    console.log(this.attendee.DietaryConsiderations);
  }




  forward(form) {
    this.isSubmitting = true;
    this.isFormCompleted = true;
    this.enrollment.$mtcshowselecterror = false;
    //console.log(this.enrollment);
    //console.log(form);
    if (form.valid) {

      console.log(this.enrollment);

        if ( this.hasdietary() === false && this.haspersonalconsiderations() === false) {
          this.cleanConsiderations();
        } else {
          this.isError = true;
          //this.isSubmitting = false;
        }

        if ( this.mealOther !== "" &&  this.mealOther !== undefined) {
          this.attendee.DietaryConsiderations = this.attendee.DietaryConsiderations + "," + this.mealOther;
        }


      if (this.varTrack.Value === 'Benefit Advisor' || this.varTrack.Value==='Non-Benefit Advisor') {
        if (this.attendee.BirthDate) {
          this.enrollment.$mtchadbirthdatefromattendee = true;
        }
      }

      if (this.varTrack.Value === 'Benefit Advisor'  || this.varTrack.Value==='Non-Benefit Advisor' || this.varTrack.Value==='Home Office') {
        if (this.enrollment.$mtccontributetemp && this.enrollment.$mtccontributetemp !== "Other") {
          this.varContribution.Value = this.enrollment.$mtccontributetemp;
        }
      }
  
      if (this.varLPLAStockholder.Value === 'No') {
        this.varContribute.Value = "";
        this.varContribution.Value = "";
        this.varInitials.Value = "";
        this.enrollment.$mtccontributetemp = "";
      }

      if (this.varContribute.Value !== 'Yes') {
        this.varContribution.Value = "";
        this.varInitials.Value = "";
        this.enrollment.$mtccontributetemp = "";
      }


      if (this.altemailAddress.EmailAddress === "") {
        this.altemailAddress.EmailAddress = undefined;
      }
      if (this.SMStext.Value === 'No') {
        this.SMSprovider.Value = "";
        this.SMSproviderother = "";
      }
      if (this.SMSprovider.Value !== 'Other') {
        this.SMSproviderother = "";
      }

      if (this.attendee.EmergencyContact.EmergencyContactRelation === "Other") {
        this.attendee.EmergencyContact.EmergencyContactRelation = this.relationother;
      }

      var temp = [];
      if(this.profDesig1 && this.profDesig1 != ' ') {
        temp.push(this.profDesig1!=="Other"?this.profDesig1:this.proDesigother1);
      }
      if(this.profDesig2 && this.profDesig2 != ' ') {
        temp.push(this.profDesig2!=="Other"?this.profDesig2:this.proDesigother2);
      }
      if(this.profDesig3 && this.profDesig3 != ' ') {
        temp.push(this.profDesig3!=="Other"?this.profDesig3:this.proDesigother3);
      }
      this.varProfDesignations.Value = temp.join(", ");

      this.enrollment.$mtcSeenAttendee = true;

      this.cleanAdminContact();
      this.navigationService.forward();
    } else {
      this.isError = true;
      this.isSubmitting = false;
      this.enrollment.$mtcshowselecterror = true;
    }
  }


  back() {
    this.navigationService.back();
  }
}
