import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  templateUrl: './load.component.html'
})
export class LoadComponent implements OnInit {
  constructor(private route: ActivatedRoute,
    private config: ConfigurationService,
    private nav: NavigationService,
    private auth:AuthenticationService,
    private enrollmentService: EnrollmentService) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe(data=>{
      //http://localhost:9000/#/load?confcode=TX5VBMXS&email=asdf@asdf.com
      if(data.confcode && data.email){
        let creds = {
          "confirmationCode": data.confcode,
          "emailAddress": data.email
        };
        this.auth.oauthlogin(creds,undefined).then((data:any)=>{
          this.enrollmentService.rvsGetEnrollment().then(() => {
            this.nav.forwardrvs();
          });
        });
      }
    });
  }

}
