import { Component, OnInit } from '@angular/core';
import { PersistenceService } from './services/persistence.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationService } from './services/authentication.service';
import { Router, NavigationEnd, NavigationStart, RouterEvent, ResolveEnd } from '@angular/router';
import { TimeoutService } from './services/timeout.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { ContactusComponent } from './reusable-components/contactus/contactus.component';
import { IeNotSupportedComponent } from './reusable-components/ie-not-supported/ie-not-supported.component';
import { EnvironmentService } from './services/environment.service';
import { CopyService } from './services/copy.service';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'focus2024daypass';
  body;
  retrieved = false;
  name = 'Angular';
  constructor(
    private persistenceService: PersistenceService,
    private bnIdle: BnNgIdleService,
    private timeoutService: TimeoutService,
    private router: Router,
    private environment: EnvironmentService,
    private copyService: CopyService,
    private analyticsService: AnalyticsService
  ) {
    this.body = <HTMLDivElement> document.body;
    router.events.subscribe((e) => {
      if(e instanceof ResolveEnd){
        // console.log('router change detected');
        // console.log(e);
      
        timeoutService.setSessionTimeout();
        window.scrollTo(0, 0);
        if(!this.retrieved){
          console.log("retrieving");
          this.persistenceService.retrieve(e.url);
          this.retrieved = true;
        }
        this.analyticsService.triggerEvent(window.location, router.url.replace(/[^A-Za-z0-9\s!?]/g,''));
      }
      });

    const env = window.location.hostname;
    var timeout = 0;
    if (env.indexOf('localhost') >= 0) {
      timeout = 86400; /* 24 hours */
    } else if (env.indexOf('local') >= 0) {
      timeout = 86400; /* 24 hours */
    } else if (env.indexOf('dev') >= 0) {
      timeout = 86400; /* 24 hours */
    } else if (env.indexOf('test') >= 0) {
      timeout = 1800; /* 30 minutes */
    } else if (env.indexOf('content') >= 0) {
      timeout = 1800; /* 30 minutes */
    }

    this.bnIdle.startWatching(timeout).subscribe((res) => {
      if (res) {
        //console.log('session expired');
      }
    });
  }

  ngOnInit() {
    let baseurl = "";
    const env = window.location.hostname;
    if (env.indexOf('local') >= 0) {
      //baseurl = 'http://127.0.0.1:8080/';
       baseurl = 'https://content-dev.travelhq.com/events/';
    }else{
      baseurl = '/events/';
    }
    this.loadScript(baseurl + 'activitycomponent/1.1.1/', 'activity.js');
    this.loadScript(baseurl + 'profilecomponent/0.0.1/', 'profile.js');
    // this.loadScript(baseurl + 'basecomponent/0.0.0/', 'basecomponent.js');
    this.loadScript(baseurl + 'r99-field/0.0.2/', 'r99-field.js');
    this.loadScript(baseurl + 'timeslotselectcomponent/0.0.4/', 'timeslot.js');

  }


  public loadScript(baseurl: string, jsname: string) {

    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = baseurl + jsname;
    script.async = false;
    script.defer = true;
    this.body.appendChild(script);

    const css = document.createElement('link');
    css.innerHTML = '';
    css.href = baseurl + 'styles.css';
    css.rel = 'stylesheet';
    this.body.appendChild(css);
  }

  isDev(){
    return this.environment.getEnv() === 'dev';
  }

  showCopy(){
    console.log("we copy");
    this.retrieved = false;
    this.copyService.showcopy = true;
    setTimeout(() =>  {
      this.retrieved = true;
    }, 100)

  }
}
